<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <b-card class="rounded border-2">
                    <b-card-header>
                        <div class="d-flex flex-row justify-content-between">
                            <h5 class="m-0">My Production Contract</h5> 
                            <b-button class="m-0 btn float-left" variant="success" @click="myDashboard" size="sm"><i class="fa fa-arrow-left mr-2"></i>Back to Dashboard</b-button>
                        </div>
                    </b-card-header>
                    <b-card-body>
                        <div v-if="errors.length > 0">
                            <b-alert show variant="danger">
                                <small>{{ errors[0] }}</small>
                            </b-alert>
                        </div>
                        <template v-if="myProductionContract.length > 0">
                            <b-list-group>
                                <b-list-group-item @click="productionContractDetails(productionContract.id)" v-for="productionContract in myProductionContract" :key="productionContract.id" class="d-block d-md-flex align-items-center add-pointer">
                                    <div class="text-center text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                                        <h6 class="mt-2 m-md-0">
                                            {{productionContract.subtype}} {{productionContract.type}}
                                        </h6>
                                        <small>Create Date: {{ formatDate(productionContract.created) }}</small><br />
                                        <small class="d-block d-md-flex"><span>Delivery Window: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left">{{moment(productionContract.startDate.toDate()).format('ll')}} to {{moment(productionContract.endDate.toDate()).format('ll')}}</strong></small>
                                        <small class="d-block d-md-flex"><span>Number of Acres: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left"> {{ productionContract.available_acres }}</strong></small>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>
                        </template>
                        <template v-else>
                            <b>No Production Contract</b>
                        </template>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase';
import {mapState} from 'vuex';
import { switchMap, map} from 'rxjs/operators';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest,  of } from 'rxjs';
import moment from 'moment';

export default {
    computed: {
        ...mapState(['user']),
    },
    data() {
        return {
            loading: false,
            total: 0,
            myProductionContract: [],
            errors: [],
        }
    },
    async mounted(){
        this.getMyProductionContract();
    },
    methods: {
        editProductionContract(productionContractID){
            this.$router.replace("/production/contract/edit/" + productionContractID)
        },
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        async getMyProductionContract(){
            let vm = this;
            vm.loading = true;
            vm.myProductionContract = [];
            vm.total = 0;
            let ref = db.collection('production_contract');
                ref = ref.where('userID', '==', vm.user.uid);
                ref = ref.where('is_delete', '==', 0)
                collectionData(ref, 'id').pipe(
                    switchMap((productionContracts)=>{
                    if(productionContracts.empty) return of([]);
                        return combineLatest(...productionContracts.map(c=>{
                            return docData(db.collection('units').doc(c.unit)).pipe(
                                map((t)=>{
                                    return {...c,unit:t.name, unitId: c.unit}
                                })
                            )
                        }))
                    })
                ).pipe(
                    switchMap(productionContracts=>{
                        if(productionContracts.length==0) return of([]);
                        return combineLatest(...productionContracts.map(c=>{
                            return docData(db.collection('commodity_types').doc(c.type)).pipe(
                                map(t=>{
                                    return {...c,type:t.name}
                                })
                            )
                        }))
                    })
                ).pipe(
                    switchMap(productionContracts=>{
                        if(productionContracts.length==0) return of([]);
                        return combineLatest(...productionContracts.map(c=>{
                            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                            map(t=>{
                                return {...c,subtype:t.name}
                            })
                            )
                        }))
                    })
                ).subscribe((productionContracts) => {
                    if(productionContracts.length != 0){
                        productionContracts.map((productionContract) => {
                            vm.myProductionContract.push(productionContract);
                        });
                    }
                    vm.loading = false
                })
        },
        myDashboard(){
            this.$router.replace('/');
        },
        productionContractDetails(productionContractID){
            this.$router.push('/production/contract/'+productionContractID)
        }
    }
    
}

</script>