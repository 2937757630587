<template>
  <div class="container">
    <div v-if="loading" class="row mt-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
        </card>

      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
    <div v-else-if="orders.length > 0" class="row">
      <div class="col-12">
        <b-card class="rounded border-2">
          <b-card-header><h5 class="m-0">My Orders</h5></b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item @click="openItem(item)" button :key="item.id" v-for="item in orders" class="d-block d-md-flex align-items-center text-center text-md-left">
                <div class="position-relative">
                  <b-img class="d-none d-md-block img-dashboard-large-preview" v-if="item.commodity && item.commodity.images" :src="primaryImage(item.commodity.images).thumbURL" thumbnail />
                  <b-img class="d-block d-md-none mobile-image" v-if="item.commodity && item.commodity.images" :src="primaryImage(item.commodity.images).largeURL" thumbnail /><small v-if="item.unread" class="position-absolute fa fa-circle text-danger" style="top:-4px;left:-4px;"></small>
                </div>
                <div class="text-center text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                  <h6 class="mt-2 m-md-0">
                    {{numeral(item.amount).format('0,000')}} 
                    <span v-if="item.commodity">
                      {{item.commodity.unit}} of {{item.commodity.subtype}} {{item.commodity.type}}
                    </span>
                  </h6>
                  <div class="text-muted"><small>Total:</small> <strong>${{numeral(item.total).format('0,000.00')}}</strong></div>
                  <div v-if="item.title" class="m-0"><strong :class="'text-'+(item.type=='message'?'info':(item.type=='update'?'success':(item.type=='alert'?'danger':(item.type=='shipping'?'warning':'dark'))))">{{item.type.toUpperCase()}}:</strong><span class="ml-2">{{item.title}}</span></div>
                  <div v-if="item.text" class="m-0">{{item.text.substr(0,100)+'...'}}</div>
                </div>
                <div class="text-center"><b-badge pill variant="info">{{item.entity}}</b-badge><small class="text-muted d-block mt-1"><nobr>{{formatDate(item.created)}}</nobr></small></div>
              </b-list-group-item>
              <b-list-group-item v-if="!allOrdersLoaded" :disabled="ordersLoadingMore" @click="loadOrders" button variant="success" class="text-center">
                  <span class="fa fa-spinner" v-if="ordersLoadingMore"></span>
                  <span v-else class="fa fa-plus-circle mr-2"></span> 
                  <strong>Show More</strong>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div v-else-if="orders.length==0" class="row mt-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <template v-if="user.role.buyer">
            <h4 class="card-title mt-0 mb-4">No Orders Placed</h4>
            <p><a href="/marketplace" class="text-info">Browse our product listings</a></p>
          </template>
          <template v-else>
            <h4 class="card-title mt-0 mb-4">No Orders Received</h4>
            <p><a @click="showLearnMore=true" class="text-info">Learn more about creating great product listings</a></p>
            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
          </template>
        </card>
        <b-modal size="lg" ref="learn-more-modal" v-model="showLearnMore" hide-footer title="Creating Great Product Listings">
          <CreatingGreatListings />
        </b-modal>

      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
  </div>
</template>
<script>
import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import {bus} from '@/vuex/bus.js';
import {mapState} from 'vuex';
import Vue from "vue";
import numeral from 'numeral';
import moment from 'moment';
//import {bus} from '@/vuex/bus.js';
import {db} from '@/firebase';
//import { collection } from 'rxfire/firestore';
import { docData } from 'rxfire/firestore';
import { from, of, combineLatest } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';
Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;
export default {
  components: {
    CreatingGreatListings
  },
  methods:{
    openItem(item){
      this.$router.push('/order/view/' + item.id);
    },
    async loadOrders(){
      let vm = this;
      if(!vm.lastOrderDoc) { 
        vm.loading=true;
      } else {
        vm.ordersLoadingMore=true;
      } 
      let ref = db.collection('orders').where('is_restore', '==', null);
      ref = ref.limit(10).orderBy('created','desc');
      if(vm.lastOrderDoc) ref=ref.startAfter(vm.lastOrderDoc);
      from(ref.get()).pipe(
        switchMap(orders=>{
          if(orders.empty||orders.length<10) vm.allOrdersLoaded, vm.loading = false;
          if(orders.empty) return of([]);
          vm.lastOrderDoc=orders.docs[orders.docs.length-1];
          return combineLatest(...orders.docs.map(doc=>{
            return docData(db.collection('commodities').doc(doc.data().commodityID)).pipe(
              map(t=>{
                if(JSON.stringify(t) !== '{}' && t.is_delete === 0){
                  return {id:doc.id,...doc.data(),commodity:{...t}}
                } else {
                  return {id:doc.id,...doc.data(),commodity:{}}
                }
              })
            )
          }))
        })
      ).pipe(
        switchMap(inbox=>{
          if(inbox.length==0) return of([]);
          return combineLatest(...inbox.map(c=>{
            if(c.commodity && c.commodity.type){
              return docData(db.collection('commodity_types').doc(c.commodity.type)).pipe(
                map(t=>{
                    if(JSON.stringify(t) !== '{}'){
                      return {...c,commodity:{...c.commodity,type:t.name}}  
                    } else {
                      return {...c,commodity:{...c.commodity,type:''}}
                    }
                })
              )
            }
            return of([]);
          }))
        })
      ).pipe(
        switchMap(inbox=>{
          if(inbox.length==0) return of([]);
          return combineLatest(...inbox.map(c=>{
            if(c.commodity && c.commodity.subtype){
              return docData(db.collection('commodity_subtypes').doc(c.commodity.subtype)).pipe(
                map(t=>{
                    if(JSON.stringify(t) !== '{}'){
                      return {...c,commodity:{...c.commodity,subtype:t.name}}
                    } else {
                      return {...c,commodity:{...c.commodity,subtype:''}}
                    }
                })
              )
            }
            return of([]);
          }))
        })
      ).pipe(
        switchMap(inbox=>{
          return combineLatest(...inbox.map(c=>{
            if(c.commodity && c.commodity.unit){
              return docData(db.collection('units').doc(c.commodity.unit)).pipe(
                map(t=>{
                    if(JSON.stringify(t) !== '{}'){
                      return {...c,commodity:{...c.commodity,unit:t.name}}
                    } else {
                      return {...c,commodity:{...c.commodity,unit:''}}
                    }
                })
              )
            }
            return of([]);
          }))
        })
      ).subscribe((orders)=>{
          orders.forEach((order)=>{
            if(order.length == undefined){
              if(vm.user.role.admin){
                vm.orders.push(order)
              } else if(order.commodity.userID === vm.user.uid && order.producerID === vm.user.uid) {
                vm.orders.push(order)
              } else if (order.commodity.userID !== vm.user.uid && order.buyerID === vm.user.uid){
                vm.orders.push(order)
              }
            }
         });
         vm.ordersLoadingMore=false;
      });
      setTimeout(() => {
        vm.ordersLoadingMore=false;
        vm.loading=false;
      }, 5000);
    },
    formatNumeral(amount,format){
      return numeral(amount).format(format);
    },
    primaryImage(images){
      let arr = images.filter(i=>i.name);
      if(arr.length==0) return {thumbURL:'/img/NGF_NO_IMAGE_45x45.png',largeURL:'/img/NGF_NO_IMAGE.png'}
      arr = arr.filter(i=>i.primary);
      if(arr.length==0) return images[0];
      else return arr[0];
    },
    formatDate(d){
      if(d){
        d = d.toDate();
        if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
        if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
        if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
        return moment(d).format('ll');
      }else{
        return '';
      }
    },
  },
  computed:{
    ...mapState(['user'])
  },
  data() {
    return {
      orders: [],
      loading: true,
      showLearnMore: false,
      ordersLoadingMore: false,
      allOrdersLoaded: false,
      lastOrderDoc: null,
    };
  },
  async mounted(){
    let vm = this;
    vm.orders = [];
    await vm.loadOrders();
    bus.$on('backToMarketplace',()=>{
      bus.$emit('prepareTitle',null);
    });
    bus.$emit('prepareTitle',null);
  }
};
</script>
<style>
.img-dashboard-large-preview{
    width: 56px !important;
    height: 56px !important; 
}

@media screen and (max-width: 767px) {
  .mobile-image{
    max-width: 90px;
    margin: auto;
  }
}

</style>
