<template>
  <div class="user user__menu">
    <div class="photo">
      <i class="fa fa-user"></i>
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <span>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav user-menu__nav" v-show="!isClosed">
            <slot>
              <li v-if="!user.role.admin">
                <a class="pl-4" href="/profile">
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li v-if="subscriptions.active&&!user.role.admin&&!user.role.producer">
                <a class="pl-4" href="/account"><span class="sidebar-normal">My Account</span></a>
              </li>
              <!-- <li v-if="!user.role.admin">
                <a class="pl-4" href="/notification/setting">
                  <span class="sidebar-normal">Notification Settings</span>
                </a>
              </li> -->
              <li>
                <a @click="logout" class="pl-4">
                  <span class="sidebar-normal">Logout</span>
                </a>
              </li>
            </slot>
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/vuex/store.js';
import {mapState} from 'vuex';
import {auth} from '@/firebase';
import { CollapseTransition } from "vue2-transitions";
import {bus} from "@/vuex/bus.js";

export default {
  name: "user-menu",
  components: {
    CollapseTransition
  },
  data() {
    return {
      isClosed: true
    };
  },
  computed:{
    ...mapState(['user','subscriptions']),
    title(){
      return (this.user.about?this.user.about.first+' '+this.user.about.last:'');
    }
  },
  methods: {
    logout(){
      auth.signOut().then(() => {
        bus.$emit('UserLogout');
        store.commit('updateUser',{});
        this.$router.replace('/login')
      });
    },
    toggleMenu() {
      this.isClosed = !this.isClosed;
    }
  }
};
</script>
<style>
.user__menu ul.user-menu__nav {
  margin-top: 0;
  padding-top: 20px;
}
</style>
