<template>
    <div>
        <div class="container">
            <div class="d-flex justify-content-end">
                <b-button variant="danger" :disabled="disabledDeletedButton" @click="restoreMultipleListing">Restores</b-button>
            </div>
            <div>
                <div v-if="loading">
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </div>
                <div id="productionContractGrid" ref="productionContractGrid"></div>
            </div>
        </div>
      
        <div>
            <b-modal ref="model" size="lg" v-model="confirmationPopup">
                <h2>
                    Are you sure you want to restore this Production Contract?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreProductionContract">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>

        <div>
            <b-modal ref="model" size="lg" v-model="multipleListingConfirmationPopup">
                <h2>
                    Are you sure you want to restore this Production Contracts?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreMultipleProductionContract">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>

    </div>    
</template>

<script>
import { mapState } from 'vuex'
import { db } from '@/firebase';
import {  docData } from 'rxfire/firestore';
import {  catchError, map, switchMap} from 'rxjs/operators';
import { of, combineLatest, from } from 'rxjs';
import { Grid, html } from 'gridjs';
import 'gridjs/dist/theme/mermaid.css';
import moment from 'moment';



export default {
    name: 'AllProductionContractDeletedList',
    computed: {
        ...mapState(['user'])
    },
    data(){
        return {
            deleteProductionContractList: [],
            confirmationPopup: false,
            ProductionContractID: null,
            loading: false,
            gridInstance: null,
            disabledDeletedButton: true,
            selectedProductionContract: [],
            multipleListingConfirmationPopup: false
        }
    },
    created(){
        const vm = this;
        if(!vm.user.role.admin){
            vm.$router.replace("/not/access")
        }
    },
    async mounted() {
       const vm = this;
       await vm.getDeleteAllProductionContractList();
    },


    methods:{
        /**
         * Get retrieved list of Deleting Production Contract
         */

        clearGrid(){
            const vm = this;
            if(vm.gridInstance){
                vm.gridInstance.destroy();
                vm.gridInstance = null;
            }
            vm.$refs.productionContractGrid.innerHTML = '';
        },


        initializeGrid(data){
            const vm  = this;
            const showPagination = data.length > 10;
            vm.gridInstance = new Grid({
                columns: [
                    {
                        id: 'checkbox',
                        name: '',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 50px;' // Set width inline
                        }
                    },
                    {
                        name: '#',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 60px; text-align: center' // Set width inline
                        }
                    },
                    {
                        name: 'Production Contracts Name',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 220px;' // Set width inline
                        }
                    },
                    {
                        name: 'Created By',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 220px;' // Set width inline
                        }
                    },
                    {
                        name: 'Created Date',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 150px;' // Set width inline
                        }
                    },
                    {
                        name: 'Deleted Date',
                        formatter: (cell) => html(cell),
                        attributes: {
                            style: 'width: 150px'
                        }
                    },
                    {
                        name:'Action',
                        formatter: (cell) => html(cell)
                    }
                ],
                pagination: showPagination ? { limit: 10} : false,
                search: false,
                data:  data.map((data, rowIndex) => {
                        return [`<input type="checkbox" id="checkbox-${rowIndex}" value="${data.id}" class="row-checkbox" />`, rowIndex + 1, data.subtype + " " + data.type, (data.user.about != undefined) ? data.user.about.first + " " + data.user.about.last :'N/A' , moment(data.created.toDate()).format('ll'),(data.deleted != undefined) ? moment(data.deleted.toDate()).format('ll') : '-',`  <button class="btn btn-sm btn-primary m-0" onclick="showPCConfirmationPopup('${data.id}')">
                            <i class="fa fa-clipboard mr-2"></i> Restore</button>`
                        ]
                })
            }).render(vm.$refs.productionContractGrid)
            vm.loading = false;

            document.getElementById('productionContractGrid').addEventListener('change', (event) => {
                if(event.target.type === 'checkbox'){
                    vm.updateSelectedIds();
                }
            });
        },



        async getDeleteAllProductionContractList() {
            const vm = this;
            vm.loading = true;
            vm.ProductionContractID = null;
            let ref = db.collection('production_contract').where('is_delete','==', 1).orderBy('created', 'desc');
            from(ref.get()).
                pipe(
                    switchMap(productionContract=>{
                    if(productionContract.empty) return of([]);
                        return combineLatest(...productionContract.docs.map(c=>{
                            return docData(db.collection('commodity_types').doc(c.data().type)).pipe(
                                map(t=>{
                                    return {...c.data(),type:t.name, typeId: c.data().type}
                                })
                            )
                        }))
                    })
                )
                .pipe( switchMap(productionContract=>{
                        if(productionContract.length==0) return of([]);
                        return combineLatest(...productionContract.map(c=>{
                            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                            map(t=>{
                                return {...c,subtype:t.name, subtypeId: c.subtype}
                            }))
                        }))
                    })
                )
                .pipe(
                        switchMap(productionContract => {
                        if (productionContract.length == 0) return of([]);
                        return combineLatest(
                            ...productionContract.map(c =>
                                docData(db.collection('users').doc(c.userID)).pipe(
                                    map(u => ({
                                        ...c,
                                        user: u
                                    })),
                                    catchError(() => {
                                        console.log(c.userID)
                                        // Handle the case where the user does not exist
                                        return of(null);
                                    })
                                )
                            )
                        );
                    })
                ).pipe(
                    map((results) =>{
                        return results.filter(result => result.user.about != undefined)
                    })
                )
                .subscribe((data) => {
                    vm.disabledDeletedButton = true;
                    vm.initializeGrid(data);                     
                    window.showPCConfirmationPopup = vm.showPCConfirmationPopup.bind(this);
                })
                        
            },

        async showPCConfirmationPopup(item) {
            const vm =this; 
            vm.confirmationPopup = true;
            vm.ProductionContractID = item;
            console.log(item);
        },
        
        async updateSelectedIds(){
            const vm = this;
            const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked')
            vm.selectedProductionContract = Array.from(checkboxes).map(item => item.value)
            console.log(vm.selectedProductionContract);
            if(vm.selectedProductionContract.length > 0){
                vm.disabledDeletedButton = false;
            } else {
                vm.disabledDeletedButton = true;
            }
        },

        async confirmRestoreProductionContract(){
            const vm = this;
            await db.collection('production_contract').doc(vm.ProductionContractID).update({
                is_delete:0
            }).then(async () => {
                vm.confirmationPopup = false;
                vm.ProductionContractID = null;
                vm.confirmationPopup = false;
                vm.loading = true;
                await vm.clearGrid();
                setTimeout(async() => {
                    await vm.getDeleteAllProductionContractList(); 
                }, 2000)
               
            }).catch((e) =>{
                console.log('error' + e);
            })

        },

        async confirmRestoreMultipleProductionContract(){
            const vm = this;
            try{
                vm.multipleListingConfirmationPopup = false;

                vm.loading = true;

                await Promise.all(vm.selectedProductionContract.map(async (item) => {
                    await db.collection('production_contract').doc(item).update({
                        is_delete:0
                    })
                }))
             
                await vm.clearGrid()

                await new Promise(resolve => setTimeout(resolve, 2000));

                vm.selectedProductionContract = [];

                await vm.getDeleteAllProductionContractList();
                
            } catch(error) {
                console.error('Error during restore operation:', error);
            }   
           
        },

        async restoreMultipleListing(){
            const vm = this;
            vm.multipleListingConfirmationPopup = true;
        }

       
    }


}
</script>
<style>
</style>