<template>
   <div class="container">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary" class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.product>
                                <span class="h5 mb-0">Products</span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel" 
                            id="product"
                        >
                            <b-card-body>
                                <div>
                                    <AllProductDeletedList />
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>                    
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary" class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.productionContract>
                                <span class="h5 mb-0">Production Contract</span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel" 
                            id="productionContract"
                        >
                            <b-card-body>
                                <div>
                                    <AllProductionContractDeletedList />
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block variant="primary" class="m-0 d-flex justify-content-between align-items-center" v-b-toggle.productBidRequest>
                                <span class="h5 mb-0">Product Bid Request</span>
                                <div>
                                    <span class="when-opened">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </span>
                                    <span class="when-closed">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </b-button>
                        </b-card-header>
                        <b-collapse
                            accordion="my-accordion"
                            role="tabpanel" 
                            id="productBidRequest"
                        >
                            <b-card-body>
                                <div>
                                    <AllBidRequestDeletedList />
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AllProductDeletedList from "@/views/Dashboard/AllDeletingListing/AllProductDeletedList.vue";
import AllBidRequestDeletedList from "@/views/Dashboard/AllDeletingListing/AllBidRequestDeletedList.vue";
import AllProductionContractDeletedList from "@/views/Dashboard/AllDeletingListing/AllProductionContractDeletedList.vue";
// import { db } from '@/firebase';



export default {
    name: 'AllDeleteListing',
    computed: {
        ...mapState(['user'])
    },
    created(){
        const vm = this;
        if(!vm.user.role.admin){
            vm.$router.replace("/not/access")
        }
    },

    data (){
        return {
            tempCount:0
        }
    },

    mounted(){
        const vm = this;
        vm.test();
    },
    
    components: {
        AllProductDeletedList,
        AllBidRequestDeletedList,
        AllProductionContractDeletedList
    },

    methods:{
        async test(){

            // await db.collection('commodities').where('is_delete','==', 1).get().then((querySnapshot) => {
            //     querySnapshot.forEach(async(doc) => {
            //         console.log(doc)
            //         await db.collection('inbox').where('commodityID', '==', doc.id).get().then((querySnapshot) => {
            //             if(querySnapshot.docs.length > 0){
            //                 this.tempCount = this.tempCount + querySnapshot.docs.length;
            //             }
            //             querySnapshot.forEach((doc) => {
            //             //     console.log(doc.id);
            //                 db.collection('inbox').doc(doc.id).update({
            //                     is_restore: 1
            //                 }).then(() => {
            //                     console.log('123')  
            //                 })
            //             });
            //         });

            //         //    await db.collection('messages').where('commodityID', '==', doc.id).get().then((querySnapshot) => {
            //         //         if(querySnapshot.docs.length > 0){
            //         //             this.tempCount = this.tempCount + querySnapshot.docs.length;
            //         //         }
            //         //         querySnapshot.forEach((doc) => { 
            //         //             db.collection('messages').doc(doc.id).update({
            //         //                 is_restore: 1
            //         //             }).then(() => {
            //         //                 console.log('345')  
            //         //             })
            //         //         });
            //         //     });

            //             // await db.collection('orders').where('commodityID', '==', doc.id).get().then((querySnapshot) => {
            //             //     if(querySnapshot.docs.length > 0){
            //             //         this.tempCount = this.tempCount + querySnapshot.docs.length;
            //             //     }
            //             //     querySnapshot.forEach((doc) => { 
            //             //         console.log(doc.id);
            //             //         if(doc.id !== undefined){
            //             //             db.collection('orders').doc(doc.id).update({
            //             //                 is_restore: 1
            //             //             }).then(() => {
            //             //                 console.log('345')  
            //             //             })
            //             //         }
            //             //     });
            //             // });

            //             // await db.collection('products_negotiation').where('commodityID', '==', doc.id).get().then((querySnapshot) => {
            //             //     if(querySnapshot.docs.length > 0){
            //             //            querySnapshot.forEach((doc) => { 
            //             //                 if(doc.id !== undefined){
            //             //                     db.collection('products_negotiation').doc(doc.id).update({
            //             //                         is_restore: 1
            //             //                     }).then(() => {
            //             //                         console.log('345')  
            //             //                     })
            //             //                 }
            //             //             });
            //             //             return;
            //             //     }
                        
            //             // });
            //             console.log(this.tempCount);
            //     })

            // })
            

            //  await db.collection('bid_request').where('is_delete','==', 1).get().then((querySnapshot) => {
            //     querySnapshot.forEach(async(doc) => {
            //             await db.collection('inbox').where('bidRequestID', '==', doc.id).get().then((querySnapshot) => {
            //                     if(querySnapshot.docs.length > 0){
            //                         this.tempCount = this.tempCount + querySnapshot.docs.length;
            //                     }
            //                     querySnapshot.forEach((doc) => {
            //                         console.log('inbox ' + doc.id);
            //                         db.collection('inbox').doc(doc.id).update({
            //                             is_restore: 1
            //                         }).then(() => {
            //                             console.log('123')  
            //                         })
            //                 });
            //             });
       
            //             await db.collection('messages').where('bidRequestID', '==', doc.id).get().then((querySnapshot) => {
            //                 if(querySnapshot.docs.length > 0){
            //                     this.tempCount = this.tempCount + querySnapshot.docs.length;
            //                 }
            //                 querySnapshot.forEach((doc) => { 
            //                     console.log('message ' + doc.id);
            //                     db.collection('messages').doc(doc.id).update({
            //                         is_restore: 1
            //                     }).then(() => {
            //                         console.log('345')  
            //                     })
            //                 });
            //             });

            //             await db.collection('bid_request_complete').where('bidRequestID', '==', doc.id).get().then((querySnapshot) => {
            //                 if(querySnapshot.docs.length > 0){
            //                     this.tempCount = this.tempCount + querySnapshot.docs.length;
            //                 }
            //                 querySnapshot.forEach((doc) => { 
            //                     console.log('bid_request_complete ' + doc.id);
            //                     if(doc.id !== undefined){
            //                         db.collection('bid_request_complete').doc(doc.id).update({
            //                             is_restore: 1
            //                         }).then(() => {
            //                             console.log('567')  
            //                         })
            //                     }
            //                 });
            //             });

            //             await db.collection('bid_request_negotiation').where('bidRequestID', '==', doc.id).get().then((querySnapshot) => {
            //                 if(querySnapshot.docs.length > 0){
            //                     this.tempCount = this.tempCount + querySnapshot.docs.length;
            //                 }
            //                 querySnapshot.forEach((doc) => { 
            //                     console.log('bid_request_negotiation ' + doc.id);
            //                     if(doc.id !== undefined){
            //                         db.collection('bid_request_negotiation').doc(doc.id).update({
            //                             is_restore: 1
            //                         }).then(() => {
            //                             console.log('789')  
            //                         })
            //                     }
            //                 });
            //             });
            //     })
            //     console.log(this.tempCount);
            //  })


            // await db.collection('production_contract').where('is_delete','==', 1).get().then((querySnapshot) => {
            //     querySnapshot.forEach(async(doc) => {
            //         await db.collection('inbox').where('productionContractID', '==', doc.id).get().then((querySnapshot) => {
            //                 if(querySnapshot.docs.length > 0){
            //                     this.tempCount = this.tempCount + querySnapshot.docs.length;
            //                     querySnapshot.forEach((doc) => {
            //                         db.collection('inbox').doc(doc.id).update({
            //                             is_restore: 1
            //                         }).then(() => {
            //                             console.log('123')  
            //                         })
            //                     });
            //                 }
            //         });

            //         // await db.collection('messages').where('productionContractID', '==', doc.id).get().then((querySnapshot) => {
            //         //     if(querySnapshot.docs.length > 0){
            //         //         console.log(doc.id + " " +  querySnapshot.docs.length)
            //         //         this.tempCount = this.tempCount + querySnapshot.docs.length;
            //         //         querySnapshot.forEach((doc) => { 
            //         //             console.log('message ' + doc.id);
            //         //             db.collection('messages').doc(doc.id).update({
            //         //                 is_restore: 1
            //         //             }).then(() => {
            //         //                 console.log('345')  
            //         //             })
            //         //         });
            //         //     }
            //         // });
            //         // await db.collection('production_contracts_complete').where('productionContractID', '==', doc.id).get().then((querySnapshot) => {
            //         //     if(querySnapshot.docs.length > 0){
            //         //         this.tempCount = this.tempCount + querySnapshot.docs.length;
            //         //          querySnapshot.forEach((doc) => { 
            //         //             if(doc.id !== undefined){
            //         //                 db.collection('production_contracts_complete').doc(doc.id).update({
            //         //                     is_restore: 1
            //         //                 }).then(() => {
            //         //                     console.log('567')  
            //         //                 })
            //         //             }
            //         //         });
            //         //     }
                       
            //         // });
            //         // console.log(doc.id)
            //         // await db.collection('production_contract_negotiation').where('productionContractID', '==', doc.id).get().then((querySnapshot) => {
            //         //     if(querySnapshot.docs.length > 0){
            //         //         this.tempCount = this.tempCount + querySnapshot.docs.length;
            //         //          querySnapshot.forEach((doc) => { 
            //         //             if(doc.id !== undefined){
            //         //                 db.collection('production_contract_negotiation').doc(doc.id).update({
            //         //                     is_restore: 1
            //         //                 }).then(() => {
            //         //                     console.log('789')  
            //         //                 })
            //         //             }
            //         //         });
            //         //     }
                       
            //         // });
            //         console.log(this.tempCount);
            //     })
                
            // }) 

           

            // await db.collection('bid_request_complete').get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => { 
            //         if(doc.id !== undefined){
            //             db.collection('bid_request_complete').doc(doc.id).update({
            //                 is_restore: null
            //             }).then(() => {
            //             console.log('345')  
            //         })
            //         }
            //     });
            // });
            // await db.collection('orders').get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => { 
            //         console.log(doc.id);
            //         if(doc.id !== undefined){
            //             db.collection('orders').doc(doc.id).update({
            //                 is_restore: null
            //             }).then(() => {
            //                 console.log('345')  
            //             })
            //         }
            //     });
            // });
            // await db.collection('production_contracts_complete').get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => { 
            //         console.log(doc.id);
            //         if(doc.id !== undefined){
            //             db.collection('production_contracts_complete').doc(doc.id).update({
            //                 is_restore: null
            //             })
            //         }
            //     });
            // });

            // await db.collection('production_contract_negotiation').get().then((querySnapshot) => {
            //     querySnapshot.forEach((doc) => { 
            //         if(doc.id !== undefined){
            //             db.collection('production_contract_negotiation').doc(doc.id).update({
            //                 is_restore: null
            //             })
            //         }
            //     });
            // });
        }
    }



}
</script>

<style>
.b-modal .modal-body {
  border: none; /* Hides the border of the modal content */
}
th.gridjs-th:last-child{
    width: 100px !important;
    text-align: center;
}
th.gridjs-th:first-child{
    width: 50px !important;
    text-align: center;
}
td.gridjs-td:last-child, td.gridjs-td:first-child{
    text-align: center;
}

.modal-lg, .modal-xl { max-width: 450px;}
.modal-header { border-bottom: 0px; padding-bottom: 0px;}
h2 { font-size: 25px !important; text-align: center; margin-top: 10px; margin-bottom: 0px;}
.modal-footer { justify-content: center; gap: 5px;}
.modal-header .close { padding: 0.5rem 0.8rem; font-size: 25px; opacity: 1;}
td.gridjs-td{
    padding: 8px 20px;
}
</style>