<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <div  v-if="completeBidRequest != null" class="row">
                <div class="d-block d-md-none col-12"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div>
                <div class="col-12">
                    <CompleteHeader :completeBidRequest = "completeBidRequest" />
                </div>
                <div class="col-12">
                    <Timeline :completeBidRequest = "completeBidRequest" />
                </div>
            </div>
            <div v-else>
                <div class="col d-none d-md-flex"></div>
                <div class="col-12 col-md-8 col-xl-6 pt-4">
                    <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                        <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                        <template>
                            <h4 class="card-title mt-0 mb-4">Complete Product Bid Request requested Not exist</h4>
                            <p><a href="/bid/request" class="text-info">Browse Product Bid</a></p>
                            <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                        </template>
                    </card>
                </div>
                <div class="col d-none d-md-flex"></div>
            </div>
        </div>
       
    </div>
</template>

<script>

import {mapState} from 'vuex'
import {db} from '@/firebase';
import {bus} from '@/vuex/bus.js';
import CompleteHeader from "./components/CompleteBidRequestHeader.vue";
import Timeline from './components/Timeline.vue';

export default {
    computed:{
        ...mapState(['user']),
    },

    data() {
        return {
            loading:true,
            completeBidRequest:null
        };
    },
    components:{
        CompleteHeader,
        Timeline
    },

    async mounted(){
        const vm = this;
        vm.load();
        bus.$emit('prepareTitle', null);
    },

    async created(){
        const vm = this;
            vm.loading = true;
    },

    methods: {
        async load(){
            const vm = this;
            if(vm.$route.params.id) vm.completeBidRequestID = vm.$route.params.id;
            
            let completeBidRequest = await db.collection('bid_request_complete').doc(vm.completeBidRequestID).get();
            if(completeBidRequest.exists) vm.completeBidRequest = {...completeBidRequest.data(),id:completeBidRequest.id};

            let BidRequest = await db.collection('bid_request').doc(vm.completeBidRequest.bidRequestID).get();
            if(BidRequest.exists) vm.completeBidRequest.BidRequest = {...BidRequest.data(),id:BidRequest.id};

            let ref = await db.collection('commodity_categories').doc(vm.completeBidRequest.BidRequest.category).get();
            if(ref.exists) vm.completeBidRequest.BidRequest = {...vm.completeBidRequest.BidRequest,category:ref.data().name};

            ref = await db.collection('bid_price_options').doc(vm.completeBidRequest.BidRequest.priceOption).get();
            if (ref.exists) vm.completeBidRequest.BidRequest = {...vm.completeBidRequest.BidRequest, priceOption: ref.data().name}

            ref = await db.collection('units').doc(vm.completeBidRequest.BidRequest.unit).get();
            if(ref.exists) vm.completeBidRequest.BidRequest = {...vm.completeBidRequest.BidRequest,unit:ref.data().name};

            ref = await db.collection('commodity_types').doc(vm.completeBidRequest.BidRequest.type).get();
            if(ref.exists) vm.completeBidRequest.BidRequest = {...vm.completeBidRequest.BidRequest,type:ref.data().name};

            ref = await db.collection('commodity_subtypes').doc(vm.completeBidRequest.BidRequest.subtype).get();
            if(ref.exists) vm.completeBidRequest.BidRequest = {...vm.completeBidRequest.BidRequest,subtype:ref.data().name};

             
            if(vm.user.role.admin){
                let bidRequestLocation = await db.collection('bid_request_location').doc(vm.completeBidRequest.bidRequestID).get();
                if(bidRequestLocation.exists) vm.completeBidRequest.BidRequest.location = {...bidRequestLocation.data()};

                ref = await db.collection('users').doc(vm.completeBidRequest.producerID).get();
                if(ref.exists) vm.completeBidRequest.producer = {...ref.data()};

                ref = await db.collection('users').doc(vm.completeBidRequest.buyerID).get();
                if(ref.exists) vm.completeBidRequest.buyer = {...ref.data()};
            }

            vm.completeBidRequest.created = vm.completeBidRequest.created.toDate();
            vm.loading = false

            bus.$emit('prepareTitle', {...vm.completeBidRequest, isCompleteBidRequest:true});
        },

        backTo(){
            bus.$emit('backToTop');
        }
    }
}

</script>