<template>
    <div>
        <div class="container">
            <div class="d-flex justify-content-end">
                <b-button variant="danger" :disabled="disabledDeletedButton" @click="restoreMultipleListing">Restores</b-button>
            </div>
            <div>
                <div v-if="loading">
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </div>
                <div>
                    <div id="bidRequestGrid" ref="bidRequestGrid"></div>
                </div>
            </div>
        </div>
        <div>
            <b-modal ref="model" size="lg" v-model="confirmationPopup">
                <h2>
                    Are you sure you want to restore this product bit request?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreBidRequest">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>
        <div>
            <b-modal ref="model" size="lg" v-model="multipleListingConfirmationPopup">
                <h2>
                    Are you sure you want to restore this product bit requests?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreMultipleBidRequest">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { db } from '@/firebase';
import { docData } from 'rxfire/firestore';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of, from, combineLatest } from 'rxjs';
import { Grid, html } from 'gridjs';
import 'gridjs/dist/theme/mermaid.css';
import moment from 'moment';



export default {
    name: 'AllBidRequestDeletedList',
    computed: {
        ...mapState(['user'])
    },
    data(){
        return {
            deleteProductBidRequest: [],
            confirmationPopup: false,
            multipleListingConfirmationPopup: false,
            loading: true,
            gridInstance: null,
            bidRequestID: null,
            selectProductBidRequest:[],
            disabledDeletedButton: true
        }
    },
    created(){
        const vm = this;
        if(!vm.user.role.admin){
            vm.$router.replace("/not/access")
        }
    },
    async mounted() {
       const vm = this;
       await vm.getDeleteAllProductBidRequestList();
    },


    methods:{


        clearGrid(){
            const vm = this;
            if(vm.gridInstance){
                vm.gridInstance.destroy();
                vm.gridInstance = null;
            }
            vm.$refs.bidRequestGrid.innerHTML = '';
        },

        initializeGrid(data){
            const vm  = this;
            const showPagination = data.length > 10;
            vm.gridInstance = new Grid({
                    columns: [
                        {
                            id: 'checkbox',
                            name: '',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 50px;' // Set width inline
                            }
                        },
                        {
                            id: 'index',
                            name: '#',
                            formatter: (cell) => html(cell), // Display 1-based index
                            attributes: {
                                style: 'width: 50px; text-align:center' // Set width inline
                            }
                        },
                        {
                            id: 'product_bid_name',
                            name: 'Product Bid Name',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 220px;' // Set width inline
                            }
                        },
                        {
                            id: 'created_by',
                            name: 'Created By',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 220px;' // Set width inline
                            }
                        },
                        {
                            id: 'created_date',
                            name: 'Created Date',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 150px;' // Set width inline
                            }
                        },
                        {
                            id: 'deleted_date',
                            name: 'Deleted Date',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 150px;' // Set width inline
                            }
                        },
                        {
                            id: 'action',
                            name:'Action',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 100px;' // Set width inline
                            }
                        }
                    ],
                    pagination: showPagination ? { limit: 10} : false,
                    search: false,
                    data:  data.map((data, rowIndex) => {
                        return [`<input type="checkbox" id="checkbox-${rowIndex}" value="${data.id}" class="row-checkbox" />` ,rowIndex + 1, data.subtype + " " + data.type, data.user.about.first + " " + data.user.about.last, moment(data.created.toDate()).format('ll'), (data.deleted != undefined) ? moment(data.deleted.toDate()).format('ll') : '-',`  <button class="btn btn-sm btn-primary m-0" onclick="showConfirmationPopup('${data.id}')">
                            <i class="fa fa-clipboard mr-2"></i> Restore</button>`
                        ]
                    })
                }).render(vm.$refs.bidRequestGrid)
                vm.loading = false;

                document.getElementById('bidRequestGrid').addEventListener('change', (event) => {
                    if(event.target.type === 'checkbox'){
                      vm.updateSelectedIds();
                    }
                });
        },

        async getDeleteAllProductBidRequestList() {
            const vm = this;
            let ref = db.collection('bid_request').where('is_delete','==', 1).orderBy('created', 'desc').limit(10);
            from(ref.get()).pipe(
                 switchMap(bidRequests=>{
                if(bidRequests.empty) return of([]);
                    return combineLatest(...bidRequests.docs.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.data().type)).pipe(
                            map(t=>{
                                return {...c.data(),type:t.name, typeId: c.data().type}
                            })
                        )
                    }))
                })
            ).pipe( switchMap(bidRequests=>{
                    if(bidRequests.length==0) return of([]);
                    return combineLatest(...bidRequests.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                        map(t=>{
                            return {...c,subtype:t.name, subtypeId: c.subtype}
                        })
                        )
                    }))
                })
            ).pipe(
                switchMap(bidRequests=>{
                    if(bidRequests.length==0) return of([]);
                    return combineLatest(...bidRequests.map(c=>{
                        return docData(db.collection('users').doc(c.userID)).pipe(
                            map(u => ({
                                ...c,
                                user: u
                            })),
                            catchError(() => {
                                console.log(c.userID)
                                // Handle the case where the user does not exist
                                return of(null);
                            })
                        )
                    }))
                })

            ).pipe(
                map((results) =>{
                    return results.filter(result => result.user.about != undefined)
                })
            )
            .subscribe((data) => {
                vm.disabledDeletedButton = true;
                vm.initializeGrid(data);           
                window.showConfirmationPopup = this.showConfirmationPopup.bind(this);
                
            })    
        },

        async showConfirmationPopup(item) {
            const vm =this;
            vm.confirmationPopup = true;
            vm.bidRequestID = item;
        },
            

        async updateSelectedIds(){
            const vm = this;
            const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked')
            vm.selectProductBidRequest = Array.from(checkboxes).map(item => item.value)
            if(vm.selectProductBidRequest.length > 0){
                vm.disabledDeletedButton = false;
            } else {
                vm.disabledDeletedButton = true;
            }
        },

        async confirmRestoreBidRequest(){
            const vm = this;
            try {
                await db.collection('bid_request').doc(vm.bidRequestID).update({
                    is_delete:0
                });

                vm.confirmationPopup = false;
                vm.ProductionContractID = null;
                vm.loading = true;
                await vm.clearGrid();
                setTimeout(async() => {
                    await vm.getDeleteAllProductBidRequestList(); 
                }, 2000)
               

            } catch(error) {
                console.error('error restoring bid request:', error);
            }
        },

        async confirmRestoreMultipleBidRequest(){
            const vm = this;
            try{
                vm.multipleListingConfirmationPopup = false;

                vm.loading = true;

                await Promise.all(vm.selectProductBidRequest.map(async (item) => {
                    await db.collection('bid_request').doc(item).update({
                        is_delete:0
                    })
                }))
             
                await vm.clearGrid()

                await new Promise(resolve => setTimeout(resolve, 2000));

                vm.selectProductBidRequest = [];

                await vm.getDeleteAllProductBidRequestList();
                
            } catch(error) {
                console.error('Error during restore operation:', error);
            }   
           
        },

        async restoreMultipleListing(){
            const vm = this;
            vm.multipleListingConfirmationPopup = true;
        }

    }


}
</script>

<style>

</style>