<template>
  <div class="row">
    <div class="col"></div>
    <div class="col-lg-10">
      <b-alert v-if="error" class="mb-2" show variant="danger">{{error}}</b-alert>
      <card class="card-signup text-center" no-footer-line :cardBodyClasses="'p-0'">

        <template v-if="complete">

          <h1 class="text-center">Congratulations!</h1>
          <p class="text-center">You've successfully updated your profile.</p>
          <p class="text-center"><button @click="startOver" class="btn btn-primary">Start Over</button></p>

        </template>
        <template v-else>

          <simple-wizard ref="simpleWizard">
            <template slot="header" > 
              <h3 class="card-title text-white mt-0 pt-2">Edit your profile</h3>
            </template>

            <wizard-tab :before-change="() => validateStep('registerAbout')" :unrestricted="true">
              <template slot="label">
                <i class="fa fa-user-circle"></i>
                <span class="d-none d-md-inline">About</span>
              </template>
              <RegisterAbout ref="registerAbout" @on-validated="onStepValidated" />
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('registerBusinessInfo')" :unrestricted="true">
              <template slot="label">
                <i class="fa fa-briefcase"></i>
                <span class="d-none d-md-inline">Business Info</span>
              </template>
              <RegisterBusinessInfo ref="registerBusinessInfo" @on-validated="onStepValidated" />
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('registerAccount')" :unrestricted="true">
              <template slot="label">
                <i class="fa fa-cog"></i>
                <span class="d-none d-md-inline">Account</span>
              </template>
              <RegisterAccount ref="registerAccount" @on-validated="onStepValidated" />
            </wizard-tab>
          </simple-wizard>

        </template>

      </card>
    </div>
    <div class="col"></div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {db,auth} from '@/firebase';
import RegisterAbout from "@/views/Auth/UserProfile/About.vue";
import RegisterBusinessInfo from "@/views/Auth/UserProfile/BusinessInfo.vue";
import RegisterAccount from "@/views/Auth/UserProfile/Account.vue";
import { SimpleWizard, WizardTab } from "@/components";
export default {
  components: {
    SimpleWizard,
    WizardTab,
    RegisterAbout,
    RegisterBusinessInfo,
    RegisterAccount
  },
  data() {
    return {
      error:null,
      complete:false,
      signup:{
        about:null,
        business:null,
        account:null
      }
    };
  },
  computed:{
    ...mapState(['user'])
  },
  methods: {
    async onStepValidated(model){
      let vm = this;
      vm.error=null;
      try{
        if(model.about&&model.about.email!=vm.user.about.email){
          let user = await auth.signInWithEmailAndPassword(vm.user.about.email,model.password);
          delete model.password;
          user = auth.currentUser;
          await user.updateEmail(model.about.email);
          await db.collection('users').doc(vm.user.uid).update(model);
          vm.$store.commit('updateUser',{...vm.user,about:{...model.about}});
          vm.$refs.simpleWizard.activeTabIndex++;
        }else if(model.account){
          let user = auth.currentUser;
          await user.updatePassword(model.account.password);
          vm.complete=true;
        }else{
          await db.collection('users').doc(vm.user.uid).update(model);
        }
      }catch(e){
        vm.error=e;
      }
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    async load() {

    },
    startOver(){
      this.complete=false;
      this.$refs.simpleWizard.activeTabIndex=0;
    }
  },
  mounted(){
    this.load();
  },
  watch: {
    user () {
      this.load();
    }
  }
};
</script>
<style>
.card-wizard .card-header {
    padding-bottom: 15px !important;
}
</style>
