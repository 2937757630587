<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row">
            <div class="col-12 pr-0 pl-0">
                <b-card class="">
                    <b-card-header>
                        <div class="d-flex flex-row align-items-center p-2 justify-content-between">
                            <h5 class="m-0 text-center" v-if="!user.role.admin">My Production Contract Negotiation</h5> 
                            <!-- <h5 class="m-0 text-center" v-if="user.role.buyer">My Production Contract Negotiations Offer Pending</h5>  -->
                            <!-- <b-button class="m-0 btn float-left" variant="outline-light" @click="myDashboard" size="sm"><i class="fa fa-arrow-left mr-2"></i>Back</b-button> -->
                        </div>
                    </b-card-header>
                    <b-card-body class="px-0">
                        <div v-if="errors.length > 0">
                            <b-alert show variant="danger">
                                <small>{{ errors[0] }}</small>
                            </b-alert>
                        </div>
                        <template v-if="myProductionContractNegotiation.length > 0">
                            <b-list-group>
                                <b-list-group-item  v-for="productionContract in getUniqueValueFromArray(myProductionContractNegotiation)" :key="productionContract.id" class="d-block d-md-flex align-items-center add-pointer">
                                    <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4" @click="productionContractDetails(productionContract.id)">
                                        <h6 class="mt-2 m-md-0">
                                            {{productionContract.productionContract.subtype}} {{productionContract.productionContract.type}}
                                        </h6>
                                        <small>Create Date: {{ formatDate(productionContract.created) }}</small><br />
                                        <small class="d-block d-md-flex"><span>Delivery Window:</span><strong class="d-block d-md-inline flex-fill text-left text-md-left ml-1">{{moment( productionContract.productionContract.startDate.toDate()).format('ll')}} to {{moment(productionContract.productionContract.endDate.toDate()).format('ll')}}</strong></small>
                                        <small class="d-block d-md-flex"><span>Number of Acres:</span><strong class="d-block d-md-inline flex-fill text-left text-md-left ml-1">{{ productionContract.productionContract.available_acres }}</strong></small>
                                    </div>
                                    <div class="text-right mt-2 mt-md-0" v-if="productionContract.created && productionContract.productionContract.userID === user.uid">
                                        <div  v-if="productionContract.is_productionContract_negotiation_status === undefined" >
                                            <small>{{formatDate(productionContract.created)}}</small>
                                            <div class="d-flex flex-row justify-content-between">
                                                <b-button variant="primary" size="md" @click="updateStatus(true, productionContract)" >Accept</b-button>
                                                <b-button variant="danger" size="md" @click="updateStatus(false, productionContract)" class="ml-2">Decline</b-button>
                                            </div>
                                        </div>
                                        <div v-if="productionContract.is_productionContract_negotiation_status !== undefined">
                                            <b-badge variant="danger" pill v-if="productionContract.is_productionContract_negotiation_status == 0">Rejected</b-badge>
                                            <b-button variant="danger" size="sm" :disabled="removeButton" @click="removeFromNegotiation(productionContract)">Remove</b-button>
                                        </div>
                                    </div>
                                    <div class="text-right mt-2 mt-md-0" v-if="productionContract.productionContract.userID !== user.uid">
            
                                        <p v-if="productionContract.is_productionContract_negotiation_status !== undefined">
                                            <b-badge variant="success" pill v-if="productionContract.is_productionContract_negotiation_status == 1">Approved</b-badge>
                                            <b-badge variant="danger" pill v-if="productionContract.is_productionContract_negotiation_status == 0">Rejected</b-badge>
                                            <b-button variant="danger" size="sm" :disabled="removeButton" @click="removeFromNegotiation(productionContract)">Remove</b-button>
                                        </p>
                                        <p v-else>
                                            <b-badge variant="warning" pill>Pending Approval</b-badge>
                                        </p>
                                   </div>
                                </b-list-group-item>
                            </b-list-group>
                        </template>
                        <template v-else>
                            <b>No Production Contract</b>
                        </template>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <div>
            <form ref="acceptForm" @submit.prevent="confirmProductionContractNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationNegotiationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>, for the purchase of the product listed.
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                        >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmProductionContractNegotiation">
                            Continue
                        </b-button>
                    </template>
                </b-modal>
            </form>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase';
import {mapState} from 'vuex';
import { switchMap, map} from 'rxjs/operators';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest,  of } from 'rxjs';
import moment from 'moment';
import {auth} from '@/firebase';
import firebase from 'firebase';


export default {
    computed: {
        ...mapState(['user']),
        sortedProductionNegotiationItems: function(){
            return [...this.myProductionContractNegotiation].sort((a, b) => {
                return b.created.toDate() - a.created.toDate();
            });
        }
    },
    data() {
        return {
            loading: false,
            total: 0,
            myProductionContractNegotiation: [],
            errors: [],
            removeButton : false,
            acceptProductionContractNegotiation: null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
            confirmationNegotiationPopup: false,
            confirmationPopupError:  null,
            
        }
    },
    async mounted(){
        this.getMyProductionContract();
    },
    methods: {
        getUniqueValueFromArray(products){      
            return [
                ...new Map(
                    products.map((item) => [item['id'], item])
                ).values()
            ]
        },
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        async getMyProductionContract(){
            let vm = this;
            vm.loading = true;
            vm.myProductionContract = [];
            vm.total = 0;
            let ref = await  db.collection('production_contract_negotiation').where('is_restore', '==', null)
            // if(vm.user.role.buyer) ref = ref.where('buyerID', '==', vm.user.uid) 
            // if(vm.user.role.producer) ref = ref.where('producerID', '==', vm.user.uid)
            collectionData(ref, 'id').pipe(
                switchMap((productionContracts) => {
                    if(productionContracts.empty) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                    return docData(db.collection('production_contract').doc(c.productionContractID)).pipe(
                        map(productionContract=>{
                        if(JSON.stringify(c) !== '{}'){
                            return {...c, productionContract: productionContract}
                        } else {
                            return of({});
                        }                            
                        })
                    )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
                            map(t=>{
                                return {...c, productionContract: {...c.productionContract,type:t.name}}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
                        map(t=>{
                            return {...c, productionContract: {...c.productionContract,subtype:t.name}}
                        })
                        )
                    }))
                })
            ).subscribe((productionContracts) => {
                if(productionContracts.length != 0){
                    vm.myProductionContractNegotiation = [],
                    productionContracts.map((item) => {
                        if(item.is_productionContract_negotiation_status != true || item.is_productionContract_negotiation_status == undefined){
                            if(item.productionContract.userID !== vm.user.uid && item.producerID === vm.user.uid){
                                vm.myProductionContractNegotiation.push(item);
                            } else if(item.productionContract.userID === vm.user.uid && item.buyerID === vm.user.uid) {
                                vm.myProductionContractNegotiation.push(item);
                            }   
                        }
                    });
                }
            }); 
            setTimeout(() => {
                vm.loading = false;                    
            }, 5000);
        },

        async removeFromNegotiation(productionContractNegotiation){
            if(confirm('Are you sure you want Remove this production contract from negotiation?')){
                this.removeButton = true;
                let productionContract = await db.collection('production_contract_negotiation').doc(productionContractNegotiation.id);
                productionContract.delete().then(() => {
                    this.loading = true;
                    this.removeButton = false;
                    this.myProductionContractNegotiation = [],
                    this.getMyProductionContract();
                });
            }       
        },

        productionContractDetails(productionContractID){
            this.$router.push('/production/contract/negotiation/'+productionContractID)
        },

        async updateStatus(negotiationStatus, productionContractNegotiation){
            const vm = this;
            console.log(productionContractNegotiation);
            vm.acceptProductionContractNegotiation = null;
            if(negotiationStatus){
                vm.acceptProductionContractNegotiation = productionContractNegotiation;
                vm.confirmationNegotiationPopup = true;
            } else {
                if(confirm('Are you sure want payment request Reject?')){
                    let ref = db.collection('production_contract_negotiation').doc(productionContractNegotiation.id);
                    ref.update({is_productionContract_negotiation_status: 0, negotiation_id: productionContractNegotiation.id}).then(async() => {
                        vm.confirmNegotiationLoading = false;
                        vm.confirmationNegotiationPopup = false;      
                    });
                }
            }
        },

        async confirmProductionContractNegotiation(){
            const vm = this;
            if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                    return true;
                }).catch(() => {
                    vm.confirmationPopupError = "This password is invalid."
                    vm.confirmNegotiationLoading = false;
                    setTimeout(() => {
                        vm.confirmationPopupError = null;
                    }, 4000);
                    return;
                });
                if(isAuth){
                    vm.loading = true;
                    let ref = db.collection('production_contract_negotiation').doc(vm.acceptProductionContractNegotiation.id);
                    ref.update({is_productionContract_negotiation_status:1, negotiation_id:vm.acceptProductionContractNegotiation.id, is_buyer_verified: true }).then(async() => {
                        await vm.completeProductionContract();
                        vm.confirmNegotiationLoading = false;
                        vm.confirmationNegotiationPopup = false;      
                        vm.myProductionContractNegotiation = [],
                        vm.getMyProductionContract();
                    });
                }

            }
        },

        async completeProductionContract(){
            const vm = this;
            let data = {};
            let ref = db.collection('production_contracts_complete').doc();
            let id =  ref.id;
            data.id = id;
            data.productionContractID = vm.acceptProductionContractNegotiation.productionContractID;
            data.buyerID = vm.user.uid;
            data.producerID = vm.acceptProductionContractNegotiation.producerID;
            data.fullFillAmount = vm.acceptProductionContractNegotiation.fullFillAmount;
            data.productionContractNegotiationId = vm.acceptProductionContractNegotiation.id;
            data.status = 'completed'
            data.created = firebase.firestore.FieldValue.serverTimestamp();
            data.updated = firebase.firestore.FieldValue.serverTimestamp();
            data.is_restore = null;
            await db.collection('production_contracts_complete').doc(id).set(data).then(async() =>{
                let ref = await db.collection('production_contract_negotiation').doc(vm.acceptProductionContractNegotiation.id);
                ref.delete().then(async() => {
                    await vm.deactivateProductionContract();
                });   
            });
            return;
        },

        
        resetConfirmationModel(){
            let vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
            vm.selectNegotiationList = false;
        },

        async deactivateProductionContract(){
            const vm = this;
            let ref = await db.collection('production_contract')
                              .doc(vm.acceptProductionContractNegotiation.productionContractID)
                              .get();
            let productionContract = null;
            if(ref.exists) productionContract = {...ref.data()};
            if(parseInt(productionContract.accepted_full_fill_amount) === 0 && productionContract.accepted_full_fill_amount != undefined) {
                let ref = await db.collection('production_contract').doc(vm.acceptProductionContractNegotiation.productionContractID)
                ref.update({'active': false});
            }
        },


    }
    
}

</script>

<style>
.card-body {
    padding: 0;
}
</style>