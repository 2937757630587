<template>
  <div class="forgotPassword-card">
    <div class="card-header bg-primary text-center mt-0 py-2">
      <img slot="header" src="/img/logo.png" class="img-fluid">
      <h4 class="card-title text-white mt-0">Forgot your password?</h4>
    </div>
    <div v-if="!hasSent" class="card-body pb-0">
      <p class="m-0 mb-4 text-center"><small class="text-bold ">No worries. Fill & submit your email address to receive further instructions.</small></p>

      <div class="mt-4" role="group">
        <b-alert v-if="error" show variant="danger" class="mt-2"><small>{{error}}</small></b-alert>
        <label for="input-live">Email Address:</label>
        <fg-input
          id="email"
          type="email"
          v-model="email"
          addon-left-icon="now-ui-icons ui-1_email-85"
          placeholder="Email"
          class="no-border form-control-lg"
          @keyup.enter.native="send"
        >
        </fg-input>
      </div>
      <div class="mt-4" role="group">
        <button :disabled="hasSent" v-on:click="send" class="btn rounded btn-block btn-primary mb-0">{{hasSent?'Email Sent':'Submit'}}</button>
      </div>
    </div>
    <div v-else class="card-body">
      <h4 class="mb-4">Instructions sent!</h4>
      <p>You should soon receive an email with instructions on how to reset your password.</p>

    </div>
    <div class="card-footer text-left">
        <button @click="$emit('return')" class="btn btn-link text-primary p-0">« Back to login</button>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase';
import {mapState} from 'vuex'
export default {
  name: 'ForgotPassword',
  computed: {
    ...mapState(['user']),
  },
  props: [],
  data: function() {
    return {
      hasSent:false,
      polling:null,
      email:'',
      error:null
    };
  },
  methods: {
    send: function() {
      let vm = this;
      firebase.auth().sendPasswordResetEmail(this.email).then(
        ()=>{
          vm.hasSent=true;
        },
        err => {
          vm.error=err.message
        }
      );
    }
  }
};
</script>
<style>
.forgotPassword-card .card-footer{
  position: relative !important; 
}

.forgotPasswordHeader{
  width: 300px;
  margin-top: 6px;
}

</style>