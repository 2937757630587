<template>
  <ValidationObserver ref="form">
    <b-spinner v-if="loading" />
    <form @submit.prevent="validate" v-else>
      <div class="pl-4 pr-4 pt-0 pt-md-4">
        <h3 class="d-block d-md-none">About</h3>
        <div class="row">
          <div class="col-12 col-md-6 pb-2">
            <ValidationProvider
              name="signup.about.first"
              rules="required|min:2"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Please enter a first name of at least 2 characters'"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="First name (required)" v-model="signup.about.first" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12 col-md-6 pb-2">
            <ValidationProvider
              name="signup.about.last"
              rules="required|min:2"
              v-slot="{ passed, failed }"
            >
              <b-form-group
                :invalid-feedback="'Please enter a last name of at least 2 characters'"
                :state="(failed ?false:(passed?true:null))"
              >
                <b-form-input placeholder="Last name (required)" v-model="signup.about.last" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 pb-2">
            <ValidationProvider
              name="signup.about.phone"
              rules="required"
              v-slot="{ passed }"
            >
              <b-form-group
                :invalid-feedback="'Please enter a valid phone'"
                :state="(!isValidPhoneNumber?false:(passed?true:null))"
              >
                <VuePhoneNumberInput @update="updatePhoneProfile" ref="phoneNumber" :noValidatorState="true" :borderRadius="30" valid-color="#28a745" color="#7ec313" placeholder="Phone (required)" v-model="signup.about.phone" :state="(!isValidPhoneNumber?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12 col-md-6 pb-2">
            <ValidationProvider
              name="signup.about.email"
              rules="emailRequired|email"
              v-slot="{passed,failed,errors}"
            >
              <b-form-group
                :invalid-feedback="errors.length>0?errors[0]:null"
                :state="(failed?false:(passed?true:null))"
              >
                <b-form-input placeholder="Email (required)" v-model="signup.about.email" :state="(failed?false:(passed?true:null))" />
              </b-form-group>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
    <b-modal v-model="enterPassword" size="md" title="Change Email">
      <div class="personal-details">
        <b-alert variant="info" class="mt-2 mb-2" show>Changing your email address requires you to reenter your password.</b-alert>
        <div role="group" class="mt-4 mb-4">
          <label for="input-live">ENTER YOUR PASSWORD</label>
          <b-form-input
            id="changeEmailPassword"
            type="password"
            v-model="password"
            placeholder=""
            trim
          ></b-form-input>
        </div>
      </div>
      <template slot="modal-footer">
        <b-button @click="()=>{this.signup.about.email=this.user.about.email;this.enterPassword=false;}" variant="outline-primary">Cancel</b-button>
        <b-button @click="validate" variant="primary">Change</b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>
<script>
import {mapState} from 'vuex'
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
extend("regex", regex);
extend("required", required);
extend("emailRequired", {
  ...required,
  message:'Email is required'
});
extend("email", {
  ...email,
  message:'Enter a valid email'
});

// extend('phone', () => {
//   return isValidPhoneNumber;
// });
// var isValidPhoneNumber = null;
export default {
  components: {
    VuePhoneNumberInput
  },
  computed:{
    ...mapState(['user'])
  },
  data() {
    return {
      loading:true,
      password:null,
      enterPassword:false,
      isPhoneLoaded:false,
      isValidPhoneNumber: true,
      phone: null,
      signup:{
        about:{
          first:null,
          last:null,
          phone:null,
          email:null,
          is_active: true
        }
      }
    };
  },
  methods: {
    updatePhoneProfile(obj){
      console.log(obj)
      // isValidPhoneNumber = null
      this.isValidPhoneNumber = obj.isValid;
      if(!this.isPhoneLoaded) {
        this.isPhoneLoaded=true;
        this.$refs.form.reset();
      }
    },
    validate() {
      let vm = this;
      if(!vm.isValidPhoneNumber){
        return;
      }
      return this.$refs.form.validate().then(res => {
        if(vm.user.about&&res&&vm.signup.about.email!=vm.user.about.email&&!vm.password){
          vm.enterPassword = true;
          return false;
        }
        if(res) {
          if(vm.password) this.$emit("on-validated", {...this.signup,password:vm.password});
          else this.$emit("on-validated", this.signup);
        }
        vm.enterPassword = false;
        vm.password = null;
        return res;
      });
    }
  },
  mounted(){
    if(this.user.about){
      this.signup.about = {...this.user.about};
    }
    this.loading=false;
  }
};
</script>
<style>
.is-invalid .input-tel__input {
  border-color:red;
}
</style>
