<template>
    <div class="container">
        <b-card>
            <div>
                <b-alert v-if="error" show variant="danger"><small>{{error}}</small></b-alert>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="w-75">
                    <b-input-group prepend="Order Transaction ID">
                        <b-form-input type="text" v-model.trim="searchTransactionID" placeholder="please enter the search order transaction Id"></b-form-input>
                    </b-input-group>
                </div>
                <div class="w-25">
                    <b-button class="ml-2 mt-0"  variant="info" @click="searchTransaction" pill><i class="fa fa-search"></i> Search</b-button>
                </div>
            </div>
        </b-card>
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <div v-if="transaction">
                <div>
                    <b-card title="Transaction Details">
                        <div>
                            <h2 class="mt-2 mb-2 d-none d-md-block">Transaction ID: {{transaction.id}}</h2>
                            <p class="mt-2 mb-2 d-block d-md-none text-center"><span class="text-muted">Transaction ID:</span><br /><strong>{{transaction.id}}</strong></p>
                            <div class="d-block d-md-flex">
                                <div class="flex-fill text-center text-md-left pt-3"><h6 class="d-inline">Purchase of {{numeral(transaction.amount).format('0,000')}} {{transaction.commodity.unit}} {{transaction.commodity.subtype}} {{transaction.commodity.type}}</h6></div>
                                <div class="flex-fill text-center text-md-right"><strong>Total:</strong> <h3 class="d-inline">${{numeral(transaction.total).format('0,000.00')}}</h3></div>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div>
                    <b-card title="Buyer Details">
                        <div v-if="transaction.buyerDetails">
                            <div v-if="transaction.buyerDetails.about">
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>First Name</small>
                                        <div v-if="transaction.buyerDetails.about.first">
                                            <strong>{{ transaction.buyerDetails.about.first }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Last Name</small>
                                        <div v-if="transaction.buyerDetails.about.last">
                                            <strong>{{ transaction.buyerDetails.about.last }}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Email</small>
                                        <div v-if="transaction.buyerDetails.about.email">
                                            <strong>{{ transaction.buyerDetails.about.email }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Phone No.</small>
                                        <div v-if="transaction.buyerDetails.about.phone">
                                            <strong>{{ transaction.buyerDetails.about.phone }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="transaction.buyerDetails.business">
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Buyer Company Name</small>
                                        <div v-if="transaction.buyerDetails.business.name"><strong>{{ transaction.buyerDetails.business.name }}</strong></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <div v-if="transaction.buyerDetails.business.address">
                                            <small>Buyer Address</small><br />
                                            <strong class="d-block">{{transaction.buyerDetails.business.address.address}}</strong>
                                            <strong class="d-block">{{transaction.buyerDetails.business.address.city}}, {{transaction.buyerDetails.business.address.state}} {{ transaction.buyerDetails.business.address.zip}}</strong >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div>
                    <b-card title="Farmer Details">
                        <div v-if="transaction.producerDetails">
                            <div v-if="transaction.producerDetails.about">
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>First Name</small>
                                        <div v-if="transaction.producerDetails.about.first">
                                            <strong>{{ transaction.producerDetails.about.first }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Last Name</small>
                                        <div v-if="transaction.producerDetails.about.last">
                                            <strong>{{ transaction.producerDetails.about.last }}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Email</small>
                                        <div v-if="transaction.producerDetails.about.email">
                                            <strong>{{ transaction.producerDetails.about.email }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Phone No.</small>
                                        <div v-if="transaction.producerDetails.about.phone">
                                            <strong>{{ transaction.producerDetails.about.phone }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="transaction.producerDetails.business">
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Farmer Company Name</small>
                                        <div v-if="transaction.producerDetails.business.name"><strong>{{ transaction.producerDetails.business.name }}</strong></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <div v-if="transaction.producerDetails.business.address">
                                            <small>Farmer Company Address</small><br />
                                            <strong class="d-block">{{transaction.producerDetails.business.address.address}}</strong>
                                            <strong class="d-block">{{transaction.producerDetails.business.address.city}}, {{transaction.producerDetails.business.address.state}} {{ transaction.producerDetails.business.address.zip}}</strong >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <b-button variant="success" @click="viewOrder">
                                View Order
                            </b-button>
                            <b-button variant="info" @click="viewProduct" class="ml-2">
                                View Product
                            </b-button>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
        <div v-if="transactionNotFound">
            <b-card>
                <p class="text-muted text-center">No order transaction Found.</p>
            </b-card>
        </div>
    </div>
</template>

<script>

import {db} from '@/firebase';
import numeral from 'numeral';
import Vue from "vue";
import {mapState} from 'vuex'

Vue.prototype.numeral = numeral;


export default {
    data() {
        return {
            loading: false,
            searchTransactionID: (this.$route.params.id) ? this.$route.params.id : '',
            transaction: null,
            loadingError: false,
            transactionNotFound: false,
            error:'',
        }
    },
    computed:{
        ...mapState(['user']),
    },
    mounted(){
        if(!this.user.role.admin){
            this.$router.replace("/not/access")
        }
        if(this.$route.params.id){
            this.searchTransaction();
        }
    },
    methods: {
        viewProduct(){
            this.$router.replace('/commodity/view/' + this.transaction.commodityID);
        },
        viewOrder(){
            this.$router.replace('/order/view/' + this.transaction.id);
        },  
        async searchTransaction(){
            let vm = this;
            vm.transaction = null;
            vm.loading = true;
            vm.transactionNotFound = false;
            if(vm.searchTransactionID == ""){
                vm.error = "Please enter the search order transaction id"
                vm.searchTransactionID = '';
                vm.loading = false;
                setTimeout(() => {
                    vm.error = '';
                }, 5000);
                return false;
            } else {
                let transaction = await db.collection('orders').doc(vm.searchTransactionID).get();
                if(transaction.exists) {
                    vm.transaction = {...transaction.data(),id:transaction.id};
                } else {
                    vm.transactionNotFound = true;
                    setTimeout(() => {
                        vm.transactionNotFound = false;
                    }, 10000);
                    vm.loading = false;
                    return false;
                }

                if(vm.transaction){
                    let buyerDetails = await db.collection('users').doc(vm.transaction.buyerID).get();
                    if(buyerDetails.exists) vm.transaction.buyerDetails = {...buyerDetails.data(),id:transaction.id};

                    let producerDetails = await db.collection('users').doc(vm.transaction.producerID).get();
                    if(producerDetails.exists) vm.transaction.producerDetails = {...producerDetails.data(),id:transaction.id};

                    let commodity = await db.collection('commodities').doc(vm.transaction.commodityID).get();
                    if(commodity.exists) vm.transaction.commodity = {...commodity.data(),id:commodity.id};

                    if(vm.transaction.commodity){
                        if(vm.transaction.commodity.unit != undefined){
                            let ref = await db.collection('units').doc(vm.transaction.commodity.unit).get();
                            if(ref.exists) vm.transaction.commodity = {...vm.transaction.commodity,unit:ref.data().name};
                        }

                        if(vm.transaction.commodity.type != undefined){
                            let ref2 = await db.collection('commodity_types').doc(vm.transaction.commodity.type).get();
                            if(ref2.exists) vm.transaction.commodity = {...vm.transaction.commodity,type:ref2.data().name};
                        }

                        if(vm.transaction.commodity.subtype != undefined){
                            let ref3 = await db.collection('commodity_subtypes').doc(vm.transaction.commodity.subtype).get();
                            if(ref3.exists) vm.transaction.commodity = {...vm.transaction.commodity,subtype:ref3.data().name};
                        }
                    }
                } 
                vm.loading = false;
            }
        }
    },
}
</script>
<style>
.card-title{
    margin-top: 0px;
}
</style>