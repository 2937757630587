<template>
    <div class="container rounded" style="max-width: 460px; "> 
        <div class="text-center p-4" style="background: #7ec313;">
            <img slot="header" src="/img/logo.png" class="img-fluid">
            <p class="h4 text-center text-white m-0 ">
                Seller & Buyer: Payments
            </p>
        </div>
        <div class="align-items-center">
           
            <b-card class="rounded-0">
                <div>
                    <b-form class="p-2">    
                        <div class="form-group ">
                            <label class="font-weight-bold text-dark">Card Number</label>
                            <div class="rounded-0 form-control" id="card-number"></div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-6 form-group">
                                <label class="font-weight-bold text-dark">Card Expiry</label>
                                <div class="rounded-0 form-control" id="card-expiry"></div>
                            </div>
                            <div class="col-xs-12 col-md-6 form-group">
                                <label class="font-weight-bold text-dark">Card CVC</label>
                                <div class="rounded-0 form-control" id="card-cvc"></div>
                            </div>
                        </div>
                        <ValidationProvider
                            name="name_of_card"
                            rules="required"
                            v-slot="{ passed, failed }"
                        >
                            <b-form-group
                                :invalid-feedback="'Name of card is required'"
                                :state="(failed?false:(passed?true:null))"
                                id="input-group-1"
                                label="Name on card:"
                                label-for="input-card" 
                                label-class="font-weight-bold text-dark"
                            >
                                <b-form-input
                                    id="input-card"
                                    class="rounded-0"
                                    type="text"
                                    v-model="name_of_card"
                                    placeholder="Enter Name on card"
                                    :state="(failed?false:(passed?true:null))"
                                >
                                </b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                       <div class="d-flex justify-content-between">
                            <b-button type="button" @click="onSubmit" variant="primary" class="rounded btn-block  font-weight-bold">Subscribe!</b-button>
                        </div>
                        </b-form>

                </div>
                
            </b-card>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase';

export default {
    computed: {
        stripeElements () {
            return this.$stripe.elements();
        },
    },
    data() {
        return {
            cardNumber: null,
            cardExpiry: null,
            name_of_card: null,
            errors: [],
        }
    },
    methods: {
        async onSubmit(){
            let vm = this;
            //create token for 
            this.$stripe.createToken(this.cardNumber, {
                name: this.name_of_card,
                email: this.email,
            }).then(function(result) {
                if(result.token){
                    vm.saveToken(result.token);
                }else if(result.error){
                    vm.errors.push(result.error);
                }
            });
        },
        async saveToken(token){
            let vm = this;
            vm.loading=true;
            let data = {
                stripeToken:token.id,
                type:token.type,
                card:{}
            };
            Object.keys(token.card).forEach((cardKey)=>{
                if(token.card[cardKey]) data.card[cardKey]=token.card[cardKey];
            });
            db.collection('user_accounts').add(data).then(()=>{
                this.$router.replace('/');
            }).catch((err)=>{
                vm.loading=false;
                vm.$notify({
                    title: err,
                    type: 'error',
                    position: 'bottom right'
                });
            });
        },
        loadScript (src) {
            return new Promise(function (resolve, reject) {
                if (document.querySelector('script[src="' + src + '"]')) {
                    resolve();
                    return;
                }
                const el = document.createElement('script')
                el.type = 'text/javascript'
                el.async = true
                el.src = src
                el.addEventListener('load', resolve)
                el.addEventListener('error', reject)
                el.addEventListener('abort', reject)
                document.head.appendChild(el)
            })
        },
        onStripeLoaded () {
            const style = {
                base: {
                    color: 'black',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                    color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            }
            this.cardNumber = this.stripeElements.create('cardNumber', { showIcon: true, placeholder: 'Card Number', style});
            this.cardNumber.mount('#card-number');
            this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
            this.cardExpiry.mount('#card-expiry');
            this.cardCvc = this.stripeElements.create('cardCvc', { style });
            this.cardCvc.mount('#card-cvc');
        },
        async load(){
            let vm = this;
            vm.loadScript('https://js.stripe.com/v3/').then(vm.onStripeLoaded).catch(vm.onScriptError);
            vm.loading = false;
        }

    }, 
    mounted() {
        this.loading = true;
        this.load();        
    }, 
    beforeDestroy(){
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
    }
}

</script>

<style scoped>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
.StripeElement--focus{
    border-color: #7ec313;
}
.checkoutHeader{
    width: 300px;
}

</style>
