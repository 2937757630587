import fgInput from "./components/Inputs/formGroupInput.vue";
import DropDown from "./components/Dropdown.vue";
import Card from "./components/Cards/Card.vue";
import Button from "./components/Button.vue";
import { BootstrapVue } from 'bootstrap-vue';

import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";

const GlobalComponents = {
  install(Vue) {
    Vue.use(BootstrapVue);
    Vue.component("fg-input", fgInput);
    Vue.component("drop-down", DropDown);
    Vue.component("card", Card);
    Vue.component("n-button", Button);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
  }
};

export default GlobalComponents;
