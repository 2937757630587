<template>
    <div class="container">
        <div class="row">
            <div class="col-12 pb-2">
                <div class="bg-white rounded pr-2 pr-md-4 pl-2 pl-md-4 pt-2 pb-2">
                    <div><small class="display-inline mr-2">Filters:</small></div>
                    <div class="d-block d-md-flex marketplace-filters col-6">
                        <Multiselect v-model="selectEntityType" placeholder="Entity Type" track-by="id" :options="options"  :searchable="false" :allow-empty="true" class="ml-0 ml-md-2"   />
                        <b-button @click="clearFilters" variant="primary" class="mt-2 mt-md-0 ml-0 ml-md-2">CLEAR</b-button>
                        <b-button @click="refresh" variant="primary" class="mt-2 mt-md-0 ml-0 ml-md-2">Refresh</b-button>

                    </div>
                </div>
            </div>
            <div class="col-12 p-2">
                <div v-if="loading" class="row mt-0 mt-md-4">
                    <div class="col d-none d-md-flex"></div>
                    <div class="col-12 col-md-8 col-xl-6 pt-4">
                        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                            <h4 v-if="loading" class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                        </card>
                    </div>
                    <div class="col d-none d-md-flex"></div>
                </div>
                <div v-else>
                    <b-card class="rounded border-2">
                        <b-card-header>
                            <h5 class="m-0">All My Logs</h5>
                        </b-card-header>
                        <b-card-body class="p-0">
                            <b-list-group>
                                <template v-if="logs.length != 0">
                                    <b-list-group-item class="d-block  text-md-left log-text">
                                        <div class="text-md-left pl-0 pr-0 pr-md-4 row">                                    
                                            <div class="col-3 d-flex flex-column">
                                                <label class="text-black">Request ID</label>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <label class="text-black">Entity Type</label>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <label class="text-black">Updated By</label>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <label class="text-black">Updated</label>
                                            </div>
                                        </div>
                                    </b-list-group-item>
                                    <b-list-group-item v-for="(log) in logs"   :key="log.id" class="d-block  text-md-left ">
                                        <div class="text-md-left pl-0 pr-0 pr-md-4 row">
                                            <div class="col-3 d-flex flex-column">
                                                <span>{{ log.RequestID }}</span>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <span class="text-capital">
                                                    {{ (log.entity == "commodity" ? "Product" : (log.entity === "ProductionContract") ? "Production Contract" : "Product Bid") }}
                                                </span>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <span>{{ log.user.about.first + " " + log.user.about.last }}</span>
                                            </div>
                                            <div class="col-3 d-flex flex-column">
                                                <span>{{ formatDate(log.Updated) }}</span>
                                            </div>
                                        </div>
                                    </b-list-group-item>
                                    <b-list-group-item v-if="allLogsLoaded"  @click="getLogs" class="text-center add-pointer" variant="success">
                                        <span class="fa fa-plus-circle mr-2"><strong class="ml-2">Show More</strong></span>
                                    </b-list-group-item>
                                </template>
                                <template v-else>
                                    <b-list-group-item>
                                        <p class="text-muted m-0">No Logs Found</p>
                                    </b-list-group-item>
                                </template>
                            </b-list-group>
                        </b-card-body>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { db } from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import {mapState} from 'vuex'
import moment from 'moment';
import { map, switchMap } from "rxjs/operators";
import { combineLatest, of } from "rxjs";
import Multiselect from "vue-multiselect";

Vue.prototype.moment = moment;

export default {
    name: 'LogsOfList',
    data(){
        return{
            logs: [],
            active: false,
            allMessagesLoaded: true,
            lastLogsDoc: null,
            allLogsLoaded: true,
            loading: true,
            selectEntityType: null,
            options: ['Product', 'Production Contract', 'Post Bit']
        }
    }, 
    components: {
        Multiselect,
    },
    watch:{
        selectEntityType(){
            const vm = this;
            vm.getLogs();
            vm.loading = true;
        }
    },
    computed: {
        ...mapState(['user']),
    },
    created(){
        const vm = this;
        if(!vm.user.role.admin){
            vm.$router.replace("/not/access")
        }
       
    },
    mounted(){
        const vm = this;
        vm.getLogs();
    },
    methods: {
        async getLogs(){

            let vm = this;
            let ref = await db.collection("store_logs")
            if(vm.selectEntityType){
                let entityType = (vm.selectEntityType === "Product") ? "commodity": (vm.selectEntityType === "Production Contract") ? "ProductionContract" : "ProductBidRequest"
              ref = ref.where('entity', '==', entityType)
            }
            ref = ref.limit(10).orderBy('Updated','desc');
            if (vm.lastLogsDoc) ref = ref.startAfter(vm.lastLogsDoc);
            
            collectionData(ref, 'id').pipe(
                switchMap ((logs) => {
                    if(logs.length == 0||logs.length < 10) vm.allLogsLoaded=false;
                    if(logs.length == 0) return of([]);
                    vm.lastLogsDoc = logs[logs.length - 1];
                    return combineLatest(...logs.map(m => {
                        if (m.id == undefined) {
                            m.id = null;
                        }
                        return docData(db.collection('users').doc(m.UpdatedBy)).pipe(
                            map(u => {
                                return { ...m, user: u }
                            })
                        )
                    }))
                })
            ).subscribe((logs) => {
                vm.logs = [];
                logs.forEach((log)=>{
                    vm.logs.push(log);
                })
                setTimeout(() => {
                    vm.loading = false;                
                }, 500);
            });
           
        }, 
        refresh(){
            const vm  = this;
            vm.loading = true;
            vm.getLogs();
        },
        clearFilters(){
            const vm  = this;
            vm.selectEntityType = null;
            vm.loading = true;
            vm.getLogs();
        },
        formatDate(d) {
            if (d) {
                d = d.toDate();
                if (moment().diff(d, 'minutes') < 1) return moment().diff(d, 'seconds') + ' secs ago';
                if (moment().diff(d, 'hours') < 1) return moment().diff(d, 'minutes') + ' mins ago';
                if (moment().diff(d, 'days') < 1) return moment().diff(d, 'hours') + ' hrs ago';
                return moment(d).format('ll');
            } else {
                return '';
            }
        },
    }
}
</script>

<style>
.text-capital{
    text-transform: capitalize;
}
.text-black { color: #000 !important;}
.log-text label { font-size: 18px;}
</style>