<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else-if="completeProductionContracts.length > 0" class="row">
            <div class="col-12">
                <b-card class="rounded border-2"> 
                    <b-card-header><h5 class="m-0">My Production Contracts</h5></b-card-header>
                    <b-card-body>
                        <b-list-group>
                            <b-list-group-item
                                :key="item.id"  v-for="item in completeProductionContracts" @click="viewDetails(item.id)" class="d-block d-md-flex align-items-center text-md-left"
                            >
                                <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                                    <h6 class="mt-2 m-md-0">
                                        {{item.productionContract.subtype}} {{item.productionContract.type}}
                                    </h6>
                                    <small class="d-block d-md-flex"><span>Delivery Window: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left">{{moment( item.productionContract.startDate.toDate()).format('ll')}} to {{moment(item.productionContract.endDate.toDate()).format('ll')}}</strong></small>
                                    <small class="d-block d-md-flex"><span>Number of Acres: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left"> {{ item.productionContract.accepted_full_fill_amount ? item.productionContract.accepted_full_fill_amount : item.productionContract.available_acres }}</strong></small>
                                    <small class="d-block d-md-flex"><span>Farmer Fulfilled Number of Acres: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left"> {{ item.fullFillAmount }}</strong></small>                                    
                                </div>
                                <div class=""><b-badge pill variant="info">{{item.entity}}</b-badge><small class="text-muted d-block mt-1"><nobr>{{formatDate(item.created)}}</nobr></small></div>
                            </b-list-group-item>
                            <b-list-group-item v-if="!allProductionContractLoaded" :disabled="productionContractLoadingMore" @click="loadCompleteProductionContract" button variant="success" class="text-center">
                                <span class="fa fa-spinner" v-if="productionContractLoadingMore"></span>
                                <span v-else class="fa fa-plus-circle mr-2"></span> 
                                <strong>Show More</strong>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <div v-else-if="completeProductionContracts.length == 0" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <template v-if="user.role.buyer">
                        <h4 class="card-title mt-0 mb-4">No production contract to accept </h4>
                        <p><a @click="showLearnMore=true" class="text-info">Learn more about creating great production contract</a></p>
                        <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                    </template>
                    <template v-else>
                        <h4 class="card-title mt-0 mb-4">No Production Contract requested</h4>
                        <p><a href="/production/contract" class="text-info">Browse our production contracts</a></p>
                        <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                    </template>
                </card>
                <b-modal size="lg" ref="learn-more-modal" v-model="showLearnMore" hide-footer title="Creating Great Product Listings">
                    <CreatingGreatListings />
                </b-modal>
            </div>


            <div class="col d-none d-md-flex"></div>
        </div>
    </div>
</template>


<script>
import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import {mapState} from 'vuex';
import {bus} from '@/vuex/bus.js';
import {db} from '@/firebase';
import { docData } from 'rxfire/firestore';
import { from, of, combineLatest } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';
import moment from 'moment';


export default{
    computed:{
        ...mapState(['user'])
    },
    components: {
        CreatingGreatListings
    },
    data() {
        return {
            completeProductionContracts: [],
            loading: true,
            showLearnMore: false,
            productionContractLoadingMore: false,
            allProductionContractLoaded: false,
            lastProductionContractDoc: null,
        };
    },
    async mounted(){
        let vm = this;
        vm.completeProductionContracts = [];
        await vm.loadCompleteProductionContract();
        bus.$on('backToMarketplace',()=>{
            bus.$emit('prepareTitle',null);
        });
        bus.$emit('prepareTitle',null);
    },
    methods:{
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        viewDetails(completeProductionContractID){
            this.$router.push('/production/contract/complete/list/'+completeProductionContractID)
        },
        async loadCompleteProductionContract(){
            let vm = this;
            if(!vm.lastProductionContractDoc) { 
                vm.loading=true;
            } else {
                vm.productionContractLoadingMore=true;
            } 
            let ref = db.collection('production_contracts_complete').where('is_restore', '==', null);
            ref = ref.limit(10).orderBy('created','desc');
            if(vm.lastProductionContractDoc) ref=ref.startAfter(vm.lastProductionContractDoc);
            from(ref.get()).pipe(
                switchMap(items=>{
                    if(items.empty||items.length<10) vm.allProductionContractLoaded, vm.loading = false;
                    if(items.empty) return of([]);
                    vm.lastProductionContractDoc=items.docs[items.docs.length-1];
                    return combineLatest(...items.docs.map(doc=>{
                        return docData(db.collection('production_contract').doc(doc.data().productionContractID)).pipe(
                            map(t=>{
                                if(JSON.stringify(t) !== '{}'){
                                    return {id:doc.id,...doc.data(),productionContract:{...t}}
                                } else {
                                    return {id:doc.id,...doc.data(),productionContract:{}}
                                }
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
                            map(t=>{
                                return {...c, productionContract: {...c.productionContract,type:t.name}}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(productionContracts=>{
                    if(productionContracts.length==0) return of([]);
                    return combineLatest(...productionContracts.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
                        map(t=>{
                            return {...c, productionContract: {...c.productionContract,subtype:t.name}}
                        })
                        )
                    }))
                })
            ).subscribe((items)=>{
                items.forEach((i)=>{
                    if(i.length == undefined){
                        if(vm.user.role.admin){
                            vm.completeProductionContracts.push(i)                            
                        } else if(i.productionContract.userID === vm.user.uid && i.buyerID === vm.user.uid){
                            vm.completeProductionContracts.push(i)                            
                        } else if(i.productionContract.userID !== vm.user.uid && i.producerID === vm.user.uid){
                            vm.completeProductionContracts.push(i)
                        }
                    }
                });
                vm.productionContractLoadingMore=false;
            });
            setTimeout(() => {
                vm.productionContractLoadingMore=false;
                vm.loading=false;
            }, 5000);
        }
    }
    
};

</script>