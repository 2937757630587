<template>
  <div>
    <div v-if="errors.length > 0">
        <b-alert show variant="danger">
            <small>{{ errors[0] }}</small>
        </b-alert>
    </div>
    <b-list-group>
        <b-list-group-item v-for="(item,index) in items" :key="item.id">
          <b-input-group prepend="Unit" class="mb-2">
            <b-form-input  v-on:change="saveItem(item,index)" v-model="item.name"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Minimum Quantity" class="mb-2">
            <b-form-input  v-on:change="saveItem(item,index)" v-model="item.minimum"></b-form-input>
          </b-input-group>
          <b-button block size="sm" variant="danger" @click="removeItem(index)"><span class="fa fa-times mr-2"></span> REMOVE</b-button>
        </b-list-group-item>
        <b-list-group-item>
          <b-input-group prepend="Unit" class="mb-2">
            <b-form-input v-model="newItem.name"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Minimum Quantity" class="mb-2">
            <b-form-input v-model="newItem.minimum" type="number"></b-form-input>
          </b-input-group>
          <b-button block size="sm" @click="addItem()" variant="success"><span class="fa fa-plus mr-2"></span> ADD</b-button>
        </b-list-group-item>
        <b-list-group-item>
          <b-button @click="save" :disabled="!hasChanges&&!saving" block variant="primary"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>SAVE</strong></b-button>
        </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import Vue from "vue";
import {db} from '@/firebase';
export default {
  name: 'Units',
  data(){
      return {
        items:[],
        removed:[],
        newItem:{
          name:'',
          minimum:0
        },
        hasChanges:null,
        saving:null,
        errors:[],

      }
  },
  computed:{
  },
  methods: {
    async save(){
      this.saving=true;
      var batch = db.batch();
      this.items.forEach((item)=>{
        let ref = db.collection('units').doc(item.id);
        batch.set(ref,item);
      });
      this.removed.forEach(item=>{
        let ref = db.collection('units').doc(item.id);
        batch.delete(ref);
      });
      await batch.commit()
      this.hasChanges=false;
      this.saving=false;
    },
    addItem(){
      let vm = this;
      if(this.newItem.name == "" && this.newItem.minimum == ""){
        vm.errors.push('Please enter validate name and minimum.')
          setTimeout(() => {
              vm.errors = [];
          }, 3000);
          return;
      }

      if(vm.checkDuplicateNameValidation() != 0){
          vm.errors.push('This unit name already exists in list.')
          setTimeout(() => {
              vm.errors = [];
          }, 3000);
          return;
      }
      let ref = db.collection('units').doc();
      let data = {id:ref.id,name:this.newItem.name,minimum:this.newItem.minimum};
      this.items.push(data);
      vm.newItem={name:'',minimum:0};
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    
    checkDuplicateNameValidation(){
        return this.items.filter((item) => {
            return (item.name.toLowerCase() === this.newItem.name.toLowerCase());
        });
    },

    saveItem(item,index){
      let vm = this;
      Vue.set(vm.items,index,item);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    removeItem(index){
      let vm = this;
      vm.removed.push(vm.items[index]);
      vm.items = vm.items.filter((item,i)=>i!=index);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    async loadItems(){
      this.items=[];
      let items = null;
      items = await db.collection('units').orderBy('name','asc').get();
      items.forEach(item=>this.items.push({...item.data(),id:item.id}));
    }
  },
  async mounted() {
      this.loadItems();
  }
}
</script>
<style>.input-group > .input-group-append > .btn {margin: 0;border-top-right-radius: 30px;border-bottom-right-radius: 30px;}</style>
