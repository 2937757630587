<template>
  <div class="d-block text-left">
    <p class="bg-light p-4 rounded">When creating a product listing on the NGF Connect application its in the best interest of the Farmer to provide useful information about their crop. Having this information readily to the Buyers could result in a sale over another listing.</p>
    <h5 class="text-center">May want to include on listing;</h5>
    <p class="bg-light p-4 rounded">
      <ol>
        <li>What is the product stored in? How large is the storage structure?</li>
        <li class="mt-2">When were chemicals/fertilizers applied?</li>
        <li class="mt-2">What kind of chemicals/fertilizers were applied?</li>
        <li class="mt-2">What variety is the product, and what is the strengths of that variety?</li>
        <li class="mt-2">Oil content for oilseeds</li>
        <li class="mt-2">How much rainfall did the crop receive while in the soil?</li>
        <li class="mt-2">Heavy metals test results</li>
        <li class="mt-2">Has the product been cleaned?</li>
        <li class="mt-2">Is the field the product originated from continuous cropped or strip farmed?</li>
      </ol>
    </p>
  </div>
</template>
<script>
export default {}
</script>
