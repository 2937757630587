<template>
  <div class="container">
    <div v-if="loading" class="row mt-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <h4 class="card-title mb-4">
            <b-spinner class="mr-2"></b-spinner> Loading...
          </h4>
        </card>
      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
    <div v-else-if="purchasing" class="row">
      <div class="d-block d-md-none col-12">
        <b-button @click="backTo" variant="primary"
          ><i class="fa fa-chevron-left mr-2"></i> Back</b-button
        >
      </div>
      <CommodityPurchase
        @backTo="backTo"
        :commodity="commodity"
        :productNegotiation="productNegotiation"
      />
    </div>
    <div v-else class="row">
      <div class="d-block d-md-none col-12">
        <b-button @click="backTo" variant="primary"
          ><i class="fa fa-chevron-left mr-2"></i> Back</b-button
        >
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <b-aspect class="w-100" :aspect="'16:9'">
              <CommodityImage
                v-if="selectedImage"
                v-bind:key="selectedImage.id + 'large'"
                :selectedFile="selectedImage"
                type="large"
                mode="view"
              />
            </b-aspect>
          </div>
        </div>
        <div class="row no-gutters mt-2">
          <div class="col-1 text-center pt-4">
            <a @click="$refs.thumbnailCarousel.prev()"
              ><i class="text-secondary fa fa-chevron-left"></i
            ></a>
          </div>
          <div class="col-10">
            <b-carousel
              class="mt-2 image-carousel"
              ref="thumbnailCarousel"
              :interval="0"
            >
              <b-carousel-slide
                v-for="(chunk, chunkIndex) in imageChunks"
                v-bind:key="chunkIndex"
              >
                <template #img>
                  <div class="row">
                    <CommodityImage
                      v-for="image in chunk"
                      :key="image.id"
                      @select="selectImage"
                      :selectedFile="image"
                      type="thumbnail"
                      mode="view"
                    ></CommodityImage>
                  </div>
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col-1 text-center pt-4">
            <a @click="$refs.thumbnailCarousel.next()"
              ><i class="text-secondary fa fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <h3 class="m-0">General</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 pt-2">
            <small>Total {{ commodity.unit }}</small
            ><br /><strong
              :style="
                availableTotal !== parseFloat(commodity.total)
                  ? 'text-decoration:line-through;'
                  : ''
              "
              >{{ numeral(commodity.total).format("0,000") }}</strong
            >
            <div v-if="availableTotal !== parseFloat(commodity.total)">
              {{ numeral(availableTotal).format("0,000") }}
              <small>remaining</small>
            </div>
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small> Price per {{ commodity.unit }}</small
            ><br /><strong
              >${{ numeral(commodity.price).format("0,000.00") }}</strong
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 pt-2">
            <small>Planted in</small><br /><strong>{{ commodity.year }}</strong>
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small>Expires by</small><br /><strong>{{
              moment(commodity.expires.toDate()).format("ll")
            }}</strong>
          </div>
        </div>

        <div v-if="commodity.location" class="row">
          <div class="col-12 col-md-6 pt-2">
            <small>Location</small><br /><strong>{{
              commodity.location
            }}</strong>
          </div>
        </div>

        <div v-if="commodity.producer && user.role.admin" class="row">
          <div class="col-12 pt-2"><hr /></div>
        </div>

        <div v-if="commodity.producer && user.role.admin" class="row">
          <div class="col-12">
            <h3 class="m-0">Farmer</h3>
          </div>

          <div class="col-12 col-md-6 pt-2">
            <small>Name</small><br />
            <strong>{{ producerFullName }}</strong>
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small>Company</small><br /><strong>{{ commodity.producer.business.name }}</strong>
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small>Address</small><br />
            <strong class="d-block">{{ commodity.producer.business.address.address }}</strong>
            <strong class="d-block"
              >{{ commodity.producer.business.address.city }},
              {{ commodity.producer.business.address.state }}
              {{ commodity.producer.business.address.zip }}</strong
            >
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small>Phone</small><br />
            <strong class="d-block">{{ commodity.producer.about.phone }}</strong>
          </div>
          <div class="col-12 col-md-6 pt-2">
            <small>Email</small><br />
            <strong class="d-block">{{ commodity.producer.about.email }}</strong>
          </div>
        </div>

        <div v-if="commodity.userID !== user.uid && !user.role.admin" class="row">
          <div class="col-12 pt-2"><hr /></div>
        </div>

        <div v-if="commodity.userID !== user.uid && !user.role.admin" class="row">
          <div v-if="commodity.active">
            <div class="col-12 pt-2" v-if="error">
              <b-alert show variant="danger">{{ error }}</b-alert>
            </div>
            <div class="col-12 pt-2 text-right">
              <b-button @click="proceedPurchase" variant="success" size="lg"
                ><strong
                  >Proceed with Negotiations<i
                    class="fa fa-arrow-right ml-2"
                  ></i></strong
              ></b-button>
            </div>
          </div>
          <div v-else>
            <div class="col-12 pt-2">
              <b-alert show variant="danger">
                This Product is currently unavailable and to check back later.
              </b-alert>
            </div>
          </div>
        </div>

        <div v-if="commodity.details" class="row mt-4">
          <div class="col-12">
            <div class="bg-white p-4 border-2 rounded">
              <h5 class="mt-0">Details</h5>
              <p class="mt-2" v-html="commodity.details"></p>
            </div>
          </div>
        </div>

        <div v-if="certifications.length > 0" class="row">
          <div class="col-12 pt-2"><hr /></div>
        </div>
        <div v-if="certifications.length > 0" class="row">
          <div class="col-12 pt-2"><h5>Certifications</h5></div>
        </div>
        <div v-if="certifications.length > 0" class="row">
          <div
            v-for="certification in certifications"
            class="col-12 col-md-6"
            :key="certification.id"
          >
            <span v-if="certification.url != undefined && certification.url != ''" >
              <a class="text-dark" @click="$router.push('/external-url/' + certification.url)" target="_blank" v-if="certification.file == undefined">
                {{ certification.certification}}<i class="fa fa-check-circle text-info ml-2"></i>
              </a>
              <a class="text-dark" :href="certification.url" target="_blank" v-else>
                  {{  certification.certification }}<i class="fa fa-check-circle text-info ml-2"></i>
              </a>
            </span>
            <span v-else>
              {{ certification.certification }}<i class="fa fa-check-circle text-info ml-2"></i>
            </span>
          </div>
        </div>

        <div class="d-block d-md-flex mt-4" v-if="user.uid === commodity.userID"> 
          <div class="flex-fill text-center text-md-left">
              <b-button variant="success" @click="backTo"><i class="fa fa-arrow-left mr-2"></i>Back</b-button>
          </div>
          <div class="flex-fill text-center text-md-right">
              <div>
                  <b-button variant="success" @click="editCommodity(commodity.id);">Edit</b-button>
              </div>
          </div>
        </div>

        <div v-if="commodity.userID !== user.uid && !user.role.admin" class="row mt-4">
          <div class="col-12">
            <div class="bg-white p-4 border-2 rounded">
              <h5 class="mt-0">Ask a Question</h5>
              <p>Have a question for this Farmer? Ask away!</p>
              <p class="mb-0">
                <b-button v-scroll-to="'#questions'" variant="info"
                  ><strong>Ask Question</strong></b-button
                >
              </p>
            </div>
          </div>
        </div>

        <div v-if="commodity.place" class="row">
          <div class="col-12 pt-2"><hr /></div>
        </div>
        <div v-if="commodity.place" class="row">
          <div class="col-12 pt-2"><h5>Location</h5></div>
        </div>

        <gmap-map
          v-if="commodity.place"
          :center="commodity.place.position"
          :zoom="12"
          class="mt-2"
          style="width:100%;height: 320px;"
        >
          <gmap-marker :position="commodity.place.position"></gmap-marker>
        </gmap-map>
      </div>
      <div class="col-12 pt-4">
        <div class="row">
          <div class="col-12 pt-2"><hr /></div>
        </div>
        <div class="row">
          <div class="col-12 pt-2"><h5>Questions</h5></div>
        </div>
        <CommodityQuestions
          v-if="((commodity.questions.length == 0 && user.role.admin)  || (commodity.userID !== user.uid && !user.role.admin))"
          id="questions"
          :currentUserID="user.uid"
          :commodityID="commodity.id"
          :isPriorMessageAvailableOrNot= "(user.role.admin && commodity.questions.length == 0)"
          @reloadQuestions="reloadQuestions"
          :showSenderCheckbox = "false"
        />
        <div v-else-if="commodity.questions" role="tablist">
          <CommodityQuestions
            id="questions"
            v-if="showQuestionModule && user.role.admin"
            :currentUserID="user.uid"
            :commodityID="commodity.id"
            :isPriorMessageAvailableOrNot= "true"
            @reloadQuestions="reloadQuestions"
            :showSenderCheckbox = "false"
          />
          <b-card
            no-body
            class="mb-1"
            v-for="question in getUniqueValue(commodity.questions)"
            :key="'cardQuestions' + question.id"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="clickQuestionMessage(question.id)" variant="info">
               {{ question.isFirstMessageAdmin ? "NGF Global " : "User ID: "}} {{ (question.isFirstMessageAdmin) ? '' : question.buyerID.substr(0, 10).toUpperCase()}} 
                  <b-badge v-if="question.unread" variant="warning" class="ml-2">
                    <strong>UNREAD</strong></b-badge>
                  </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse' + question.id"
              :visible="selectedBuyerQuestions == question.id"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <CommodityQuestions
                  v-if="selectedBuyerQuestions == question.id"
                  :id="'questions' + question.id"
                  :currentUserID="question.buyerID"
                  :commodityID="commodity.id"
                  :isPriorMessageAvailableOrNot= "false"
                  :showSenderCheckbox = "user.role.admin && !question.isFirstMessageAdmin"
                />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import numeral from "numeral";
import moment from "moment";
import { bus } from "@/vuex/bus.js";
import { mapState } from "vuex";
import { db } from "@/firebase";
import { firestorage } from "@/firebase";
import CommodityImage from "./components/Image.vue";
import CommodityQuestions from "./components/Questions.vue";
import CommodityPurchase from "./components/Purchase.vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);
Vue.prototype.numeral = numeral;
Vue.prototype.moment = moment;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
    libraries: "places",
  },
});
export default {
  components: {
    CommodityImage,
    CommodityQuestions,
    CommodityPurchase,
  },
  watch: {},
  computed: {
    ...mapState(["user"]),
    producerFullName: function (){
      const vm = this;
      return vm.commodity.producer.about.first+" "+vm.commodity.producer.about.last;
    },
    availableTotal() {
      return this.accepted_ammounts;
    },
    primaryPhotos() {
      return this.images.filter((i) => i.primary);
    },
    commodityPhotos() {
      return this.images.filter((i) => i.name);
    },
    emptyPhotos() {
      return this.images.filter((i) => !i.name);
    },
    imageChunks() {
      let vm = this;
      return [].concat.apply(
        [],
        vm.commodityPhotos.map(function(elem, i) {
          return i % 5 ? [] : [vm.commodityPhotos.slice(i, i + 5)];
        })
      );
    },
  },
  data() {
    return {
      loading: true,
      purchasing: false,
      commodity: null,
      commodityID: null,
      error: null,
      images: [],
      certifications: [],
      selectedImage: null,
      selectedBuyerQuestions: null,
      productNegotiation: null,
      accepted_ammounts: null,
      showQuestionModule: true,
    };
  },
  methods: {
    proceedPurchase() {
      if (this.availableTotal > 0 && this.commodity.active == true) {
        this.purchasing = true;
        bus.$emit("prepareTitle", { ...this.commodity, purchasing: true });
        window.scrollTo(0, 0);
      } else if (this.commodity.active == false) {
        this.error =
          "This product is currently unavailable and to check back later.";
      } else {
        this.error = "No quantity available to purchase.";
      }
      setTimeout(() => {
        this.error = "";
      }, 5000);
    },
    backTo() {
      this.purchasing = false;
      bus.$emit("backToTop");
    },
    getUniqueValue(questions){
      return [
        ...new Map(
          questions.map((item) => [item['id'], item])
        ).values()
      ];
    },
    editCommodity(commodityID){
        const vm = this;
        vm.$router.replace("/commodity/edit/" + commodityID)
    },

    reloadQuestions: function reloadQuestions(){ 
      const vm = this;
      vm.loading = true;
      vm.commodity = []
      vm.images = [],
      vm.certifications = [],
      vm.showQuestionModule = true;
      setTimeout(() => {
        vm.load();
      }, 3000);
    },
    selectImage(image) {
      let f = this.images.filter((i) => i.id == image.id);
      if (f.length > 0) this.selectedImage = f[0];
      else this.selectedImage = this.images[0];
    },
    async getCertification(c) {
      try {
        let url = "";
        if(c.file){
          url = await firestorage.ref(c.file.fullPath).getDownloadURL();
        }
        if(c.url){
          url = c.url
        }
        let ref = await db
          .collection("certifications")
          .doc(c.certification)
          .get();
        return { ...c, url: url, certification: ref.data().name };
      } catch (e) {
        return null;
      }
    },
    checkIfBuyerExist(items, buyerId){
       return items.filter((i) => {
          return i.buyerID.includes(buyerId);
        });
    },
    clickQuestionMessage(questionId) {
      if (this.selectedBuyerQuestions == questionId) {
        return (this.selectedBuyerQuestions = null);
      }
      return (this.selectedBuyerQuestions = questionId);
    },
    async getCommodityIdLoad(parametersID){
          let getCommodityRef = await db.collection("commodities").where('slug', '==', parametersID).get();
            if(!getCommodityRef.empty){
                  const data = getCommodityRef.docs.map((commodity) => {
                      return commodity.data().id;
                  });
                  return data[0];
            } else {
                  let getCommodityRef = await db.collection("commodities").where('id', '==', parametersID).get();
                  if(!getCommodityRef.empty){
                      const data = getCommodityRef.docs.map((commodity) => {
                          return commodity.data().id;
                      });
                      return data[0];
                  } else {
                      return null;
              }
            } 
    },

    async load(){
      const vm = this;
  
      let getCommodityID = await vm.getCommodityIdLoad(vm.$route.params.id);
      if (vm.$route.params.id) vm.commodityID = getCommodityID;

      bus.$on("backToMarketplace", () => {
        if (!vm.purchasing) {
          bus.$emit("prepareTitle", null);
        } else {
          vm.purchasing = false;
        }
      });

      let acceptAmountRef = await db
      .collection("accepted_amounts")
      .where("commodityID", "==", this.commodityID)
      .get();

      let acceptedAmount = acceptAmountRef.docs
        .filter((o) => !o.data().cancelled)
        .map((o) => {
          return { amount: o.data().amount };
        });

      let ref = await db
        .collection("commodities")
        .doc(this.commodityID)
        .get();

      if (ref.exists) vm.commodity = { ...ref.data() };
      let total = parseFloat(vm.commodity.total);

      acceptedAmount.forEach((o) => (total = total - parseFloat(o.amount)));

      vm.accepted_ammounts = total;

      if (vm.user.role.admin) {
        ref = await db
          .collection("commodity_location")
          .doc(this.commodityID)
          .get();
        if (ref.exists) vm.commodity.place = { ...ref.data() };
      }
      
      await vm.getAllQuestionsInCommodity();

      ref = await db.collection("commodity_categories").doc(vm.commodity.category).get();
      if (ref.exists) vm.commodity = { ...vm.commodity, category: ref.data().name };

      ref = await db.collection("units").doc(vm.commodity.unit).get();
      if (ref.exists) {
        vm.commodity = {...vm.commodity, unit: ref.data().name, minimum: ref.data().minimum ? ref.data().minimum : 0};
        vm.commodity.availableTotal = vm.availableTotal;
      }

      ref = await db.collection("commodity_types").doc(vm.commodity.type).get();
      if (ref.exists) vm.commodity = { ...vm.commodity, type: ref.data().name };

      ref = await db.collection("commodity_subtypes").doc(vm.commodity.subtype).get();
      if (ref.exists) vm.commodity = { ...vm.commodity, subtype: ref.data().name };

      // ref = await db.collection('price_options').doc(vm.commodity.priceOption).get();
      // if (ref.exists) vm.commodity = {...vm.commodity, priceOption: ref.data().name}

      if (vm.user.role.admin) {
        ref = await db.collection("users").doc(vm.commodity.userID).get();
        if (ref.exists) vm.commodity.producer = { id: vm.commodity.userID, ...ref.data() };
      } else {
        vm.commodity.producer = { id: vm.commodity.userID };
      }

      bus.$emit("prepareTitle", vm.commodity);

      vm.images = vm.commodity.images.filter((i) => i.fullURL);

      if (vm.primaryPhotos.length > 0) {
        vm.selectedImage = vm.primaryPhotos[0];
      } else {
        vm.selectedImage = {
          id: "none",
          largeURL: "/img/NGF_NO_IMAGE_@2x.png",
          fullURL: "/img/NGF_NO_IMAGE_@2x.png",
          name: "none",
        };
      }

      let promises = [];

      if (vm.commodity.certifications) {
        vm.commodity.certifications.map((c) =>
          promises.push(vm.getCertification(c))
        );
      }
      let certifications = await Promise.all(promises);
      certifications.filter((c) => c).map((c) => vm.certifications.push(c));
      if (vm.$route.params.productNegotiation != undefined) {
        let productNegotiationId = vm.$route.params.productNegotiation;
        let product = await db
          .collection("products_negotiation")
          .doc(productNegotiationId)
          .get();
        if (product.exists)
          vm.productNegotiation = { ...product.data(), id: product.id };
        vm.proceedPurchase();
      }
      vm.loading = false;

    },

    async getAllQuestionsInCommodity(){
      const vm = this;
      vm.commodity.questions = [];
      let ref = db.collection("inbox");
      ref = ref.where("entity", "==", "commodity");
      ref = ref.where("commodityID", "==", vm.commodityID);
      ref = ref.where("to", "==", vm.user.role.admin ? vm.commodity.userID : vm.user.uid);
      ref = ref.where("is_restore", "==", null);
      let docs = await ref.get();
      if (!docs.empty)
        vm.commodity.questions = docs.docs.map((d) => {
          return { ...d.data(), id: d.id };
        });
      else vm.commodity.questions = [];

      var items = [];
      var lastBuyerId = "";
      vm.commodity.questions.forEach((item) => {
          let checkIfBuyer = vm.checkIfBuyerExist(items, item.buyerID);
          if(lastBuyerId !== item.buyerID && checkIfBuyer.length == 0 && (item.isProductCreateByFarmer != true || item.isProductCreateByFarmer == undefined)){
            items.push(item);
          }
          lastBuyerId = item.buyerID;
          if(item.buyerID == vm.user.uid && vm.user.role.admin){
            vm.showQuestionModule = false;
          }
      });
      vm.commodity.questions = items;
    }
  },
  async mounted() {
    const vm =this;
    vm.load();
  },
};
</script>
<style>
.image-carousel .col-thumbnail {
  flex: 0 0 20%;
  max-width: 20%;
  padding-bottom: 1em !important;
}
@media (max-width: 1200px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 25%;
    max-width: 25%;
    padding-bottom: 1em !important;
  }
}
@media (max-width: 992px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;
    max-width: 33%;
    padding-bottom: 1em !important;
  }
}
@media (max-width: 768px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;
    max-width: 33%;
    padding-bottom: 1em !important;
  }
}
@media (max-width: 576px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 25%;
    max-width: 25%;
    padding-bottom: 1em !important;
  }
}
@media (max-width: 400px) {
  .image-carousel .col-thumbnail {
    flex: 0 0 33%;
    max-width: 33%;
    padding-bottom: 1em !important;
  }
}
</style>
