<template>
  <ValidationObserver ref="form" class="col">
    <form @submit.prevent="validate">
      <div>
        <div v-if="loading" class="row mt-4">
          <div class="col d-none d-md-flex"></div>
          <div class="col-12 col-md-8 col-xl-6 pt-4">
            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
              <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
              <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
            </card>
          </div>
          <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else-if="processing" class="row mt-4">
          <div class="col d-none d-md-flex"></div>
          <div class="col-12 col-md-8 col-xl-6 pt-4">
            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
              <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
              <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Processing...</h4>
            </card>
          </div>
          <div class="col d-none d-md-flex"></div>
        </div>
        <!-- <div v-else-if="confirming" class="row mt-4">
          <div class="col-12">
            <OrderHeader :order="{...order,commodity:{...commodity}}" />
            <div class="row mt-4">
              <div class="col-6 text-right"><b-button @click="confirming=false" :disabled="saving" variant="secondary">Back</b-button></div>
              <div class="col-6 text-left"><b-button @click="confirm" :disabled="saving" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i>Confirm Order</b-button></div>
            </div>
          </div>
        </div> -->
        <div v-else-if="confirmed" class="row mt-4">
          <div class="col d-none d-md-flex"></div>
          <div class="col-12 col-md-8 col-xl-6 pt-4">

            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
              <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
              <h4 class="card-title mt-0 mb-4">Your order has been confirmed!</h4>
              <p><a href="/marketplace" class="text-info">Back to marketplace</a></p>
              <p><a href="/" class="text-info">Go to your Dashboard to receive order updates</a></p>
            </card>

          </div>
          <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row">
          <div class="col-12 col-md-5">
            <div class="row">
              <div class="col-12">
                <div class="border-2 bg-white rounded p-4 text-center d-flex justify-content-center">
                  <h4 class="text-muted text-right m-0 mr-1 pt-2">total:</h4>
                  <h2 class="text-left m-0 ml-1">${{numeral(total).format('0,000.00')}}</h2>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="border-2 rounded p-4">
                  <h5 class="text-left m-0">Choose Currency &amp; Quantity</h5>
                  <p>Start by selecting the amount of {{commodity.type}} you would like to purchase. If you prefer a different currency or want to compare pricing, use the currency selector.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-md-flex col-md-1"></div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 pt-2">
                <ValidationProvider
                  name="product_negotiation.currency"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <b-form-group
                    label="Currency"
                    :invalid-feedback="'Currency is required'"
                    :state="(failed?false:(passed?true:null))"
                  >
                    <b-form-select v-model="product_negotiation.currency" :options="optionCurrencies" :state="(failed?false:(passed?true:null))"></b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-2">
                <ValidationProvider
                  name="product_negotiation.amount"
                  :rules="{required:true,decimal:true,available:{total:commodity.availableTotal},minimum:{minimum:commodity.minimum}}"
                  v-slot="{ passed, failed, errors }"
                >

                  <b-form-group
                    :state="(failed?false:(passed?true:null))"
                  >
                    <div>
                      <legend class="col-form-label">How much would you like to purchase?<i class="d-block d-md-inline"><small>{{numeral(commodity.availableTotal).format('0,000')}} {{commodity.unit}} available</small></i></legend>
                      <b-input-group>
                        <b-form-input placeholder="Enter amount" v-model="product_negotiation.amount" :state="(failed?false:(passed?true:null))"></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text>{{commodity.unit}}</b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div slot="description" class="d-block d-md-none text-center">
                       <strong><em>Pricing per {{commodity.unit}}</em></strong>
                    </div>
                    <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                      {{errors[0]=='Minimum'?'The amount entered must be at least ' + numeral(commodity.minimum).format((commodity.unit !== "LBS") ? '0.00000' : '' ) + commodity.unit + ' '+ ((selectedUnit !== 'LBS') ? '(as converted 1 lbs)' : '' )  :errors[0]=='Exceeds'?'The amount entered exceeds the amount available.':'Please enter a valid number.'}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-2">
                <ValidationProvider
                  name="product_negotiation.offer_price"
                  :rules="{required:true,decimal:true,offerPrice_minimum:{minimum:0}}"
                  v-slot="{ passed, failed, errors }"
                >
                <b-form-group :state="(failed?false:(passed?true:null))">
                  <legend class="col-form-label">Offer Price per<i class="d-block d-md-inline"><small>{{commodity.unit}}</small></i></legend>
                  <b-input-group>
                    <b-form-input placeholder="Enter offer price" v-model="product_negotiation.offer_price" :state="(failed?false:(passed?true:null))"></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text>$</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                    {{errors[0]=='OfferPriceMinimum'?'The minimum offer price must be $'+ numeral(0).format('0,000') : 'Please enter a valid offer price.'}}
                  </b-form-invalid-feedback>
                </b-form-group>  
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-2">
                  <ValidationProvider
                      name="product_negotiation.paymentOption"
                      :rules="{required:true}"
                      v-slot="{ passed, failed }"
                  >
                  <b-form-group
                    :state="(failed?false:(passed?true:null))"
                  >
                    <legend class="col-form-label">Payment Options</legend>
                    <b-input-group>
                      <b-form-radio-group v-model="selectedPaymentOption">
                        <b-form-radio value="100_at_purchase">100% at Purchase</b-form-radio>
                        <b-form-radio value="50_at_purchase">Buyer and Seller Negotiated Payment Split</b-form-radio>
                        <b-form-radio value="100_at_delivery">100% at Delivery</b-form-radio>
                      </b-form-radio-group>
                    </b-input-group>
                  </b-form-group>
                  <div class="text-start mt-0">
                    <b-card>
                      <b-card-header>
                          <small class="text-muted mb-0">Payment Option Details</small>
                      </b-card-header>
                      <hr />
                      <b-card-body class="mt-0">
                        <div class="mt-0">
                            <small>100% at time of purchase</small><br />
                            <small>Buyer and Seller Negotiated Payment Split</small><br />
                            <small>100% at time of delivery</small><br />
                        </div>
                      </b-card-body>
                    </b-card>
                  </div>
                  </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-12 "></div>
            </div>
            <!-- <div class="row">
              <div class="col-12 pt-2"><h5>What to Expect</h5></div>
            </div>
            <div class="row">
              <div class="col-12 pt-2">
                <div class="bg-secondary p-4 text-white">Upon finalizing your order, an NGF Global representative will be in communication with you regarding payment and logistics concerning the delivery of your order. If terms can not be met within 24 hours your order may be cancelled and the product will be available for others to purchase.</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pt-2"><hr /></div>
            </div> -->
            <!-- <div class="row mt-2">
              <div class="col-12"><div class="alert alert-primary"><i class="fa fa-balance-scale mr-2"></i>By proceeding, you acknowledge reading and agreeing to NGF-Global’s policies <a href="https://www.ngf-global.com/policy" target="_blank" class="text-white font-weight-bold"><u>https://www.ngf-global.com/policy</u></a></div></div>
            </div> -->
            <div v-if="error" class="row mt-4">
              <div class="col-12"><div class="alert alert-danger">One or more required fields are missing.</div></div>
            </div>
            <div class="row">
              <div class="col-12 text-right"><b-button @click="validate" variant="success" :disabled="saving" size="lg"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i>Move to Negotiations<i class="fa fa-arrow-right ml-2"></i></strong></b-button></div>
              <!-- <div class="col-12 text-right"><b-button @click="myProductsNegotiation" variant="success" :disabled="saving" size="lg"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i><i class="fa fa-arrow-left mr-2"></i>Back to My Negotiations</strong></b-button></div> -->
            </div>
            <div class="row"></div>
          </div>
        </div>
      </div>
    </form>
    <div>
      <form @submit.prevent="confirmNegotiation">
        <b-modal ref="model" size="lg" 
          title="Confirmation of Negotiation" centered  v-model="confirmationPopup" 
          @show="resetConfirmationModel" 
          @hide="resetConfirmationModel"
        >
        <div v-if="confirmNegotiationLoading" class="row mt-4">
          <div class="col d-none d-md-flex"></div>
          <div class="col-12 col-md-8 col-xl-6 pt-4">
            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
              <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
            </card>
          </div>
          <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
          <p>
            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Seller, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>, for the purchase of the product listed.
          </p>
          <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
          <ValidationProvider
              name="confirmationPassword"
              rules="required|min:6"
              v-slot="{ passed, failed, errors }"
            >
              <b-form-group
                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                :state="failed ? false : passed ? true : null"
              >
                <b-form-input
                  type="password"
                  placeholder="Please Enter Password"
                  v-model="confirmationPassword"
                  :class="confirmationPopupError ? 'is-invalid' : ''"
                  :state="failed ? false : passed ? true : null"
                />
              </b-form-group>
            </ValidationProvider>
        </div>
            <template #modal-footer="{ok,cancel}">
              <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                Cancel
              </b-button>
              <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmNegotiation">
                Continue
              </b-button>
            </template>
        </b-modal>    
      </form>
    </div>
  </ValidationObserver>
</template>
<script>
import moment from 'moment';
import firebase from 'firebase';
import {db} from '@/firebase';
import Vue from "vue";
import numeral from 'numeral';
import {mapState} from 'vuex'
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {auth} from '@/firebase';
// import OrderHeader from "@/views/Dashboard/Order/components/Header.vue";
Vue.prototype.numeral = numeral;
Vue.prototype.moment = moment;
extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));
extend("available", {
  params: ["total"],
  validate: (amount, {total})=>{
    return (parseFloat(amount)<=total);
  },
  message:'Exceeds'
});
extend("minimum", {
  params: ["minimum"],
  validate: (amount, {minimum})=>{
    return (parseFloat(amount)>=minimum);
  },
  message:'Minimum'
});
extend("offerPrice_minimum",{
  params: ["minimum"],
  validate:(total, {minimum})=>{
    return (parseFloat(total)>=minimum)
  },
  message:'OfferPriceMinimum'
});
export default {
  props: ['commodity', 'productNegotiation'],
  // components: {
  //   OrderHeader
  // },
  watch:{},
  computed:{
    ...mapState(['user']),
    total(){
      return !isNaN(this.product_negotiation.amount)?parseFloat(this.product_negotiation.amount*this.product_negotiation.offer_price):0;
    }
  },
  data() {
    return {
      loading:true,
      processing:false,
      confirming:false,
      confirmed:false,
      saving:false,
      error:false,
      confirmationPopup: false,
      confirmationPopupError: null,
      confirmationPassword: null,
      confirmNegotiationLoading: false,
      product_negotiation:{
        amount: 0,
        offer_price:0,
        currency:'USD',
        services:[]
      },
      optionCurrencies:[{
        text:'USD',
        value:'USD'
      }],
      selectedPaymentOption: '100_at_purchase',
    };
  },
  methods: {
    async confirm() {
      let vm = this;
      vm.saving=true;
      let data = {...vm.order};
      delete data.id;
      // await db.collection('orders').doc(orderID).set(data);
      vm.confirming=false;
      vm.confirmed=true;
      vm.saving=false;
    },
    async addPaymentNegotiationRequestInbox(item){
      let ref = await db.collection('inbox').doc();
      let inboxID = ref.id;
      let data = {
          buyerID: item.buyerID,
          commodityID: item.commodityID,
          product_negotiation_id: (item.id == undefined) ? this.productNegotiation.id : item.id ,
          entity: 'product_negotiation',
          from: this.user.uid,
          type: 'update',
          title: 'REQUEST',
          text: 'A User has requested a payment negotiation',
          to: item.producerID,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          unread: true,
          is_restore: null
      }
      await db.collection('inbox').doc(inboxID).set(data);
    },
    resetConfirmationModel(){
      this.confirmationPopupError = null;
      this.confirmationPassword = null;
    },
    async confirmNegotiation(){
      var vm = this;
      let res = await vm.$refs.form.validate();
      if(res){
        vm.confirmNegotiationLoading = true;
        await auth.signInWithEmailAndPassword(this.user.about.email, this.confirmationPassword).then(async() => {
          let ref = db.collection('products_negotiation').doc();
          let negotiationID =  ref.id;
          vm.product_negotiation.id=negotiationID;
          vm.product_negotiation.buyerID=vm.user.uid;
          vm.product_negotiation.commodityID=vm.commodity.id;
          vm.product_negotiation.producerID=vm.commodity.userID;
          vm.product_negotiation.total=numeral(vm.total).format('0,000.00');
          vm.product_negotiation.services=[];
          vm.product_negotiation.updated = firebase.firestore.FieldValue.serverTimestamp();
          vm.product_negotiation.created = firebase.firestore.FieldValue.serverTimestamp();
          vm.product_negotiation.paymentOption = vm.selectedPaymentOption;
          vm.product_negotiation.is_hide_paymentOption = 0;
          vm.product_negotiation.is_buyer_verified=true;
          vm.product_negotiation.is_restore = null;
          let data = {...vm.product_negotiation};
          await db.collection('products_negotiation').doc(negotiationID).set(data).then(async() => {
            await vm.addPaymentNegotiationRequestInbox(data);
            setTimeout(() => {
              vm.$router.replace('/');
            }, 3000);
          });
        }).catch(() => {
          vm.confirmationPopupError = "This password is invalid."
          vm.confirmNegotiationLoading = false;
          setTimeout(() => {
            vm.confirmationPopupError = null;
          }, 2000);
        });
      }
    },
    async validate() {
      const vm = this;
      vm.error=false;
      let res = await this.$refs.form.validate();
      if(res){
        if(vm.productNegotiation == null){
            vm.confirmationPopup = true;
        } else {
            vm.saving = true;
            let ref = await db.collection('products_negotiation').doc(vm.productNegotiation.id);
            let data = {
                buyerID: vm.user.uid,
                commodityID: vm.commodity.id,
                producerID: vm.commodity.userID,
                total: numeral(vm.product_negotiation.amount * vm.product_negotiation.offer_price).format('0,000.00'), 
                amount: vm.product_negotiation.amount, 
                offer_price: vm.product_negotiation.offer_price,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                paymentOption: vm.selectedPaymentOption,
                is_product_negotiation_status: firebase.firestore.FieldValue.delete(), 
                is_hide_paymentOption: 0,
                services: [],
            }
            ref.update(data).then(async() => {
              await vm.addPaymentNegotiationRequestInbox(data);
              setTimeout(() => {
                vm.$router.replace('/');
              }, 3000);
            });
        }
      }else{
        vm.error=true;
        vm.saving=false;
      }
    },
    async myProductsNegotiation(){
      this.$router.replace('/products/negotiation/');
    }
  },

  mounted(){
    this.loading=false;
    if(this.productNegotiation != undefined && this.product_negotiation){
      this.product_negotiation.amount = parseFloat(this.productNegotiation.amount);
      this.product_negotiation.offer_price =  this.productNegotiation.offer_price;
      this.selectedPaymentOption = this.productNegotiation.paymentOption;
    } else {
      this.product_negotiation.offer_price =  this.commodity.price
    }
  }
};
</script>
<style>
.input-group > .input-group-append > .input-group-text {border-radius:30px;border-top-left-radius: 0;border-bottom-left-radius: 0;}
.input-group > .input-group-prepend > .input-group-text {border-radius:30px;border-top-right-radius: 0;border-bottom-right-radius: 0;}
</style>
