<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="pl-4 pr-4 pt-0 pt-md-4">
        <h3 class="d-block d-md-none">Account</h3>
        <div class="row">
          <div class="d-none d-md-flex col"></div>
          <div class="col-12 col-md-8 col-lg-6 pb-2">
            <ValidationProvider
              name="signup.account.password"
              rules="required|min:6"
              v-slot="{ passed, failed, errors }"
            >
              <b-form-group
                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                :state="failed ? false : passed ? true : null"
              >
                <b-form-input
                  type="password"
                  placeholder="Password"
                  v-model="signup.account.password"
                  :state="failed ? false : passed ? true : null"
                />
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="d-none d-md-flex col"></div>
        </div>
        <div class="row">
          <div class="d-none d-md-flex col"></div>
          <div class="col-12 col-md-8 col-lg-6 pb-2">
            <ValidationProvider
              name="signup.account.confirm_password"
              :rules="{
                required: true,
                matches: { password: signup.account.password },
              }"
              v-slot="{ passed, failed, errors }"
            >
              <b-form-group
                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                :state="failed ? false : passed ? true : null"
              >
                <b-form-input
                  type="password"
                  placeholder="Confirm password"
                  v-model="signup.account.confirm_password"
                  :state="failed ? false : passed ? true : null"
                />
              </b-form-group>
            </ValidationProvider>
            <div class="row">
              <div v-if="currentRouteName" class="d-flex col">
                <div class="col-12 col-md-12 pb-2">
                    <InputsCheckbox
                    :inline="true"
                    :v-model="checked"
                    @input="input"
                    >
                  </InputsCheckbox>
                  <a
                  style="position: relative;top:20px;"
                  href="https://www.ngf-global.com/copy-of-user-agreements"
                  >Agree to Terms & Conditions</a
                  >
                  <!-- <span class="help is-danger" v-show="errors.has('terms')">{{ errors.first('terms') }}</span> -->
                  <!-- <input type="checkbox" id="checkbox" v-model="checked" /> -->
                  <!-- <label for="checkbox"><a  href="https://www.ngf-global.com/copy-of-user-agreements" class="pl-2">Agree to Terms & Conditions</a> </label> -->
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-md-flex col"></div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import InputsCheckbox from "../../../components/Inputs/Checkbox.vue";

extend("required", {
  ...required,
  message: "Please enter a valid password",
});
extend("min", {
  ...min,
  message: "Password must be at least 6 characters",
});
extend("matches", {
  params: ["password"],
  validate: (confirm_password, { password }) => {
    return password == confirm_password;
  },
  message: "Passwords do not match.",
});

export default {
  components: {
    InputsCheckbox,
  },
  data() {
    return {
      checked: null,
      profile: {
        termsAccepted: true,
      },
      signup: {
        account: {
          password: null,
          confirm_password: null,
        },
      },
    };
  },
  computed:{
    currentRouteName() {
        if(this.$router.currentRoute.name === "Profile"){
          
          return false;
        }
        return true;
    }
  },
  methods: {
    input(c){
      this.checked = c;
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        if(this.$router.currentRoute.name !== "Profile"){
          if (!this.checked){
            alert("You must accept terms & agreements to continue.")
            return;
          }else
          if (res && this.checked) this.$emit("on-validated", this.signup);
          return res;
        }else{
          if (res) this.$emit("on-validated", this.signup);
          return res;
        }
      });
    },
  },
  async mounted(){
   
  

  }
};
</script>
<style>
.form-check .form-check-sign::before, .form-check .form-check-sign::after{
  border: 1px solid #9A9A9A;
}
.btn-outline-primary:hover{
  background-color: #7ec313;
  color: #fff;
  border-color: #7ec313;
}
</style>
