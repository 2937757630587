<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row">
            <div class="d-block d-md-none col-12"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div>
            <div class="col-12">
                <CompleteHeader :completeProductionContract = "completeProductionContract" />
            </div>
            <div class="col-12">
                <Timeline :completeProductionContract = "completeProductionContract" />
            </div>
        </div>
    </div>
</template>

<script>

import {bus} from '@/vuex/bus.js';
import {mapState} from 'vuex'
import CompleteHeader from "./components/CompleteProductionContractHeader.vue";
import Timeline from './components/Timeline.vue';
import {db} from '@/firebase';

export default {
    data() {
        return {
            loading:true,
            completeProductionContract:null
        };
    },
    components:{
        CompleteHeader,
        Timeline
    },
    computed:{
        ...mapState(['user']),
    },
    methods:{
        backTo(){
            bus.$emit('backToTop');
        }
    },
    async mounted(){
        let vm = this;
        if(vm.$route.params.id) vm.completeProductionContractID = vm.$route.params.id;

        let completeProductionContract = await db.collection('production_contracts_complete').doc(vm.completeProductionContractID).get();
        if(completeProductionContract.exists) vm.completeProductionContract = {...completeProductionContract.data(),id:completeProductionContract.id};

        let ProductionContract = await db.collection('production_contract').doc(vm.completeProductionContract.productionContractID).get();
        if(ProductionContract.exists) vm.completeProductionContract.productionContract = {...ProductionContract.data(),id:ProductionContract.id};

        let ref = await db.collection('commodity_categories').doc(vm.completeProductionContract.productionContract.category).get();
        if(ref.exists) vm.completeProductionContract.productionContract = {...vm.completeProductionContract.productionContract,category:ref.data().name};


        ref = await db.collection('price_options').doc(vm.completeProductionContract.productionContract.priceOption).get();
        if (ref.exists) vm.completeProductionContract.productionContract = {...vm.completeProductionContract.productionContract, priceOption: ref.data().name}

        ref = await db.collection('units').doc(vm.completeProductionContract.productionContract.unit).get();
        if(ref.exists) vm.completeProductionContract.productionContract = {...vm.completeProductionContract.productionContract,unit:ref.data().name};

        ref = await db.collection('commodity_types').doc(vm.completeProductionContract.productionContract.type).get();
        if(ref.exists) vm.completeProductionContract.productionContract = {...vm.completeProductionContract.productionContract,type:ref.data().name};

        ref = await db.collection('commodity_subtypes').doc(vm.completeProductionContract.productionContract.subtype).get();
        if(ref.exists) vm.completeProductionContract.productionContract = {...vm.completeProductionContract.productionContract,subtype:ref.data().name};

        
        if(vm.user.role.admin){
            let productionContractLocation = await db.collection('production_contract_location').doc(vm.completeProductionContract.commodityID).get();
            if(productionContractLocation.exists) vm.completeProductionContract.productionContract.location = {...productionContractLocation.data()};

            ref = await db.collection('users').doc(vm.completeProductionContract.producerID).get();
            if(ref.exists) vm.completeProductionContract.producer = {...ref.data()};

            ref = await db.collection('users').doc(vm.completeProductionContract.buyerID).get();
            if(ref.exists) vm.completeProductionContract.buyer = {...ref.data()};
        }

        vm.completeProductionContract.created = vm.completeProductionContract.created.toDate();

        bus.$emit('prepareTitle', {...vm.completeProductionContract, isCompleteProductionContract:true});

        vm.loading = false
    },
    destroyed() {
        bus.$emit('prepareTitle',{isCompleteProductionContract:false});
    }
}

</script>