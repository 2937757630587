<template>
  <div class="d-flex w-100">
    <span class="pt-2"><input :disabled="true" type="radio" :checked="false" /></span>
    <div class="w-100 mt-2 ml-4 mr-4" ref="card"></div>
    <b-button :disabled="saving" size="sm" variant="success" @click="saveCreditCard"><span class="fa fa-spinner" v-if="saving"></span><span v-else class="fa fa-save"></span></b-button>
    <b-button :disabled="saving" size="sm" variant="danger" @click="cancel" class="ml-2"><span class="fa fa-times"></span></b-button>
  </div>
</template>
<script>
export default {
  name: 'CreditCard',
  props:[
    'stripe',
    'elements'
  ],
  data(){
      return {
        card:null,
        saving:false
      }
  },
  beforeDestroy(){
    this.card.unmount();
    this.card.destroy();
  },
  mounted(){
    this.card = this.elements.create('card',{
      style: {
        base: {
          fontSize: '16px',
          color: "#404b55",
        }
      }
    });
    this.card.mount(this.$refs.card);
  },
  methods:{
    cancel(){
      this.card.unmount();
      this.card.destroy();
      this.$emit('cancel');
    },
    saveCreditCard(){
      let vm = this;
      vm.saving=true;
      vm.stripe.createToken(this.card).then(function(result) {
        vm.saving=false;
        if(result.token){
          vm.$emit('save',result.token);
          vm.cancel();
        }else if(result.error){
          vm.$notify({
            title: result.error.message,
            type: 'error',
            position: 'bottom right'
          });
        }
      });
    }
  }
}
</script>
