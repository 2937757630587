<template>
  <b-list-group>
    <b-list-group-item v-for="(item,index) in items" :key="item.id">
      <b-input-group prepend="Name" class="mb-2">
        <b-form-input  v-on:change="saveItem(item,index)" v-model="item.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Description" class="mb-2">
        <b-form-input  v-on:change="saveItem(item,index)" v-model="item.description"></b-form-input>
      </b-input-group>
      <b-button block size="sm" variant="danger" @click="removeItem(index)"><span class="fa fa-times mr-2"></span> REMOVE</b-button>
    </b-list-group-item>
    <b-list-group-item>
      <b-input-group prepend="Name" class="mb-2">
        <b-form-input v-model="newItem.name"></b-form-input>
      </b-input-group>
      <b-input-group prepend="Description" class="mb-2">
        <b-form-input v-model="newItem.description"></b-form-input>
      </b-input-group>
      <b-button block size="sm" @click="addItem()" variant="success"><span class="fa fa-plus mr-2"></span> ADD</b-button>
    </b-list-group-item>
    <b-list-group-item>
      <b-button @click="save" :disabled="!hasChanges&&!saving" block variant="primary"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>SAVE</strong></b-button>
    </b-list-group-item>
</b-list-group>
</template>
<script>
import Vue from "vue";
import {db} from '@/firebase';
export default {
  name: 'Descriptors',
  props: [
    'table'
  ],
  components: {
  },
  data(){
      return {
        items:[],
        removed:[],
        newItem:{
          name:'',
          description:''
        },
        hasChanges:null,
        saving:null
      }
  },
  computed:{
  },
  watch:{
    category(){
      this.loadItems();
    },
    type(){
      this.loadItems();
    }
  },
  methods: {
    async save(){
      this.saving=true;
      var batch = db.batch();
      this.items.forEach((item)=>{
        let ref = db.collection(this.table).doc(item.id);
        batch.set(ref,item);
      });
      this.removed.forEach(item=>{
        let ref = db.collection(this.table).doc(item.id);
        batch.delete(ref);
      });
      await batch.commit()
      this.hasChanges=false;
      this.saving=false;
    },
    addItem(){
      let vm = this;
      let ref = db.collection(this.table).doc();
      let data = {id:ref.id,name:this.newItem.name,description:this.newItem.description};
      this.items.push(data);
      vm.newItem={name:'',description:''};
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    saveItem(item,index){
      let vm = this;
      Vue.set(vm.items,index,item);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    removeItem(index){
      let vm = this;
      vm.removed.push(vm.items[index]);
      vm.items = vm.items.filter((item,i)=>i!=index);
      vm.hasChanges=true;
      vm.$emit('saveList',vm.list);
    },
    async loadItems(){
      this.items=[];
      let items = null;
      items = await db.collection(this.table).orderBy('name','asc').get();
      items.forEach(item=>this.items.push({...item.data(),id:item.id}));
    }
  },
  async mounted() {
      this.loadItems();
  }
}
</script>
<style>.input-group > .input-group-append > .btn {margin: 0;border-top-right-radius: 30px;border-bottom-right-radius: 30px;}</style>
