<template>
    <div>
        <div class="centered">
          <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>403</h1>
          <p>You don't have permission to view this page.</p>
          <div v-if="user.role.producer">
            <div>
              <p>As a Farmer you can not view the {{ displayName }}. You need to log in as a buyer to view it.</p>
            </div>
            <div class="d-flex justify-content-center">
              <b-button @click="logout" variant="success" >Login as Buyer</b-button>
            </div>
          </div>
          <div v-if="user.role.buyer" >
            <div>
              <p>As a buyer you can not view the {{ displayName }}. You need to log in as a Farmer to view it.</p>
            </div>
            <div class="d-flex justify-content-center">
              <b-button @click="logout" variant="success">Login as Farmer</b-button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {auth} from '@/firebase';
import {bus} from '@/vuex/bus.js';
import store from '@/vuex/store.js';

export default {
  computed: {
    ...mapState(["user"]),
  },
  data(){
    return {
      displayName: ""
    }
  },

  mounted(){
    const vm = this;
      switch (vm.$route.params.by) {
        case "bidRequest":
          vm.displayName = "Product Bid Request" ;       
          break;
        case "productionContract":
          vm.displayName = "Production Contract";
          break;
        case "commodity":
          vm.displayName = "Product";
          break;
        default:
          vm.displayName = "Production Contract";
          break;
      }
    // bus.$on('isProductionContract', (obj) => {
    //   console.log(obj);
    // })
  },

  methods: {
    logout(){
      auth.signOut().then(() => {
        bus.$emit('UserLogout');
        store.commit('updateUser',{});
        this.$router.replace('/login')
      });
    },
  }
}
</script>

<style>
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
      color: black !important;
    }
    p {
      font-size: 20px;
      color: black !important;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
  }
</style>