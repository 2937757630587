<template>
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <card class="card-signup text-center" no-footer-line :cardBodyClasses="'p-0'">
                <template v-if="complete">
                    <p class="text-center">You've successfully created an admin account.</p>
                    <b-button @click="addAnotherAccount" class="btn btn-primary">Create another admin account</b-button>
                </template>
                <template v-else>
                    <template slot="header">
                        <img slot="header" src="/img/logo.png" class="img-fluid" />
                        <h3 class="card-title text-white">Create New Admin Account</h3>
                    </template>
                    <div>
                    <ValidationObserver ref="form">
                        <form @submit.prevent="validate">
                            <div class="pl-4 pr-4 pt-0 pt-md-4">
                                <h3 class="d-block d-md-none">Account</h3>
                                <div class="row">
                                    <div class="col-12 col-lg-12 pb-2">
                                        <ValidationProvider
                                            name="adminAccount.first"
                                            rules="required|min:2"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                :invalid-feedback="'Please enter a first name of at least 2 characters'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-input placeholder="First name (required)" v-model="adminAccount.first" :state="(failed?false:(passed?true:null))" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-12 pb-2">
                                        <ValidationProvider
                                            name="adminAccount.last"
                                            rules="required|min:2"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                :invalid-feedback="'Please enter a last name of at least 2 characters'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-input placeholder="Last name (required)" v-model="adminAccount.last" :state="(failed?false:(passed?true:null))" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-12 pb-2">
                                        <ValidationProvider
                                            name="adminAccount.email"
                                            rules="emailRequired|email|exists"
                                            v-slot="{passed,failed,errors}"
                                        >
                                            <b-form-group
                                                :invalid-feedback="errors.length>0?errors[0]:null"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-input placeholder="Email (required)" v-model="adminAccount.email" :state="(failed?false:(passed?true:null))" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-12 pb-2">
                                        <ValidationProvider
                                            name="adminAccount.password"
                                            rules="required|min:6"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                            <b-form-group
                                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                                :state="failed ? false : passed ? true : null"
                                            >
                                                <fg-input
                                                    :type="passwordType"
                                                    placeholder="Password"
                                                    :addon-right-icon="'fa '+passwordIcon"
                                                    v-model="adminAccount.password"
                                                    @rightclick="togglePasswordShow"
                                                    :state="failed ? false : passed ? true : null"
                                                ></fg-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-12 pb-2 d-lg-flex justify-content-between">
                                        <b-button variant="success" @click="generate">
                                                <span class="fa fa-refresh"></span>
                                                <span class="ml-2">Generate Password</span> 
                                            </b-button>
                                            <b-button variant="danger" @click="CopyPassword">
                                                <span class="fa fa-copy"></span>
                                                <span class="ml-2">Copy Password</span> 
                                            </b-button>
                                    </div>
                                </div>
                               
                                <div class="d-lg-flex justify-content-between mb-4">
                                    <b-button @click="clearForm" variant="light">Clear</b-button>
                                    <b-button :disabled="saving" @click="validate" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>Save<i class="fa fa-arrow-right ml-2" /></b-button>
                                </div>
                            </div>
                        </form>

                    </ValidationObserver>
                </div>
                </template>
            </card>
        </div>
    </div>
</template>


<script>

import { extend } from "vee-validate";
import { required, email  } from "vee-validate/dist/rules";
import {mapState} from 'vuex'
import { auth, db } from '@/firebase';
import {bus} from '@/vuex/bus.js';
import { Clipboard } from "v-clipboard"



extend("required", required);
extend("emailRequired", {
  ...required,
  message:'Email is required'
});
extend("email", {
  ...email,
  message:'Enter a valid email'
});
extend('exists', value => {
  return auth.fetchSignInMethodsForEmail(value).then((res)=>{
    if(res.length>0){
      return 'An account already exists for this email';
    }else{
      return true;
    }
  }).catch(()=>false);
});
export default {
    name: 'AdminUserCreate',

    computed:{
        ...mapState(['user'])
    },

    data() {
        return {
            complete:false,
            adminAccount: {
                first: null,
                last: null,
                email:null,
                password: null,
               
            },
            saving: false,
            passwordType:'password',
            passwordIcon:'fa-eye-slash'
        };
    },

    created(){
        const vm = this;
        if((!vm.user.role.admin)) {
            vm.$router.push("/not/access");
            bus.$emit('prepareTitle', null);
            return;
        }  
    },
    methods: {
        togglePasswordShow(){
            const vm = this;
            if(vm.passwordType=='password') {
                vm.passwordType='text';
                vm.passwordIcon='fa-eye';
            } else {
                vm.passwordType='password';
                vm.passwordIcon='fa-eye-slash';
            }
        },   
        clearForm(){
            const vm = this;
            vm.adminAccount.first = null;
            vm.adminAccount.last = null;
            vm.adminAccount.email = null;
            vm.adminAccount.password = null;
            vm.saving = false;
            vm.$refs.form.reset();
        },
        addAnotherAccount(){
            const vm = this;
            vm.adminAccount.first = null;
            vm.adminAccount.last = null;
            vm.adminAccount.email = null;
            vm.adminAccount.password = null;
            vm.saving = false;
            vm.complete = false;
        },
        generate(){
            const vm = this;
            let password = '';
            var CharacterSet  = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            for(let i=0; i < 8; i++) {
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
            }
            vm.adminAccount.password = password;
        },
        CopyPassword(){
            const vm = this;
            Clipboard.copy(vm.adminAccount.password);
        },
        async validate() {
            const vm = this;
            let validate = await vm.$refs.form.validate()
            vm.saving = false;
            if(validate){
                let ref = db.collection("admin_user").doc();
                let userData = {
                    about:{
                        first: vm.adminAccount.first,
                        last: vm.adminAccount.last,
                        email: vm.adminAccount.email,
                        is_active: true,
                    },
                    business:{
                        type: "admin"
                    },
                    emailNotification: true,
                    terms: "accepted",
                    createdBy: vm.user.uid,
                    password: vm.adminAccount.password
                };

                await db.collection("admin_user").doc(ref.id).set(userData).then(function(){
                    vm.complete=true;
                    vm.saving = true;
                }).catch(function(error) {
                    console.log(error);
                    vm.saving = true;
                });
            } else {
                return;
            }
        },
  },
}
</script>
