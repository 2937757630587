<template>
  <div class="container">
    <div v-if="loading" class="row mt-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
        </card>
      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
    <div v-else class="row">
      <div class="d-block d-md-none col-12"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div>
      <div class="col-12">
        <OrderHeader :order="order" />
      </div>
      <div class="col-12">
        <OrderTimeline :order="order" />
      </div>
    </div>
  </div>

</template>
<script>
import {bus} from '@/vuex/bus.js';
import OrderHeader from "@/views/Dashboard/Order/components/Header.vue";
import OrderTimeline from "@/views/Dashboard/Order/components/Timeline.vue";
import {mapState} from 'vuex'
import {db} from '@/firebase';
export default {
  // props: ['orderID'],
  components: {
    OrderHeader,
    OrderTimeline
  },
  computed:{
    ...mapState(['user']),
  },
  data() {
    return {
      loading:true,
      order:null
    };
  },
  methods:{
    backTo(){
      bus.$emit('backToTop');
    }
  },
  async mounted(){

    let vm = this;

    if(vm.$route.params.id) vm.orderID=vm.$route.params.id;

    let order = await db.collection('orders').doc(vm.orderID).get();
    if(order.exists) vm.order = {...order.data(),id:order.id};

    let commodity = await db.collection('commodities').doc(vm.order.commodityID).get();
    if(commodity.exists) vm.order.commodity = {...commodity.data(),id:commodity.id};

    let ref = await db.collection('commodity_categories').doc(vm.order.commodity.category).get();
    if(ref.exists) vm.order.commodity = {...vm.order.commodity,category:ref.data().name};

    ref = await db.collection('units').doc(vm.order.commodity.unit).get();
    if(ref.exists) vm.order.commodity = {...vm.order.commodity,unit:ref.data().name};

    ref = await db.collection('commodity_types').doc(vm.order.commodity.type).get();
    if(ref.exists) vm.order.commodity = {...vm.order.commodity,type:ref.data().name};

    ref = await db.collection('commodity_subtypes').doc(vm.order.commodity.subtype).get();
    if(ref.exists) vm.order.commodity = {...vm.order.commodity,subtype:ref.data().name};

    if(vm.user.role.admin){

      let commodityLocation = await db.collection('commodity_location').doc(vm.order.commodityID).get();
      if(commodityLocation.exists) vm.order.commodity.location = {...commodityLocation.data()};

      ref = await db.collection('users').doc(vm.order.producerID).get();
      if(ref.exists) vm.order.producer = {...ref.data()};

      ref = await db.collection('users').doc(vm.order.buyerID).get();
      if(ref.exists) vm.order.buyer = {...ref.data()};

    }

    if(!vm.order.services) {
      vm.order.services = [];
    } else {
      let promises=[];
      let services=[];
      vm.order.services.map(s=>promises.push(db.collection('services').doc(s.id).get()));
      let res = await Promise.all(promises);
      res.forEach(r=>{
        if(r.exists) services.push({...r.data(),id:r.id});
      });
      vm.order.services = services;
    }

    vm.order.created = vm.order.created.toDate()

    bus.$emit('prepareTitle',{...vm.order,orderID:vm.order.id});

    vm.loading=false;

  }
}
</script>
