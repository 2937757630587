var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"pl-4 pr-4 pt-0 pt-md-4"},[_c('h3',{staticClass:"d-block d-md-none"},[_vm._v("Account")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-none d-md-flex col"}),_c('div',{staticClass:"col-12 col-md-8 col-lg-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.account.password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors.length > 0 ? errors[0] : null,"state":failed ? false : passed ? true : null}},[_c('b-form-input',{attrs:{"type":"password","placeholder":"Password","state":failed ? false : passed ? true : null},model:{value:(_vm.signup.account.password),callback:function ($$v) {_vm.$set(_vm.signup.account, "password", $$v)},expression:"signup.account.password"}})],1)]}}])})],1),_c('div',{staticClass:"d-none d-md-flex col"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-none d-md-flex col"}),_c('div',{staticClass:"col-12 col-md-8 col-lg-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.account.confirm_password","rules":{
              required: true,
              matches: { password: _vm.signup.account.password },
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors.length > 0 ? errors[0] : null,"state":failed ? false : passed ? true : null}},[_c('b-form-input',{attrs:{"type":"password","placeholder":"Confirm password","state":failed ? false : passed ? true : null},model:{value:(_vm.signup.account.confirm_password),callback:function ($$v) {_vm.$set(_vm.signup.account, "confirm_password", $$v)},expression:"signup.account.confirm_password"}})],1)]}}])}),_c('div',{staticClass:"row"},[(_vm.currentRouteName)?_c('div',{staticClass:"d-flex col"},[_c('div',{staticClass:"col-12 col-md-12 pb-2"},[_c('InputsCheckbox',{attrs:{"inline":true,"v-model":_vm.checked},on:{"input":_vm.input}}),_c('a',{staticStyle:{"position":"relative","top":"20px"},attrs:{"href":"https://www.ngf-global.com/copy-of-user-agreements"}},[_vm._v("Agree to Terms & Conditions")])],1)]):_vm._e()])],1),_c('div',{staticClass:"d-none d-md-flex col"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }