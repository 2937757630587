<template>
    <div class="container">
        <b-card>
            <div>
                <b-alert v-if="error" show variant="danger"><small>{{error}}</small></b-alert>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="w-75">
                    <b-input-group prepend="Buyer ID/Name">
                        <b-form-input type="text" v-model.trim="searchTransactionID" placeholder="please enter the search buyer Id or buyer name"></b-form-input>
                    </b-input-group>
                </div>
                <div class="w-25">
                    <b-button class="ml-2 mt-0"  variant="info" @click="searchTransaction" pill><i class="fa fa-search"></i> Search</b-button>
                </div>
            </div>
        </b-card>
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <div v-if="buyerDetails">
                <div>
                    <b-card>
                        <div v-if="buyerDetails">
                            <div v-if="buyerDetails.about">
                                <div>
                                    <h4 class="card-title mt-0 mb-1"> {{ (buyerDetails.business.type == "buyer") ? 'Buyer' : 'Farmer' }} Details</h4>  
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>First Name</small>
                                        <div v-if="buyerDetails.about.first">
                                            <strong>{{ buyerDetails.about.first }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Last Name</small>
                                        <div v-if="buyerDetails.about.last">
                                            <strong>{{ buyerDetails.about.last }}</strong>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Email</small>
                                        <div v-if="buyerDetails.about.email">
                                            <strong>{{ buyerDetails.about.email }}</strong>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Phone No.</small>
                                        <div v-if="buyerDetails.about.phone">
                                            <strong>{{ buyerDetails.about.phone }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="buyerDetails.business">
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>{{ (buyerDetails.business.type == "buyer") ? 'Buyer' : 'Farmer' }} Company Name</small>
                                        <div v-if="buyerDetails.business.name"><strong>{{ buyerDetails.business.name }}</strong></div>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <small>Buyer status</small>
                                        <div v-if="buyerDetails.about.is_active"><strong>Active</strong></div>
                                        <div v-else><strong>De-Active</strong></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <div v-if="buyerDetails.business.address">
                                            <small>{{ (buyerDetails.business.type == "buyer") ? 'Buyer' : 'Farmer' }} Address</small><br />
                                            <strong class="d-block">{{buyerDetails.business.address.address}}</strong>
                                            <strong class="d-block">{{buyerDetails.business.address.city}}, {{buyerDetails.business.address.state}} {{ buyerDetails.business.address.zip}}</strong >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="transaction">
                                <b-card-body>
                                    <b-list-group>
                                        <b-list-group-item v-for="item in transaction" :key="item.id"  class="align-items-center text-center text-md-left">
                                            <div class="text-left text-md-left w-100 pl-0 pr-0">
                                                <div class="d-flex flex-row justify-content-between">
                                                    <div class="w-75">
                                                        <span class="mt-1 d-md-block">{{item.id}}</span>
                                                        <span class="mt-0"><h6 class="d-inline">Purchase of {{numeral(item.amount).format('0,000')}} {{item.unit.name}} {{item.subtype.name}} {{item.type.name}}</h6></span><br />
                                                        <span><strong>Total:</strong> <span class="d-inline">${{numeral(item.total).format('0,000.00')}}</span></span>
                                                    </div>
                                                    <div class="w-25">
                                                        <b-button variant="success" @click="viewOrder(item)">
                                                            View Order
                                                        </b-button>
                                                        <b-button variant="info" @click="viewProduct(item)" class="ml-1">
                                                            View Product
                                                        </b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card-body>
                            </div>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
        <div v-if="transactionNotFound">
            <b-card>
                <p class="text-muted text-center">No Details found.</p>
            </b-card>
        </div>
    </div>
</template>

<script>

import {db} from '@/firebase';
import numeral from 'numeral';
import Vue from "vue";
import { collectionData, docData } from 'rxfire/firestore';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import {mapState} from 'vuex'

Vue.prototype.numeral = numeral;


export default {
    data() {
        return {
            loading: false,
            searchTransactionID: (this.$route.params.id) ? this.$route.params.id : '',
            transaction: [],
            buyerDetails: null,
            loadingError: false,
            transactionNotFound: false,
            error:'',
        }
    },
    computed:{
        ...mapState(['user']),
    },
    mounted(){
        if(!this.user.role.admin){
            this.$router.replace("/not/access")
        }
        if(this.$route.params.id){
            this.searchTransaction();
        }
    },
    methods: {
        viewProduct(item){
            this.$router.replace('/commodity/view/' + item.commodityID);
        },
        viewOrder(item){
            this.$router.replace('/order/view/' + item.id);
        },  
        async searchTransaction(){
            let vm = this;
            vm.loading = true;
            vm.transactionNotFound = false;
            vm.buyerDetails = null; 
            if(vm.searchTransactionID == ""){
                vm.error = "Please enter the search buyer id/name"
                vm.searchTransactionID = '';
                vm.loading = false;
                setTimeout(() => {
                    vm.error = '';
                }, 5000);
                return false;
            } else {
                    let buyerDetails = await db.collection('users').doc(vm.searchTransactionID).get();
                    if(buyerDetails.exists) {
                        let buyerData = {...buyerDetails.data(),id:buyerDetails.id};
                        // if(buyerData.business.type == "buyer"){
                            vm.getBuyerData(buyerData);
                        // } else {
                        //     vm.transactionNotFound = true;
                        //     vm.loading = false;
                        //     setTimeout(() => {
                        //         vm.transactionNotFound = false;
                        //     }, 10000);                    
                        //     return false;        
                        // }
                    } else if(buyerDetails.exists == false){
                        const [firstName, lastName] = vm.searchTransactionID.split(' ');
                        let buyerData = await db.collection('users').limit(1)
                        if(firstName != undefined){
                            buyerData = buyerData.where('about.first', '==', firstName)
                        }
                        if(lastName != undefined){
                            buyerData = buyerData.where('about.last', '==', lastName)
                        }
                        // buyerData = buyerData.where('business.type', '==', 'buyer')
                        buyerData.get().then((querySnapshot) => {
                            const data  = querySnapshot.docs.map((doc) => {
                                return {id: doc.id, ...doc.data()};
                            });
                            this.getBuyerData(data[0]);
                       });
                    } else {
                        vm.transactionNotFound = true;
                        vm.loading = false;
                        setTimeout(() => {
                            vm.transactionNotFound = false;
                        }, 10000);
                    }

            }
        },
        async getBuyerData(buyerDetails){
            let vm = this;
            vm.transaction = [];
            if(buyerDetails != undefined){
                vm.buyerDetails = buyerDetails;
                let transaction = await db.collection('orders').where('is_restore', '==', null).where('buyerID', '==', buyerDetails.id);
                collectionData(transaction, 'id').pipe(
                switchMap((item) => {
                    if(item.length==0) return of([]);
                    return combineLatest(...item.map(order => {
                        return docData(db.collection('commodities').doc(order.commodityID)).pipe(
                            map(c => {
                                if(JSON.stringify(c) !== '{}'){
                                    return {...order, commodity:c }
                                } else {
                                    return {...order, commodity:{}}
                                }
                            })
                        )
                    }))
                })
                ).pipe(
                    switchMap((item) => {
                        if(item.length==0) return of([]);
                        return combineLatest(...item.map(order => {
                            if(order.commodity && order.commodity.unit){
                                return docData(db.collection('units').doc(order.commodity.unit)).pipe(
                                    map(u => {
                                        return {...order, unit:u }
                                    })
                                )
                            } else {
                                return of({...order, unit:''})
                            }
                        }))
                    })
                ).pipe(
                    switchMap((item) => {
                        if(item.length==0) return of([]);
                        return combineLatest(...item.map(order => {
                            if(order.commodity && order.commodity.type){
                                return docData(db.collection('commodity_types').doc(order.commodity.type)).pipe(
                                    map(t => {
                                        return {...order, type:t }
                                    })
                                )
                            } else {
                                return of({...order, unit:''})
                            }
                        }))
                    })
                ).pipe(
                    switchMap((item) => {
                        if(item.length==0) return of([]);
                        return combineLatest(...item.map(order => {
                            if(order.commodity && order.commodity.subtype){
                                return docData(db.collection('commodity_subtypes').doc(order.commodity.subtype)).pipe(
                                    map(t => {
                                        return {...order, subtype:t }
                                    })
                                )
                            } else {
                                return of({...order, subtype:''})
                            }
                        }))
                    })
                ).subscribe((items) => {
                    items.forEach((i)=>{
                        if(i.type){
                            vm.transaction.push(i)
                        }
                    });
                    vm.loading = false;
                });
            } else {
                let vm = this;
                vm.transactionNotFound = true;
                vm.loading = false;
                setTimeout(() => {
                    vm.transactionNotFound = false;
                }, 10000);
                return false;
            }
        },
    },

}
</script>