<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="validate">
                <div class="container">
                   <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="m-0">General</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.category"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Category"
                                            :invalid-feedback="'Category is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="categoryChange" placeholder="Choose category" v-model="productionContract.category" :options="optionCategories" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.year"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >

                                        <b-form-group
                                            label="Crop year"
                                            :invalid-feedback="'Year is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select placeholder="Choose year" v-model="productionContract.year" :options="sortedYears" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.type"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Product"
                                            :invalid-feedback="'Product is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="typeChange" :disabled="optionTypes.length==0" placeholder="Choose product" v-model="productionContract.type" :options="optionTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.subtype"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Type"
                                            :invalid-feedback="'Type is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select @change="subTypeChange" :disabled="optionSubTypes.length==0" placeholder="Choose type" v-model="productionContract.subtype" :options="optionSubTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <b-form-group
                                        label="Variety"
                                    >
                                        <b-form-input placeholder="Enter variety" v-model="productionContract.variety" />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.act_of_god"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            label="Act of God"
                                            :invalid-feedback="'Please select'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-radio-group v-model="productionContract.act_of_god">
                                                <b-form-radio value="yes">Yes</b-form-radio>
                                                <b-form-radio value="no">No</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.available_acres"
                                        rules="required|decimal"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group label="Number of Acres"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-input placeholder="Enter number of requested acres" v-model="productionContract.available_acres" :state="(failed?false:(passed?true:null))" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <ValidationProvider
                                        name="productionContract.unit"
                                        rules="required"
                                        v-slot="{ passed, failed }"
                                    >
                                    <b-form-group
                                        label="Unit of Measure"
                                        :invalid-feedback="'Unit is required'"
                                        :state="(failed?false:(passed?true:null))"
                                    >
                                        <b-form-select placeholder="Choose units" v-model="productionContract.unit" :options="optionUnits" :state="(failed?false:(passed?true:null))"></b-form-select>
                                    </b-form-group>

                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.total"
                                        rules="required|decimal"
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            :label="'Guaranteed'+(selectedUnit?' '+selectedUnit + ' Per Acre':'')"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-input placeholder="Enter total" v-model="productionContract.total" :state="(failed?false:(passed?true:null))"></b-form-input>
                                            <div class="invalid-feedback d-block" v-if="minimumFailed">Total must meet the minimum of {{numeral(minimumQuantity).format('0,000')}} {{selectedUnit}}</div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="productionContract.priceOption"
                                        rules="required"
                                        v-slot="{passed, failed}"
                                    > 
                                        <b-form-group
                                            label="Price Option"
                                            :invalid-feedback="'Price Option is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-select placeholder="Choose Price Options" v-model="productionContract.priceOption" :options="optionPriceOption" :state="(failed?false:(passed?true:null))"></b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                        name="CurrencyPrice"
                                        :rules="{ required : true}"   
                                        v-slot="{ passed, failed }"
                                    >
                                        <b-form-group
                                            :label="(selectedPriceOption? selectedPriceOption : '') + ' Price'+(selectedUnit?' per '+selectedUnit:'')"
                                            :invalid-feedback="'Please enter a valid number'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <b-form-input  :disabled="productionContract.priceOption == null" placeholder="Enter price" ref="currencyInput" v-currency="priceFormatOptions" v-model="CurrencyPrice" :state="(failed?false:(passed?true:null))"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div>
                                <span>Delivery Window</span>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.startDate"
                                    rules="required"
                                    v-slot="{ passed, failed }"
                                    >
                                    <b-form-group
                                        :invalid-feedback="'Start Date is required'"
                                        :state="(failed?false:(passed?true:null))"
                                    >
                                        <Datepicker placeholder="Start Date" v-model="productionContract.startDate" :disabledDates="disabledStartDates"   @selected="selectStartDate"  :state="(failed?false:(passed?true:null))"/>
                                    </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-6 pt-2">
                                    <ValidationProvider
                                    name="productionContract.endDate"
                                    :rules="{required: true, minimumStartDate: productionContract.startDate }"
                                    v-slot="{ passed, failed, errors }"
                                    >
                                        <b-form-group
                                            :state="(dateError?false:(passed?true:null))"
                                        >
                                            <Datepicker placeholder="End Date" v-model="productionContract.endDate" :disabled="disabledEndDate" :disabledDates="disabledEndDates" :state="(failed?false:(passed?true:null))"/>
                                        </b-form-group>
                                        <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                            {{ errors[0]=='MinimumStartDate'?'End date must be after the start date ' + moment(productionContract.startDate).format('ll'): (errors.length > 0) ? 'End Date is required' : ''}}
                                        </b-form-invalid-feedback>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <h5>Details</h5>
                                </div>
                            </div>
                            <div class="col-12 pt-2 bg-white border rounded">
                                <b-form-group
                                    id="fieldset-commodity-details"
                                    description="Enter additional details for your Production Contract Offer."
                                    label="Include more details"
                                >
                                    <vue-editor v-model="productionContract.details"></vue-editor>
                                </b-form-group>
                            </div>
                            <!-- <div class="row">
                                <div class="col-12 pt-4 text-center">
                                    <p><a @click="showCreatingGreat=true" class="text-success font-weight-bold"><i class="fa fa-info-circle mr-2" />Learn more about creating great Production Contract Offers</a></p>
                                </div>
                            </div> -->
                            <b-modal size="lg" ref="learn-more-modal" v-model="showCreatingGreat" hide-footer title="Creating Great Product Listings">
                                <CreatingGreatListings />
                            </b-modal>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <h5>Location</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2">
                                    <b-alert show variant="info">The requested address will be used for Production Contract Offer location purposes.  The precise location will not be shared with the Farmer; however, the general location will be shared.</b-alert>
                                    <b-form-group
                                        label="Where is your Production Contract located?"
                                    >
                                        <gmap-autocomplete
                                            @place_changed="setPlace"
                                            :types="['address']"
                                            :enable-geolocation="true"
                                        >
                                        </gmap-autocomplete>
                                        <div class="invalid-feedback d-block" v-if="positionFailed">Please set the location</div>
                                    </b-form-group>
                                    <b-button variant="info" @click="setPosition">Set Location</b-button>
                                    <gmap-map
                                        v-if="productionContractPlace"
                                        :center="productionContractPlace.position"
                                        :zoom="12"
                                        class="mt-4"
                                        style="width:100%;  height: 320px;"
                                    >
                                        <gmap-marker
                                            :position="productionContractPlace.position"
                                        ></gmap-marker>
                                    </gmap-map>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><hr /></div>
                            </div>
                            <div class="row">
                                <div class="col-12 pt-2"><h5>Certifications</h5></div>
                            </div>
                            <div v-if="productionContract.specifications.length > 0">
                                <EditSpecification v-for="specification in productionContract.specifications" :key="specification.id" :specification="specification" @cert-add="getAddCert" @cert-del="getDelCert"  @remove="certRemoved" />
                            </div>
                            <NewSpecification :productionContractID="productionContract.id" :certificationIndex="productionContract.specifications.length" @saved="certAdded" @cert-add="getAddCert" @cert-del="getDelCert" class="mt-4" />
                            <div v-if="error" class="row mt-2">
                                <div class="col-12"><div class="alert alert-danger"><i class="fa fa-exclamation-triangle mr-2"></i>One or more required fields are missing.</div></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12"><div class="alert alert-primary"><i class="fa fa-balance-scale mr-2"></i>By proceeding, you acknowledge reading and agreeing to NGF-Global’s policies <a href="https://www.ngf-global.com/policy" target="_blank" class="text-white font-weight-bold"><u>https://www.ngf-global.com/policy</u></a></div></div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <b-button @click="backTo" variant="light">Cancel</b-button>
                                </div>
                                <div class="col-6 text-right">
                                    <b-button :disabled="saving" @click="validate" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>Submit<i class="fa fa-arrow-right ml-2" /></b-button>
                                </div>
                            </div>
                        </div>
                   </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>

// import {db} from '@/firebase';
import Vue from "vue";
import {mapState} from 'vuex'
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {bus} from '@/vuex/bus.js';
import { VueEditor } from "vue2-editor";
import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import NewSpecification from "@/components/ProductionSpecification/New.vue";
import EditSpecification from "@/components/ProductionSpecification/Edit.vue";
import * as VueGoogleMaps from "vue2-google-maps";
import * as geofire from 'geofire-common';
import {db} from '@/firebase';
import Datepicker from 'vuejs-datepicker';
import firebase from 'firebase';
import moment from 'moment';
import { getValue } from "vue-currency-input";


extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));

extend("minimumPrice", {
    params: ["minimumPrice"],
    validate: (value, {minimumPrice})=>{
        if(typeof value != 'number'){
            let tempPrice = value.replace('$', '');
            return (parseFloat(tempPrice)>=minimumPrice);
        }
    },
    message:'Minimum'
});
extend("minimumStartDate", {
  params: ["minimumStartDate"],
  validate: (value, {minimumStartDate})=>{
    const startDate = new Date(value);
    const EndDate = new Date(minimumStartDate);
    return !(startDate.getTime()<=EndDate.getTime());
  },
  message:'MinimumStartDate'
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
    libraries: "places"
  }
});
export default {
    computed: {
        ...mapState(['user']),
        selectedUnit(){
            let unit = '';
            this.optionUnits.forEach((u)=>{
                if(u.value==this.productionContract.unit) unit = u.text;
            })
            return unit;
        },
        selectedPriceOption(){
            let priceOption = '';
            this.optionPriceOption.forEach((p) => {
                if(p.value==this.productionContract.priceOption) priceOption = p.text;
            });
            return priceOption;
        },
        availableYears(){
          return [...Array(6).keys()].map(k=>Math.abs(k-moment().year()));
        },
        sortedYears: function(){
            return [...this.years].sort((a, b) => {
                return a - b;
            });
        },
    },
    watch:{
        CurrencyPrice(){
            this.productionContract.price = getValue(this.$refs.currencyInput);
        },

    },
    components:{
        VueEditor,
        NewSpecification,
        CreatingGreatListings,
        Datepicker,
        EditSpecification
    },
    data() {
        return{
            error:false,
            saving:false,
            saved: true,
            productionContract: {
                id:null, 
                available_acres:null,
                details:null,
                location:null,
                specifications: [],
                unit:null,
                priceOption: null,
                price:null,
                total:null,
                startDate: null,
                type: null,
                subtype: null,
                variety:null,
                category: null,
                year: null,
                endDate: null,
                active:true,
                is_delete: 0,
                status: true,
                grainSampleMissing: null,
                act_of_god: "yes",
                is_newest: 1,
                slug: null,
            },
            CurrencyPrice: '',
            optionUnits:[],
            optionCategories:[],
            optionPriceOption: [],
            optionTypes:[],
            optionSubTypes:[],
            years: [],
            showCreatingGreat:false,
            positionFailed: null,
            dateError: null,
            place:null,
            productionContractPlace:null,
            specification_name:false,
            grainSampleMissing:null,
            minimumFailed: null,
            disabledStartDates: {
                to: new Date(Date.now() - 8640000)
            },
            disabledEndDate: true,
            disabledEndDates: {
                to: new Date(Date.now() - 8640000)
            },
            priceFormatOptions: {
                locale:"en",
                currency: "USD",
                precision: 2,
                distractionFree: false,
                valueAsInteger: false,
                autoDecimalMode: true,
                allowNegative: true
            },
        }
    },


    async created(){
        const vm = this;
        let cRef = await db.collection('certifications').where('name','==','Standard Grade Test').get();
        if(!cRef.empty) vm.idGrainSample=cRef.docs[0].id;
        let ref = db.collection('production_contract').doc();
        vm.productionContract.id=ref.id;
    },

    async mounted(){
        const vm = this;
        vm.load();
    },

    methods:{
        async load(){
            const vm = this;
            await vm.getYears();
            let categories = await db.collection('commodity_categories').orderBy('name','asc').get();
            categories.forEach(c=>{vm.optionCategories.push({text:c.data().name,value:c.id})});

            let priceOptions = await db.collection('price_options').orderBy('name', 'asc').get();
            priceOptions.forEach(c=>{vm.optionPriceOption.push({text:c.data().name, value:c.id})});

            let units = await db.collection('units').orderBy('name','asc').get();
            units.forEach(c=>{vm.optionUnits.push({minimum:c.data().minimum,text:c.data().name,value:c.id})});
        },

        async validate() {
            const vm = this;
            vm.error=false;
            vm.saving=true;
            let res = await vm.$refs.form.validate();

            if(!vm.productionContractPlace) vm.positionFailed = true;
            if(res&&!vm.positionFailed){
                let data = vm.productionContract;
                
                if(data.specifications.length==0) {
                    data.specification = firebase.firestore.FieldValue.delete();
                    data.specifications = firebase.firestore.FieldValue.delete();
                } else {
                    data.specification = data.specifications.map(c=>c.specification);
                }
                
                data.location=vm.productionContractPlace.address;
                data.userID=vm.user.uid;    
                
                try{
                    data.updated = firebase.firestore.FieldValue.serverTimestamp();
                    data.created = firebase.firestore.FieldValue.serverTimestamp();
                
                    await db.collection('production_contract').doc(vm.productionContract.id).set(data,{merge:true}).then(async() => {
                        await db.collection('production_contract_location').doc(vm.productionContract.id).set({...vm.productionContractPlace});
                        setTimeout(() => { vm.$router.push('/'); }, 4000);
                    }).catch((e) => {
                        vm.error=e;
                        vm.error = true;
                        vm.saving = false
                    });
                
                } catch(e){
                    vm.error=e;
                    vm.error = true;
                    vm.saving = false
                }
            } else{
                vm.error=true;
                vm.saving=false;
            }
        },


        getAddCert: async function getAddCert(value){ 
            const vm = this;
            let tempCertificationArray = await vm.getUniqueArray(vm.productionContract.specifications);
            vm.productionContract.specifications = [];
            vm.productionContract.specifications = tempCertificationArray;
            vm.specification_name = (value === "Standard Grade Test" || value === "I1fDAEKhTwpOUqNd41RA" || value === "ZxTMNwcDS13tgIpYXxcw" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Pesticide Test and/or Herbicide Test" || value === "AC4ISjtmNDKq62MqlVCR" || value === "rJN8HDaXrlEyUgYu0REl" ) ? true : vm.specification_name;
            vm.specification_name = (value === "Organic Certification" || value === "M5pvkZh2dEuXyuKkcR9D" || value === "Q04rVQ0vbu8RRvXGMuvT") ? true : vm.specification_name;
        },

        getDelCert: function getDelCert(value){ 
            const vm = this;
            vm.specification_name = (value === "Standard Grade Test" || value === "I1fDAEKhTwpOUqNd41RA" || value === "ZxTMNwcDS13tgIpYXxcw") ? vm.specification_name : false;
            vm.specification_name = (value === "Pesticide Test and/or Herbicide Test" || value === "AC4ISjtmNDKq62MqlVCR" ) ? vm.specification_name : false;
            vm.specification_name = (value === "Organic Certification" || value === "M5pvkZh2dEuXyuKkcR9D" || value === "Q04rVQ0vbu8RRvXGMuvT") ? vm.specification_name : false;
        },
         
        certAdded(cert, index, isCertificationNotDelete = true){
            const vm = this;
            if(cert.id !== null){
                if(isCertificationNotDelete){
                    vm.productionContract.specifications[index] = cert;
                } else{
                    vm.productionContract.specifications.splice(index, 1);
                }
            }
        },

        certRemoved(id){
            const vm = this;
            vm.productionContract.specifications = vm.productionContract.specifications.filter(c=>c.id!=id);
        },

        async selectCommodityForContract(){
            const vm = this;
            let ref = await db.collection('commodities').doc(vm.productionContract.commodityId).get();
            if(ref.exists){
                let commodityData = {...ref.data()};
                vm.productionContract.category = commodityData.category;
                vm.productionContract.type = commodityData.type;
                vm.productionContract.subtype = commodityData.subtype;
            }
        },

        async categoryChange(){
            const vm = this;
            vm.optionTypes=[];
            vm.optionSubTypes=[];
            if(vm.productionContract.category){
                let types = await db.collection('commodity_types').where('category','==',vm.productionContract.category).get();
                if(!types.empty) types.forEach(t=>{vm.optionTypes.push({text:t.data().name,value:t.id})});
            }
        },

        async typeChange(){
            const vm = this;
            vm.optionSubTypes=[];
            if(vm.productionContract.type){
                let subtypes = await db.collection('commodity_subtypes').where('type','==',vm.productionContract.type).get();
                if(!subtypes.empty) subtypes.forEach(t=>{vm.optionSubTypes.push({text:t.data().name,value:t.id})});
            }
        },
       

        async subTypeChange(){
            const vm = this;
            let tempSlug = null;

            let ref = await db.collection('commodity_subtypes').doc(vm.productionContract.subtype).get();
            if(!ref.empty) var subType = {...ref.data()}.name;

            ref = await db.collection('commodity_types').doc(vm.productionContract.type).get();
            if(!ref.empty) var type = {...ref.data()}.name;

            let productionContractRef = await db.collection('production_contract')
                                                .where('type', '==', vm.productionContract.type)
                                                .where('subtype', '==', vm.productionContract.subtype).get();

            if(!productionContractRef.empty){
                let totalExistingProduct = productionContractRef.size + vm.generateRandomNumber();
                tempSlug = (subType + "-" + type + "-" + totalExistingProduct).trim();
            } else {
                tempSlug = (subType + "-" + type).trim();
            }                      
            
            let productSlug = tempSlug.toLowerCase().replace(/\s+/g,'-');
            productSlug = productSlug.replace("/", "-");
            vm.productionContract.slug = productSlug;
        },

        generateRandomNumber(){
            return Math.floor(Math.random() * (99 - 10 + 1) + 10) ;
        }, 

        randomNumberGenerate(){
            return Math.random() * (120 - 100) + 100;
        },

        setPlace(place) {
            const vm = this;
            let generateRandomNumber = (parseFloat("0.0000" + parseInt(vm.randomNumberGenerate())));
            let arr = place.address_components.filter((ac)=>{
                return ( ac.types.indexOf('administrative_area_level_2')>-1 || ac.types.indexOf('administrative_area_level_1')>-1
                );
            });
            let address = arr.map(a=>a.long_name).join(', ');
            vm.place = {
                    position:{
                        lat: place.geometry.location.lat() + generateRandomNumber,
                        lng: place.geometry.location.lng()
                    },
                formatted_address:place.formatted_address,
                address:address,
                url:place.url,
                reference:place.reference
            };
        },

        setPosition() {
            const vm = this;
            if (vm.place) {
                vm.productionContractPlace = vm.place;
                const lat = vm.place.position.lat;
                const lng = vm.place.position.lng;
                const hash = geofire.geohashForLocation([lat, lng]);
                vm.productionContractPlace.geohash = hash;
                vm.productionContract.geohash = hash;
                vm.productionContract.placePosition = {
                    lat: lat,
                    lng: lng
                },
                vm.positionFailed=null;
            }else{
                vm.positionFailed=true;
            }
        },

        selectStartDate(){
            const vm = this;
            vm.disabledEndDate = false;
        },

      

        backTo(){
          bus.$emit('backToTop');
        },

        async getYears(){
            const vm = this;
            let refs = await db.collection('cropyear').orderBy('year', 'desc').get();
            refs.forEach(ref => {
                vm.years.push(ref.data().year)
            });
        },

        getUniqueArray(items){      
            return [
                ...new Map(
                    items.map((item) => [item['id'], item])
                ).values()
            ]
        },
    },
}

</script>
<style>
.vdp-datepicker input[type="text"], .pac-target-input{
  background-color: transparent;
    border: 1px solid #9A9A9A;
    border-radius: 30px;
    width:100%;
    color: #2c2c2c;
    line-height: normal;
    height: auto;
    font-size: 0.8571em;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    box-shadow: none;
    padding: 10px 18px 10px 18px;
}

@media (min-width: 300px){
    .navbar-brand.text-white.d-flex span span.d-block {
        font-size: initial !important;
    }
}

@media (min-width: 768px){
    .navbar-brand.text-white.d-flex span span.d-block {
        font-size: x-large !important;
    }
}

@media screen and (min-width: 400px) {
    .navbar-brand.text-white.d-flex span span.d-block {
        font-size: large !important;
    }
}

@media screen and (min-width: 500px) {
    .navbar-brand.text-white.d-flex span span.d-block {
        font-size: 25px !important;
    }
}

@media screen and (min-width: 720px) {
    .navbar-brand.text-white.d-flex span span.d-block {
        font-size: unset !important;
    }
}
</style>
