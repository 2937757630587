<template>
    <div>
        <div class="centered">
          <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>403</h1>
          <p>You don't have permission to view this page.</p>
          <div>
            <div class="d-flex justify-content-center">
              <b-button @click="backTo" variant="success">Return Home Page</b-button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

export default {
    name:"notAccess",
    methods: {
      backTo(){
        this.$router.push("/")
      }
    }
}
</script>

<style>
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
      color: black !important;
    }
    p {
      font-size: 20px;
      color: black !important;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
  }
</style>