<template>
  <div class="card card-plain border-dotted">
    <div class="card-header">Add New Certification</div>
    <div class="card-body">
      <div v-if="error" class="alert alert-danger mb-4">{{error}}</div>
      <div class="row mb-4">
        <div class="col">
          <label>Certification Type</label>
          <b-form-select v-model="certification.certification" :options="optionsCertification" @change="onChangesCertification" ></b-form-select>
        </div>
      </div>
      <div v-if="uploading" class="row mt-4 mb-4">
        <div class="col-12 text-center"><b-progress :value="progressUpload" variant="primary" striped :animated="true"></b-progress><br /><small>Uploading...</small></div>
      </div>
      <div v-else-if="certification.file" class="row mt-4 mb-4">
        <div class="col-12 text-center"><a :disabled="deleting" @click="deleteFile" class="text-danger mr-2"><span :class="'fa '+(deleting?'fa-spinner':'fa-times')"></span></a><strong><em class="text-muted">{{certification.file.name}}</em></strong></div>
      </div>
      <div v-else class="row mt-4 mb-4">
        <div class="col-12 col-md-5"><label>Add Certification</label><b-input :disabled="formFieldsDisabled" v-model.trim="certificationURL" placeholder="Enter URL" /></div>
        <div class="col-12 col-md-2 text-center pt-4">or</div>
        <div class="col-12 col-md-5 text-center pt-4"><a :disabled="formFieldsDisabled" @click="selectFile" class="text-muted mt-2" :class="{'add-pointer': !formFieldsDisabled, 'remove-pointer': formFieldsDisabled}"><i class="fa fa-upload mr-1"></i>Upload Document</a></div>
      </div>
      <div class="row mt-4 mb-4" >
        <div class="col-12">
          <label>More Information</label>
          <b-form-textarea
            class="form-controller"
            v-model.trim="moreInformation"
            placeholder="Other"
            rows="5"
            :disabled="formFieldsDisabled"
          >
            {{ certification.more_information }}
          </b-form-textarea>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class=""></div>
      </div>
      <div class="row col-12">
        <div class="">
          <b-link @click="addMore" :disabled="submitButtonDisabled" size="sm" class="btn btn-info">+ Add</b-link>
        </div>
        <!-- <div class="ml-4">
          <b-link @click="addCertification" size="sm" class="btn btn-success">Save</b-link>
        </div> -->
      </div>
      <input class="d-none" id="upload"
                type="file"
                name="upload"
                ref="uploadInput"
                accept="image/*, application/pdf, .doc, .docx,.txt"
                :multiple="false"
                @change="detectFiles($event)" />
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {db} from '@/firebase';
import {firestorage} from '@/firebase';
export default {
  props: ['commodityID', 'certificationIndex'],
  computed:{
    ...mapState(['user']),
  },
  data() {
    return {
      error:null,
      uploading:false,
      deleting:false,
      progressUpload: 0,
      uploadTask:'',
      tempCertificateID: null,
      formFieldsDisabled: true,
      submitButtonDisabled: true,
      certificationURL: null,
      moreInformation: null,
      clickAddMore: false,
      certification:{
        certification:null,
        url:null,
        file:null,
        more_information:null
      },
      optionsCertification:[{value:null,text:'--select one--'}]
    }
  },
  components: {},
  async mounted(){
    let vm = this;
    let certifications = await db.collection('certifications').orderBy('name','asc').get();
    certifications.forEach(c=>{vm.optionsCertification.push({value:c.id,text:c.data().name})});
  },
  methods:{
    async addMore(){
      const vm = this;
      vm.certification.url = vm.certificationURL;
      vm.certification.more_information = vm.moreInformation;
      await vm.saveCertificationDetails();
      await vm.$emit('cert-add', vm.certification.certification)
      vm.tempCertificateID = null;
      vm.clickAddMore = true;
      vm.certificationURL = null;
      vm.moreInformation = null;
      vm.formFieldsDisabled = true;
      vm.submitButtonDisabled = true;
      vm.certification={
        certification:null,
        url:null,
        file:null,
        more_information:null
      };
    },


    async saveCertificationDetails(){
      let data = {...this.certification,commodityID:this.commodityID,userID:this.user.uid};
      if(!data.url) delete data.url;
      if(!data.file) delete data.file;
      console.log(this.tempCertificateID);
      if(this.tempCertificateID == null){
        let ref = await db.collection('commodity_certifications').doc();
        this.tempCertificateID = ref.id;
        data.id = ref.id
        await db.collection('commodity_certifications').doc(ref.id).set(data)
        this.$emit('saved',{...data, id: ref.id}, this.certificationIndex, true);
      } else {
        await db.collection('commodity_certifications').doc(this.tempCertificateID).set(data)
        this.$emit('saved',{...data, id: this.tempCertificateID}, this.certificationIndex, true);
      }
    },
    async onChangesCertification(){
      const vm = this;
        vm.clickAddMore = false;
        if(vm.certification.certification !== null){
          vm.formFieldsDisabled = false;
          vm.submitButtonDisabled = false;
          vm.saveCertificationDetails();
        } else {
          vm.formFieldsDisabled = false;
          vm.certification={
            certification:null,
            url:null,
            file:null,
            more_information:null
          };
          if(vm.tempCertificateID != null){
            let data = null;
            vm.$emit('saved',{...data, id: vm.tempCertificateID}, vm.certificationIndex, false);
            await db.collection('commodity_certifications').doc(vm.tempCertificateID).delete();
            vm.saveCertificationDetails();
          }
        }
    },
    async deleteFile(){
      this.deleting=true;
      this.$emit('cert-del', this.certification.certification)
      let storageRef = firestorage.ref(this.certification.file.fullPath);
      await storageRef.delete();
      this.certification.file=null;
      this.deleting=false;
    },
    upload (file) {
      this.uploading = true;
      console.log('cc/' + this.commodityID + '/' + file.name);
      this.uploadTask = firestorage.ref('cc/' + this.commodityID + '/' + file.name).put(file)
    },
    selectFile () {
      if(!this.formFieldsDisabled){
        this.$refs.uploadInput.click(); 
      } else {
        return;
      }
    },
    async detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(async(x) => {
        await this.upload(fileList[x])
      }) 
    },
  },
  watch: {
    certificationURL(){
      const vm = this;
      if(vm.clickAddMore){
        return;
      }
      if(vm.certificationURL !== "" && vm.certification.certification !== null){
        vm.certification.url = vm.certificationURL;
        vm.submitButtonDisabled = false;
        vm.saveCertificationDetails();
      } else {
        vm.certification.url = "";
        vm.saveCertificationDetails();
        vm.submitButtonDisabled = true;
        if(vm.certification.certification === null){
          vm.error = "Please select Certification Type."
          setTimeout(() => {
              vm.error = null;
          }, 5000);
        }
        return;
      }
    },
    moreInformation(){
      const vm = this;
      if(vm.clickAddMore){
        return;
      }
      if(vm.moreInformation !== "" && vm.certification.certification !== null){
        vm.certification.more_information = vm.moreInformation;
        vm.saveCertificationDetails();
      } else {
        if(vm.certification.certification === null){
          vm.certification.more_information = "";
          vm.error = "Please select certification type."
          setTimeout(() => {
              vm.error = null;
          }, 5000);
        }
        return;
      }
    },
    selectedCert: function (selection) {
      console.log(selection)
    },
    uploadTask: function () {
      let vm = this;
      vm.uploadTask.on('state_changed', sp => {
        vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      async () => {
        let data = await vm.uploadTask.snapshot.ref.getMetadata();
        for(let key in data){
          if(data[key] === undefined) delete data[key];
        }
        vm.certification.file=data;
        vm.uploading=false;
        vm.submitButtonDisabled = false;
        await this.saveCertificationDetails();
      })
    }
  }
};
</script>
<style>
.add-pointer {
  cursor: pointer;
}
.remove-pointer {
  cursor: not-allowed;
}
</style>
