<template>
    <div>
        <div class="border-2 bg-white rounded p-4">
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left text-muted text-break" v-if="completeBidRequest.buyerID">
                    Owner ID: {{ completeBidRequest.buyerID.substr(0,10).toUpperCase() }}
                </div>
                <div class="flex-fill text-center text-md-right text-success font-weight-bold">
                    {{ moment(completeBidRequest.created).format("ll") }}
                </div>
            </div>
            <h2 class="mt-2 mb-2 d-none d-md-block">
               Trans.ID: {{ completeBidRequest.id.toUpperCase() }}
            </h2>

            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left pt-3">
                    <h6 class="d-inline">
                        Product Bid Request Accepted:  {{ completeBidRequest.fullFillAmount }} {{ completeBidRequest.BidRequest.unit ? completeBidRequest.BidRequest.unit : "" }}  {{ completeBidRequest.BidRequest.subtype }} {{ completeBidRequest.BidRequest.type }}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex" v-if="completeBidRequest.offerPrice">
                <div class="flex-fill text-center text-md-left pt-0">
                    <h6 class="d-inline">
                        Accept Farmer Offer Price: ${{ completeBidRequest.offerPrice }}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left pt-0">
                    <h6 class="d-inline">
                        {{ completeBidRequest.BidRequest.priceOption }} Price (Per {{completeBidRequest.BidRequest.unit}}): 
                        <span v-if="completeBidRequest.BidRequest.price != null">
                            {{(completeBidRequest.BidRequest.price >= 0) ? "$" : "-$" }}{{ (completeBidRequest.BidRequest.price >= 0) ?  numeral(Math.abs(completeBidRequest.BidRequest.price)).format('0,000.00') : '' }}
                        </span>
                        <span v-else>-</span>
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left pt-0">
                    <h6 class="d-inline">
                        CROP YEAR: {{ completeBidRequest.BidRequest.year }}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left pt-0">
                    <h6 class="d-inline">
                        Delivery Window: {{moment(completeBidRequest.BidRequest.startDate.toDate()).format('L')}} - {{moment(completeBidRequest.BidRequest.endDate.toDate()).format('L')}}
                    </h6>
                </div>
                <div class="flex-fill text-center text-md-right total-global">
                    <!-- <div class="pt-0">
                       
                       <strong>NGF Global Fees:</strong>
                       <h3 class="d-inline font-weight-bold">
                           ${{ numeral(ngfGlobalFees).format("0,000.00") }}
                       </h3>
                   </div> -->
                   <div class="pt-0">
                       <strong class="text-uppercase">Total Projected Value:</strong>
                       <h3 class="d-inline font-weight-bold">
                           ${{ numeral(totalPaidAmount).format("0,000.00") }}
                       </h3>
                   </div>
                </div>
            </div>
            <div class="d-flex view-list mt-3">
                <a :href="'/bid/request/' + completeBidRequest.BidRequest.id"
                    target="_blank"
                    class="btn d-inline btn-sm btn-primary m-0">
                    <span class="fa fa-info-circle mr-2"></span> 
                    View Listing
                </a>
            </div>
        </div>
        <div v-if="user.role.admin" class="border-2 bg-white rounded p-4 mt-4">
            <h5>More Details</h5>
            <div class="row">
                <div v-if="completeBidRequest.buyer && completeBidRequest.buyer.about" class="col-12 col-md-4">
                    <p>
                        <small class="text-muted d-block">Buyer</small>
                        <strong class="d-block">{{ buyerFullName }}</strong>
                        <strong class="d-block">
                            {{ completeBidRequest.buyer.business.name }}
                        </strong>
                        <strong class="d-block">{{ completeBidRequest.buyer.business.address.address }}</strong>
                        <strong class="d-block">
                            {{ completeBidRequest.buyer.business.address.city }},
                            {{ completeBidRequest.buyer.business.address.state }}
                            {{ completeBidRequest.buyer.business.address.zip }}
                        </strong>
                        <strong class="d-block">
                            {{ completeBidRequest.buyer.about.email }}
                        </strong>
                        <strong class="d-block">
                            {{ completeBidRequest.buyer.about.phone }}
                        </strong>
                    </p>
                </div>
                <div v-if="completeBidRequest.producer && completeBidRequest.producer.about" class="col-12 col-md-4">
                    <p>
                        <small class="text-muted d-block">Producer</small>
                        <strong class="d-block"> {{ producerFullName }}</strong>
                        <strong class="d-block">
                            {{ completeBidRequest.producer.business.name }}
                        </strong>
                        <strong class="d-block">
                            {{ completeBidRequest.producer.business.address.address }}
                        </strong>
                        <strong class="d-block">
                            {{ completeBidRequest.producer.business.address.city }},
                            {{ completeBidRequest.producer.business.address.state }}
                        {{ completeBidRequest.producer.business.address.zip }}
                        </strong>
                        <strong class="d-block">{{ completeBidRequest.producer.about.email }}</strong>
                        <strong class="d-block">{{ completeBidRequest.producer.about.phone }}</strong>
                        
                    </p>
                </div>
                <div v-if="completeBidRequest.BidRequest" class="col-12 col-md-4">
                    <p>
                        <small class="text-muted d-block">Location</small>
                        <strong class="d-block">{{ bidRequestAddress }}</strong>
                        <gmap-map
                            v-if="completeBidRequest.BidRequest.placePosition"
                            :center="completeBidRequest.BidRequest.placePosition"
                            :zoom="12"
                            class="mt-2"
                            style="width:100%;height: 320px;"
                        >
                            <gmap-marker :position="completeBidRequest.BidRequest.placePosition">
                            </gmap-marker>
                        </gmap-map>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapState } from "vuex";

Vue.prototype.moment = moment;


export default{
    props: ["completeBidRequest"],
    computed: {
        ...mapState(["user"]),
        totalAmount: function(){
            const vm = this;
            var total = 0;
            if(vm.completeBidRequest.total != undefined){
                total = (vm.completeBidRequest.total);
            } else {
                total =  (((vm.completeBidRequest.offerPrice) ? vm.completeBidRequest.offerPrice :  vm.completeBidRequest.BidRequest.price)  * vm.completeBidRequest.fullFillAmount)
            }
            return total;
        },
        // ngfGlobalFees: function(){
        //     return this.totalAmount/(100 * 1);   
        // },
        producerFullName: function(){
            const vm = this;
            return  vm.completeBidRequest.producer.about.first +" "+ vm.completeBidRequest.producer.about.last 
        },
        buyerFullName: function(){
            const vm = this;
            return vm.completeBidRequest.buyer.about.first + " " + vm.completeBidRequest.buyer.about.last
        },
        totalPaidAmount: function() {
            const vm = this;
            return vm.totalAmount
        },
        bidRequestAddress: function(){
            const vm = this;
            return (vm.completeBidRequest.BidRequest.location.formatted_address) ? vm.completeBidRequest.BidRequest.location.formatted_address : vm.completeBidRequest.BidRequest.location;
        }
    },
}
</script>