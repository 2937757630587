<template>
    <div v-if="loading">
    </div>
    <time-line v-else>
        <time-line-item  badge-type="info" badge-icon="fa fa-comments" inverted>
            <div slot="header" class="d-flex">
                <span class="badge badge-info">SEND A MESSAGE</span>
            </div>
            <h6 slot="footer">
                <div v-if="uploading" class="row mt-4 mb-4">
                    <div class="col-12 text-center">
                        <b-progress :value="progressUpload" variant="primary" striped :animated="true"></b-progress>
                        <br />
                        <small>Uploading...</small>
                    </div>
                </div>
                <div v-if="showSenderCheckbox && questions.length > 0" class="col-12 remove-checkbox-space-bottom">
                    <b-form-group>
                        <b-form-checkbox-group v-model="messageTo">
                            <b-form-checkbox :value="questions[questions.length - 1].producerID">{{ questions[questions.length - 1].producerUserDetail.first + " " + questions[questions.length - 1].producerUserDetail.last+ `(${ questions[questions.length - 1].producerID.substr(0,10)})` }}</b-form-checkbox>
                            <b-form-checkbox :value="bidRequest.userID">{{  bidRequest.userDetails.first + " " + bidRequest.userDetails.last + `(${bidRequest.userID.substr(0,10)})` }}</b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </div>
                <div class="col-12 pt-2 mb-2">
                    <div class="col-md-5 text-start pt-2">
                        <a @click="selectFile" class="text-muted mt-2 add-pointer" title="Upload File" >
                            <i class="fa fa-upload mr-1"></i> <span class="text-capitalize">Upload File</span>
                        </a>
                    </div>
                </div>
                <div v-if="newMessageUploadedFile.length > 0" class="row mt-4 mb-4 list-group">
                    <div v-for="(file, index) in newMessageUploadedFile" :key="index">
                        <div class="col-12 text-start" style="word-break: break-all;">
                            <a :disabled="deleting" @click="deleteFile(file.data, index)" class="text-danger mr-2 add-pointer">
                            <span class="fa fa-times"></span>
                            </a>
                            <strong><em class="text-muted">{{ file.data.fileNameShow }}</em></strong>
                        </div>
                    </div>
                </div>
                <b-input-group class="mt-0">
                    <b-form-input v-model="newQuestion" placeholder="Type something"></b-form-input>
                    <b-input-group-append>
                        <b-button :disabled="uploading" @click="addQuestion" variant="info" class="fa fa-arrow-up"></b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-alert v-if="error" show variant="danger"><small>{{error}}</small></b-alert>
                <b-alert v-if="fileErrors" show variant="danger"><small>{{fileErrors}}</small></b-alert>

            </h6>
        </time-line-item>
        <input class="d-none" id="upload" type="file"  name="upload" ref="uploadInput" accept="video/*, image/*,application/pdf, .mkv, .webm,.avi, .flv,.mov, .doc, .docx,.txt" :multiple="true" @change="detectFiles($event)"/>
        <time-line-item
            badge-type="info"
            badge-icon="fa fa-comments"
            :inverted="(index % 2 == 1)"
            v-for="(q, index) in questions" :key="q.id"
        >
            <div slot="header" class="d-flex">
                <span class="badge badge-info"> {{ q.fromType.toUpperCase() }} </span>
                <small class="w-100 text-muted text-right"><i class="ti-time"></i> {{formatDate(q.created)}}</small>
            </div>
            <div slot="content">
                <div :class="'bubble p-4 rounded'+(q.from!=user.uid?' bg-info text-white':'')">
                    <strong v-if="q.from != user.uid">{{(q.from==bidRequest.userID? `User ID: ${q.from.substr(0, 10)}` :( q.from==currentUserID && q.from != adminUserID ? `User ID: ${q.from.substr(0, 10)}`:'NGF Global')) }}</strong>
                    <!-- <strong v-if="q.from!=user.uid">{{(q.from==bidRequest.userID?'Buyer' :( q.from==currentUserID && q.from != adminUserID ? 'Farmer':'NGF Global')) }}:</strong> -->
                    <span class="d-block">{{q.text}}</span>
                </div>
                <div v-for="(file) in getUniqueValueFromArray(q.questionAttachment)" :key="file.id">
                    <div class="col-12 text-start" style="word-break: break-all;">
                        <a v-if="user.role.admin" @click="deleteFileRecord(file)" class="text-danger mr-2" title="delete">
                            <span class="fa fa-times add-pointer"></span>
                        </a>
                        <a @click="viewFile(file)" class="text-info mr-2" title="view">
                            <span class="fa fa-external-link text-black add-pointer"></span>
                        </a>
                        <strong>
                            <em class="text-muted" v-if="file.fileNameShow">{{ file.fileNameShow }}</em>
                            <em class="text-muted" v-else>{{ file.name }}</em>
                        </strong>
                    </div>
                </div>
            </div>
            <small slot="footer">
                <div v-if="q.questionAttachment.length > 0">
                    <div v-if="getAttachmentsError.length > 0">
                        <div v-for="(error, index) in getAttachmentsError" :key="index">
                            <div tabindex="-1" v-if="error.messageId == m.id" class="d-block invalid-feedback">
                                {{ error.errorMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </small>
        </time-line-item>
    </time-line>
</template>

<script>
import { TimeLine, TimeLineItem } from "@/components";
import {mapState} from 'vuex';
import firebase from 'firebase';
import {db} from '@/firebase';
import { firestorage } from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import { switchMap, map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import moment from 'moment';


export default {
    props: ['bidRequestID','currentUserID', 'isPriorMessageAvailableOrNot', 'showSenderCheckbox'],
    components: {
        TimeLine,
        TimeLineItem
    },
    computed:{
        ...mapState(['user']),
    },
    data() {
        return {
            uploading: false,
            fileErrors: '',
            newQuestion:null,
            error:null,
            loading:true,
            questions:[],
            messageTo: [],
            bidRequest: null,
            newMessageUploadedFile: [],
            progressUpload: 0,
            getAttachmentsError: [],
            deleting: false,
            isFirstMessageAdmin: false,
            adminUserID: null,
        }
    },
    async created(){
        const vm = this;
        let ref = await db.collection('users').where('business.type','==','admin').limit(1).get();
        if(!ref.empty){
            vm.adminUserID = ref.docs[0].id;
        }
        vm.getQuestions();
        vm.loading = false;
    },
    methods: {
        async getQuestions(){
            const vm = this;
            let ref = await db.collection('bid_request').doc(vm.bidRequestID).get();
            if(ref.exists) vm.bidRequest = {...ref.data(),id:ref.id};
            if(vm.bidRequest){
                ref = await db.collection('users').doc(vm.bidRequest.userID).get();
                if(ref.exists) vm.bidRequest = {...vm.bidRequest, userDetails: ref.data().about }
            }
            collectionData(db.collection('messages').where('is_restore', '==', null).where('entity','==','bidRequest')
            .where('bidRequestID','==',vm.bidRequestID)
            .where('producerID','==',vm.currentUserID).orderBy('created','desc'),'id')
            .pipe(
                switchMap(questions=>{
                    if(questions.length==0) return of([]);
                    return combineLatest(...questions.map(m=>{
                        return docData(db.collection('users').doc(m.producerID)).pipe(
                        map(u=>{
                            return { ...m, producerUserDetail: u.about}
                        })
                        )
                    }))
                })
            ).pipe(
                switchMap(questions=>{
                    if(questions.length==0) return of([]);
                    return combineLatest(...questions.map(m=>{
                        return docData(db.collection('users').doc(m.from)).pipe(
                        map(u=>{
                            let from = '';
                            if (u.business != undefined) {
                                from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? `${m.from.substr(0, 10)}` : (vm.user.uid == m.from ? 'Message' : `${m.from.substr(0,10)}`)))
                                // from = (u.business.type == 'admin' ? 'NGF Global' : (vm.user.role.admin ? u.about.first + ' ' + u.about.last : (vm.user.uid == m.from ? 'me' : (u.business.type == "producer") ? 'Farmer' : u.business.type)))
                            }
                            return { ...m, fromType: from }
                        })
                        )
                    }))
                })
            )
            .subscribe(async(m)=>{
                let questionsMessage = [];
                m.map(async(question) =>{
                    let data = [];
                    if(question.selectedSender !== undefined){
                        data = question.selectedSender.filter((i) =>{
                            return i === (vm.user.role.admin ?'admin' : vm.user.uid );
                        })
                    }
                    if(question.isFirstMessageAdmin != undefined && question.isFirstMessageAdmin == true){
                        vm.isFirstMessageAdmin = true;
                    }
                    let getQuestionAttachment = await vm.getAllQuestionAttachments(question.id);
                    if(data.length > 0){
                        if(!vm.user.role.admin){
                            if(!question.is_hide || question.is_hide == undefined){
                                questionsMessage.push({ ...question, questionAttachment: getQuestionAttachment })
                            } 
                        } else {
                            questionsMessage.push({ ...question, questionAttachment: getQuestionAttachment })
                        }
                    } else if(question.selectedSender === undefined) {
                        if(!vm.user.role.admin){
                            if(!question.is_hide || question.is_hide == undefined){
                                questionsMessage.push({ ...question, questionAttachment: getQuestionAttachment })
                            } 
                        } else {
                            questionsMessage.push({ ...question, questionAttachment: getQuestionAttachment })
                        }
                    }
                });
                vm.questions=questionsMessage;
                let unread = vm.questions.filter(q=>q.from!=vm.user.uid&&q.unread);
                if(unread.length>0){
                    let batch = db.batch();
                    unread.map(u=>batch.update(db.collection('messages').doc(u.id),{unread:false}));
                    batch.commit();
                }
            });
        },
        async getAllQuestionAttachments(messageId) {
            let getAttachment = [];
            if (messageId != '' && messageId != undefined) {
                let getAttachmentRef = await db.collection('message_attachments');
                getAttachmentRef = getAttachmentRef.where('messageId', '==', messageId);
                collectionData(getAttachmentRef, 'id').subscribe((res) => {
                    getAttachment.push(...res);
                });
                return getAttachment;
            }
        },
        selectFile(){
            this.fileErrors = '';
            this.$refs.uploadInput.click();
        },
        upload(file, currentTimeStamp){
            const vm = this;
            vm.uploading = true;
            vm.saving = true;
            let getFileName = file.name.split('.').slice(0, -1).join('.');
            let getFileExtension = file.name.split('.').pop();
            let fileName = vm.user.uid + '_'+ currentTimeStamp  + '_' + getFileName + '.' + getFileExtension;
            let uploadMessageFile = firestorage.ref('message_attachments/bid_request/' + vm.bidRequestID +'/'+  vm.user.uid + '/' + fileName).put(file);
                uploadMessageFile.on('state_changed', sp => {
                    vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
                }, 
                null,
                async () => { 
                    let data = await uploadMessageFile.snapshot.ref.getMetadata();
                    for(let key in data){
                    if(data[key] === undefined) delete data[key];
                    }
                    vm.uploading = false;
                    vm.saving = false;
                    data['fileNameShow'] = file.name;
                    vm.newMessageUploadedFile.push({...vm.newMessageUploadedFile, data});
                });
        },
        detectFiles(e){
            const vm = this;
            let fileList = e.target.files;
            let fileAccept = ['application/pdf', 'application/msword', 'video/x-flv','video/quicktime','video/mp4', 'image/jpeg', 'image/png', 'image/gif', 'video/webm' ,'video/x-matroska','video/x-msvideo','application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']; 
            let  timestamp = Math.floor(Date.now() / 1000);
            if(fileList.length > 0){
                Array.from(Array(fileList.length).keys()).map((x) => {
                    if(fileAccept.includes(fileList[x].type)){
                        timestamp += 100;
                        vm.upload(fileList[x], timestamp);
                    } else {
                        let errorMessage = 'Please select valid document file. file will be accept this extension (.PDF, .TXT and .DOC/.DOCX.)';
                        vm.fileErrors = errorMessage;
                        setTimeout(() => {
                        vm.fileErrors = '';
                        }, 6000); 
                        return false; 
                    }
                });
            }
            vm.$refs.uploadInput.value = "";
        },
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        async addQuestion(){
            const vm = this;
            vm.error=null;

            if(vm.user.role.admin && vm.messageTo.length <= 0 && vm.showSenderCheckbox){
                vm.error ="Please select checkbox for sender.";
                setTimeout(() => {
                    vm.error = '';
                }, 10000); 
                return;
            }

            if(!vm.newQuestion){
                vm.error="Please type your message.";
                setTimeout(() => {
                    vm.error = '';
                }, 10000); 
                return;
            }
            if(!vm.bidRequest.active){
                vm.error="The listing of this product Bid request has been deactivated. The message cannot be sent.";
                vm.newQuestion = null;
                if(vm.newMessageUploadedFile.length > 0){
                    Array.from(Array(vm.newMessageUploadedFile.length).keys()).map((x) => {
                        let attachmentRef = firestorage.ref(vm.newMessageUploadedFile[x].data.fullPath);
                        attachmentRef.delete();
                    });
                }
                vm.newMessageUploadedFile = [];
                setTimeout(() => {
                    vm.error = '';
                }, 10000);
                return;
            }

            let senderData = [];
            if(vm.user.role.admin && vm.messageTo.length > 0){
                vm.messageTo.forEach((t) =>{
                    senderData.push(t)
                })
                senderData.push('admin');
            } else{
                if(vm.isPriorMessageAvailableOrNot || vm.isFirstMessageAdmin === true){
                    senderData.push(vm.bidRequest.userID);
                    senderData.push('admin');
                } else {
                    senderData.push((vm.bidRequest.userID !== vm.user.uid && !vm.user.role.admin) ? vm.bidRequest.userID : vm.currentUserID);
                    senderData.push('admin');    
                    senderData.push(vm.user.uid);
                }
            }

            let data = {
                unread:true,
                from:vm.user.uid,
                to: (vm.bidRequest.userID !== vm.user.uid && !vm.user.role.admin) ? vm.bidRequest.userID : vm.currentUserID,
                bidRequestID:vm.bidRequestID,
                text:vm.newQuestion,
                entity:'bidRequest',
                excludeAdmin:true,
                created:firebase.firestore.FieldValue.serverTimestamp(),
                is_restore: null
            }
            data.producerID = vm.currentUserID
            data.selectedSender = senderData;
            if(vm.isPriorMessageAvailableOrNot || vm.isFirstMessageAdmin === true){
                data.to = vm.bidRequest.userID;
                data.isFirstMessageAdmin = true;
            }
            vm.addNewQuestion(data).then(async(res) => {
                await vm.newMessageAttachmentsInsert(res.id);
            });
            if(vm.isPriorMessageAvailableOrNot){
                vm.$emit('reloadQuestions')
            }
            vm.newQuestion=null;
            vm.messageTo = [];
        },
        async addNewQuestion(data){
            let ref = await db.collection('messages').doc();
            ref.set(data);
            return ref;
        },
        async deleteFile(file, fileIndex){ 
            const vm = this;
            if(confirm("Are you sure you want to delete this file?")){
                vm.deleting=true;
                let storageRef = firestorage.ref(file.fullPath);
                await storageRef.delete();
                vm.newMessageUploadedFile.splice(fileIndex, 1);
                vm.deleting=false;
            }
        },
        async viewFile(file){
            const vm = this;
            vm.getAttachmentsError = [];
            if(file != '' && file != undefined){
                let attachmentRef = await firestorage.ref(file.fullPath);
                attachmentRef.getDownloadURL().then((url) => {
                    window.open(url,'_blank');
                }).catch(() => {
                        let errorMessage = 'Please refresh the Page';
                        vm.getAttachmentsError.push({errorMessage, messageId: file.messageId});
                        setTimeout(() => {
                        vm.getAttachmentsError = [];
                        }, 5000); 
                        return false; 
                });
            }
        },
        async newMessageAttachmentsInsert(questionId){
            const vm = this;
            if(questionId != '' &&  vm.newMessageUploadedFile.length > 0){
                Array.from(Array(vm.newMessageUploadedFile.length).keys()).map((x) => {
                    let newMessageUploadedFile = {...vm.newMessageUploadedFile[x].data,messageId:questionId};
                        db.collection('message_attachments').add(newMessageUploadedFile).then(() => {
                    });
                });
            }
            vm.newMessageUploadedFile = [];
        },
        getUniqueValueFromArray(questionAttachment){      
            return [
                ...new Map(
                    questionAttachment.map((item) => [item['id'], item])
                ).values()
            ]
        },
        beforeDestroy(){
            const vm = this;
           vm.getQuestions();
        }
    }
}
</script>
<style>
.remove-checkbox-space-bottom .custom-checkbox .custom-control-label::before {
  top: unset;
}
.remove-checkbox-space-bottom .custom-checkbox .custom-control-label::after {
  top: 0rem;
}
</style>