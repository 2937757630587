<template>
  <div class="row">
    <div class="col"></div>
    <div class="col-lg-10">
      <card class="card-signup text-center" no-footer-line :cardBodyClasses="'p-0'">
        <template v-if="complete">
          <h1 class="text-center" v-if="subscriptions.active&&user.business&&user.business.type=='buyer'">7 Day Free Trial</h1>
          <h1 class="text-center" v-else>Congratulations!</h1>
          <p class="text-center" v-if="subscriptions.active&&user.business&&user.business.type=='buyer'">You've successfully created an account and can use the NGF Marketplace for 7 days on us. Enjoy!</p>
          <p class="text-center" v-else>Thank you for signing up.</p>
          <p class="text-center"><a href="/" class="btn btn-primary">Continue</a></p>
        </template>
        <template v-else>
          <simple-wizard>
            <template slot="header">
              <img slot="header" src="/img/logo.png" class="img-fluid" />
              <h3 class="card-title text-white">Build your profile</h3>
              <p class="text-white m-0 mb-4">If you already have an account, <a href="/login" class="text-white"><u>log in here</u></a>.</p>
            </template>
            <wizard-tab :before-change="() => validateStep('registerAbout')">
              <template slot="label">
                <i class="fa fa-user-circle"></i>
                <span class="d-none d-md-inline">About</span>
              </template>
              <RegisterAbout ref="registerAbout" @on-validated="onStepValidated" />
            </wizard-tab>
            <wizard-tab :before-change="() => validateStep('registerBusinessInfo')">
              <template slot="label">
                <i class="fa fa-briefcase"></i>
                <span class="d-none d-md-inline">Business Info</span>
              </template>
              <RegisterBusinessInfo ref="registerBusinessInfo" @on-validated="onStepValidated" />
            </wizard-tab>
            <wizard-tab :before-change="() => validateStep('registerAccount')">
              <template slot="label">
                <i class="fa fa-cog"></i>
                <span class="d-none d-md-inline">Account</span>
              </template>
              <RegisterAccount ref="registerAccount" @on-validated="onStepValidated" />
            </wizard-tab>
          </simple-wizard>
        </template>
      </card>
    </div>
    <div class="col"></div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {db,auth} from '@/firebase';
import RegisterAbout from "./Register/About.vue";
import RegisterBusinessInfo from "./Register/BusinessInfo.vue";
import RegisterAccount from "./Register/Account.vue";
import { SimpleWizard, WizardTab } from "@/components";
export default {
  components: {
    SimpleWizard,
    WizardTab,
    RegisterAbout,
    RegisterBusinessInfo,
    RegisterAccount
  },
  data() {
    return {
      complete:false,
      signup:{
        about:null,
        business:null,
        account:null,
        terms:"accepted",
        emailNotification: true,
      }
    };
  },
  computed:{
    ...mapState(['user','subscriptions'])
  },
  methods: {
    async onStepValidated(model){
      let vm = this;
      vm.signup={...vm.signup,...model};
      if(vm.signup.about&&vm.signup.business&&vm.signup.account){
        return auth.createUserWithEmailAndPassword(vm.signup.about.email, vm.signup.account.password).then(
          (response) => {
            if(response.user.uid){
              let userData = {
                about:{...vm.signup.about},
                business:{...vm.signup.business},
                terms: vm.signup.terms
              };
              db.collection("users").doc(response.user.uid).set(userData).then(function(){
                vm.complete=true;
              }).catch(function(error) {
                console.log(error);
              });
            }

          },
          (err) => {
            console.log(err);
          }
        );
      }
    },
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    async load() {
      if(this.user.uid){
        this.$router.replace('/');
      }
    }
  },
  mounted(){
    this.load();
  },
  watch: {
    user () {
      this.load();
    }
  }
};
</script>
<style>
.card-signup .card-body{
  padding-bottom: 60px;
}

.registerHeader{
  width: 250px;
  margin-top: 15px;
}

</style>