<template>
  <li>
    <a
      class="nav-link sidebar-menu-item"
      @click="toggle"
      :aria-expanded="checked"
      v-if="!hidden"
      :disabled="working"
    >
      <i :class="'fa ' + (working?'fa-spinner':(checked?'fa-check-square-o':'fa-square-o'))"></i>
      {{filter.name}}
    </a>

    <collapse-transition>
      <div v-if="checked&&children&&children.length>0" v-show="checked">
        <ul class="nav links__nav">
          <sidebar-filter v-for="filter in children" :key="filter.name" :filter="filter" />
        </ul>
      </div>
    </collapse-transition>

  </li>
</template>
<script>
import store from '@/vuex/store.js';
import {db} from '@/firebase';
import {bus} from '@/vuex/bus.js';
import { CollapseTransition } from "vue2-transitions";
export default {
  name: "sidebar-filter",
  components: {
    CollapseTransition
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          id: "",
          name: "",
          checked: false,
          hidden: false,
          children: []
        };
      }
    }
  },
  data() {
    return {
      working:false,
      checked:false,
      collapsed: true
    };
  },
  computed: {
    hidden(){
      return this.filter.hidden;
    },
    children(){
      return this.filter.children;
    }
  },
  methods: {
    async toggle(){
      let vm = this;
      vm.working=true;
      vm.checked=!vm.checked;
      if(vm.filter.type=='commodity'){
        if(vm.checked){
          let filters = store.state.filters;

          let ref = await db.collection('commodity_subtypes').where('type','==',vm.filter.id).orderBy('name','asc').get();
          vm.filter.children=[];
          ref.forEach(t=>vm.filter.children.push({...t.data(),type:'subtype',id:t.id,checked:false,hidden:false}));

          filters['subtype']=vm.filter.children;
          store.commit('updateFilters',filters);

        }
      }
      bus.$emit('filterChecked',{...vm.filter,checked:vm.checked});
      vm.working=false;
    }
  },
  mounted() {
    this.checked=this.filter.checked;
  },
  destroyed() {
  }
};
</script>
<style>
</style>
