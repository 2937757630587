<template>
    <div>
        <div class="container">
            <div class="d-flex justify-content-end">
                <b-button variant="danger" :disabled="disabledDeletedButton" @click="restoreMultipleListing">Restores</b-button>
            </div>
            <div>
                <div v-if="loading">
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </div>
                <div id="productGrid" ref="productGrid"></div>
            </div>

        </div>
       
        <div>
            <b-modal ref="model" size="lg" v-model="confirmationPopup">
                <h2>
                    Are you sure you want to restore this Product?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreProduct">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>

        <div>
            <b-modal ref="model" size="lg" v-model="multipleListingConfirmationPopup">
                <h2>
                    Are you sure you want to restore this Products?
                </h2>
                <template #modal-footer="{ok,cancel}">
                    <b-button size="xl" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button size="xl" variant="success" @click="confirmRestoreMultipleProduct">
                        Continue
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
    

</template>

<script>
import { mapState } from 'vuex'
import { db } from '@/firebase';
import { docData } from 'rxfire/firestore';
import { catchError, map, switchMap} from 'rxjs/operators';
import { of, from, combineLatest } from 'rxjs';
import { Grid, html } from 'gridjs';
import 'gridjs/dist/theme/mermaid.css';
import moment from 'moment';


export default {
    name: 'AllProductDeletedList',
    computed: {
        ...mapState(['user'])
    },
    data(){
        return {
            deleteProductList: [],
            confirmationPopup: false,
            gridInstance: null,
            loading: false,
            productID: null,
            disabledDeletedButton: true,
            selectedProduct: [],
            multipleListingConfirmationPopup: false
        }
    },
    created(){
        const vm = this;
        if(!vm.user.role.admin){
            vm.$router.replace("/not/access")
        }
    },
    async mounted() {
       const vm = this;
       await vm.getDeleteAllProductList();
    },


    methods:{
        /**
         * Get retrieved list of Deleting product
         */

        clearGrid(){
            const vm = this;
            if(vm.gridInstance){
                vm.gridInstance.destroy();
                vm.gridInstance = null;
            }
            vm.$refs.productGrid.innerHTML = '';
        },


        initializeGrid(data){
            const vm  = this;
            const showPagination = data.length > 10;
            vm.gridInstance = new Grid({
                    columns: [
                        {
                            id: 'checkbox',
                            name: '',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 50px;' // Set width inline
                            }
                        },
                        {
                            name: '#',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 60px; text-align:center' // Set width inline
                            }
                        },
                        {
                            name: 'Product Name',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 220px;' // Set width inline
                            }
                        },
                        {
                            name: 'Created By',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 220px;' // Set width inline
                            }
                        },
                        {
                            name: 'Created Date',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 150px;' // Set width inline
                            }
                        },
                        {
                            name: 'Deleted Date',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 150px;' // Set width inline
                            }
                        },
                        {
                            name:'Action',
                            formatter: (cell) => html(cell),
                            attributes: {
                                style: 'width: 100px;' // Set width inline
                            }
                        }
                    ],
                    pagination: showPagination ? { limit: 10} : false,
                    search: false,
                    data:  data.map((data, rowIndex) => {
                        return [`<input type="checkbox" id="checkbox-${rowIndex}" value="${data.id}" class="row-checkbox" />`, rowIndex + 1, data.subtype + " " + data.type, (data.user !== null) ? data.user.about.first + " " + data.user.about.last :'N/A' , moment(data.created.toDate()).format('ll'), (data.deleted != undefined) ? moment(data.deleted.toDate()).format('ll') : '-',`  <button class="btn btn-sm btn-primary m-0" onclick="showProductConfirmationPopup('${data.id}')">
                            <i class="fa fa-clipboard mr-2"></i> Restore</button>`
                        ]
                    })
                }).render(vm.$refs.productGrid)
                vm.loading = false;

                document.getElementById('productGrid').addEventListener('change', (event) => {
                    if(event.target.type === 'checkbox'){
                        vm.updateSelectedIds();
                    }
                });

        },

        async getDeleteAllProductList() {
            const vm = this;
            let ref = db.collection('commodities').where('is_delete','==', 1).orderBy('created', 'desc');
            from(ref.get()).pipe(
                 switchMap(commodities=>{
                if(commodities.empty) return of([]);
                    return combineLatest(...commodities.docs.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.data().type)).pipe(
                            map(t=>{
                                return {...c.data(),type:t.name, typeId: c.data().type}
                            })
                        )
                    }))
                })
            ).pipe( switchMap(commodities=>{
                    if(commodities.length==0) return of([]);
                    return combineLatest(...commodities.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                        map(t=>{
                            return {...c,subtype:t.name, subtypeId: c.subtype}
                        })
                        )
                    }))
                })
            ).pipe(
                switchMap(commodities=>{
                    if(commodities.length==0) return of([]);
                    return combineLatest(...commodities.map(c=>{
                        return docData(db.collection('users').doc(c.userID)).pipe(
                            map(u => ({
                                ...c,
                                user: u
                            })),
                            catchError(() => {
                                console.log(c.userID)
                                // Handle the case where the user does not exist
                                return of(null);
                            })
                        )
                    }))
                })
            ).pipe(
                map((results) =>{
                    return results.filter(result => result.user.about != undefined)
                })
            )
            .subscribe((data) => {
                vm.disabledDeletedButton = true;
                vm.initializeGrid(data);
                window.showProductConfirmationPopup = vm.showProductConfirmationPopup.bind(this);
            })
        },

        async showProductConfirmationPopup(item) {
            const vm =this;
            vm.confirmationPopup = true;
            vm.productID = item;
            console.log(item)
        },

    
        async updateSelectedIds(){
            const vm = this;
            const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked')
            vm.selectedProduct = Array.from(checkboxes).map(item => item.value)
            if(vm.selectedProduct.length > 0){
                vm.disabledDeletedButton = false;
            } else {
                vm.disabledDeletedButton = true;
            }
        },

        async confirmRestoreProduct(){
            const vm = this;
            await db.collection('commodities').doc(vm.productID).update({
                is_delete:0
            }).then(async () => {
                vm.confirmationPopup = false;
                vm.productID = null;
                vm.confirmationPopup = false;
                vm.loading = true;
                await vm.clearGrid();
                setTimeout(async() => {
                    await vm.getDeleteAllProductList(); 
                }, 2000)
               
            }).catch((e) =>{
                console.log('error' + e);
            })

        },

        async confirmRestoreMultipleProduct(){
            const vm = this;
            try{
                vm.multipleListingConfirmationPopup = false;

                vm.loading = true;

                await Promise.all(vm.selectedProduct.map(async (item) => {
                    await db.collection('commodities').doc(item).update({
                        is_delete:0
                    })
                }))
             
                await vm.clearGrid()

                await new Promise(resolve => setTimeout(resolve, 2000));

                vm.selectedProduct = [];

                await vm.getDeleteAllProductList();
                
            } catch(error) {
                console.error('Error during restore operation:', error);
            }   
           
        },

        async restoreMultipleListing(){
            const vm = this;
            vm.multipleListingConfirmationPopup = true;
        }

    }


}
</script>