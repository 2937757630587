<template>
    <div class="container">
        <div class="row">
            <div class="col-12 p-0 ">
                <b-card class="rounded border-2">
                    <b-card-header>
                        <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                            <h5 class="m-2 text-white">All Users</h5>
                            <div>
                                <b-input-group class=" mb-3 mb-md-0">
                                    <template #append>
                                        <b-input-group-text><i class="fa fa-search"></i></b-input-group-text>
                                    </template>
                                    <b-form-input type="text" class="border-white bg-white" v-model.trim="searchQuery"
                                        placeholder="Search"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                    </b-card-header>
                    <b-card-body class="p-0">
                        <b-list-group>
                            <template v-if="filteredList.length != 0">
                                <b-list-group-item @click="openUserDetails(user)" v-for="(user) in filteredList"
                                    :key="user.id"
                                    class="d-block d-md-flex align-items-center justify-content-between text-md-left">
                                    <div class="text-md-left pl-0 pr-0 pr-md-4">
                                        <div v-if="(user.about)">
                                            <strong>{{ user.about.first + " " + user.about.last }}</strong>
                                            <div class=" text-muted" v-if="user.business">{{ user.business.type }}</div>
                                            <div class="buss-name-width text-muted" v-if="user.business">{{
                                                user.business.name }}</div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class="">
                                            <div v-if="user.about.is_active == undefined">
                                                <button size="sm" @click.stop="updateUsersStatus(true, user)" type='button'
                                                    :class="'btn btn-' + (false ? 'primary' : 'outline-primary')"><i
                                                        :class="'fa fa-' + (false ? 'toggle-on' : 'toggle-off') + ' mr-2'"></i>
                                                    ACTIVE</button>
                                                <b-button variant="danger" class="ml-2"
                                                    @click.stop="deleteUser(user)">Delete</b-button>
                                            </div>
                                            <div v-else>
                                                <button size="sm"
                                                    @click.stop="updateUsersStatus(!user.about.is_active, user)"
                                                    type='button'
                                                    :class="'btn btn-' + (user.about.is_active ? 'primary' : 'outline-primary')"><i
                                                        :class="'fa fa-' + (user.about.is_active ? 'toggle-on' : 'toggle-off') + ' mr-2'"></i>
                                                    ACTIVE</button>
                                                <b-button variant="danger" class="ml-2"
                                                    @click.stop="deleteUser(user)">Delete</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group-item>
                            </template>
                            <template v-else>
                                <b-list-group-item>
                                    <p class="text-muted m-0">No User Found</p>
                                </b-list-group-item>
                            </template>
                        </b-list-group>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>

import { db } from '@/firebase';
import { collectionData } from 'rxfire/firestore';
import {mapState} from 'vuex'



export default {
    data(){
        return{
            users: [],
            active: false,
            searchQuery: '',
        }
    }, 
    mounted(){
        if(!this.user.role.admin){
            this.$router.replace("/not/access")
        }
        this.getUsers();
    },
    computed: {
        ...mapState(['user']),
        filteredList(){
            return this.users.filter((user) => {
                if(user.about){
                    return (user.about.first != undefined && user.about.first.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1) 
                                || 
                            (user.about.last != undefined && user.about.last.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1)
                                ||
                            (user.business.name != undefined && user.business.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1)
                }
            });
        }
    },

    methods: {
        async getUsers(){
            let vm = this;
            let ref = await db.collection("users")
            collectionData(ref, 'id').subscribe((users) => {
                vm.users = users;
            });
        }, 
        async updateUsersStatus(status, user){
            let userStatus = "Active";
            if(!status) userStatus ="De-Active";           
            if(confirm('Are you sure want to this User ' + userStatus +'?')){
                await db.collection("users").doc(user.id)
                .update({"about.is_active": status}).then(() => {
                    this.getUsers();
                });
            }
        }, 
        async deleteUser(user){
            if(confirm('Are you sure want to this Delete User ?')){
                await db.collection("users").doc(user.id).delete().then(() => {
                    this.getUsers();
                });
            }
        },
        openUserDetails(user){
            this.$router.push('/user/view/' + user.id);
        }
    },
}
</script>
<style>@media screen and (max-width:767px) {
    .buss-name-width {
        width: 260px;
        max-width: 100%;
    }
    
}</style>