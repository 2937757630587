<template>
  <div>
    <div class="border-2 bg-white rounded p-4">
      <div class="d-block d-md-flex">
        <div class="flex-fill text-center text-md-left text-muted" v-if="order.producerID">
          Owner ID: {{ order.producerID.substr(0, 10).toUpperCase() }}
        </div>
        <div
          class="flex-fill text-center text-md-right text-success font-weight-bold"
        >
          {{ moment(order.created).format("ll") }}
        </div>
      </div>
      <h2 class="mt-2 mb-2 d-none d-md-block">
        Trans. ID: {{ order.id.toUpperCase() }}
      </h2>
      <p class="mt-2 mb-2 d-block d-md-none text-center">
        <span class="text-muted">Trans. ID:</span><br /><strong>{{ order.id.toUpperCase() }}</strong>
      </p>
      <div class="d-block d-md-flex">
        <div class="flex-fill text-center text-md-left pt-3">
          <h6 class="d-inline">
            Purchase of {{ numeral(order.amount).format("0,000") }}
            {{ order.commodity.unit }} {{ order.commodity.subtype }}
            {{ order.commodity.type }}
          </h6>

        </div>
        <div class="flex-fill text-center text-md-right">
          <strong>Total: </strong>
          <h3 class="d-inline font-weight-bold">
            ${{ numeral(order.total).format("0,000.00") }}
          </h3>
        </div>
      </div>
      <hr />
      <div class="mt-3">
          <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between">
              <div class="">
                <h6>Payment Negotiation Option</h6>
                <p class="mb-0">
                  {{
                      order.paymentOption === "100_at_purchase"
                        ? "100% at time of purchase".toUpperCase()
                        : order.paymentOption === "100_at_delivery"
                        ? "100% at time of delivery".toUpperCase()
                        : order.paymentOption === "50_at_purchase"
                        ? "Buyer and Seller Negotiated Payment Split".toUpperCase()
                        : "Please Contact Customer Support for Payment Options"
                  }}
                </p>
              </div>
              <div class="">
                <b-badge variant="success" pill>Approved</b-badge>
              </div>
          </div>
      </div>
      <hr />
      <div class="d-flex flex-wrap gap-2 justify-content-center justify-content-md-start">
        <a
          :href="'/commodity/view/' + order.commodityID"
          target="_blank"
          class="btn d-inline mb-0 btn-sm btn-primary"
          ><span class="fa fa-info-circle mr-2"></span>View Product Details</a
        >
        <a
          v-if="user.role.admin"
          :href="'/search/purchase/order/' + order.id"
          target="_blank"
          class="btn d-inline mb-0 ml-md-2 btn-sm btn-info"
          ><span class="fa fa-info-circle mr-2"></span> View Purchase Details</a
        >
      </div>
      <div class="d-block mt-1"></div>
    </div>
    <div v-if="user.role.admin" class="border-2 bg-white rounded p-4 mt-4">
      <h5>More Details</h5>
      <div class="row">
        <div
          v-if="order.producer && order.producer.about"
          class="col-12 col-md-4"
        >
          <p>
            <small class="text-muted d-block">Farmer</small>
            <strong class="d-block"
              >{{ order.producer.about.first }}
              {{ order.producer.about.last }}</strong
            >
            <strong class="d-block">{{ order.producer.business.name }}</strong>
            <strong class="d-block">{{
              order.producer.business.address.address
            }}</strong>
            <strong class="d-block"
              >{{ order.producer.business.address.city }},
              {{ order.producer.business.address.state }}
              {{ order.producer.business.address.zip }}</strong
            >
            <strong class="d-block">{{ order.producer.about.email }}</strong>
            <strong class="d-block">{{ order.producer.about.phone }}</strong>
          </p>
        </div>
        <div v-if="order.buyer && order.buyer.about" class="col-12 col-md-4">
          <p>
            <small class="text-muted d-block">Buyer</small>
            <strong class="d-block"
              >{{ order.buyer.about.first }}
              {{ order.buyer.about.last }}</strong
            >
            <strong class="d-block">{{ order.buyer.business.name }}</strong>
            <strong class="d-block">{{
              order.buyer.business.address.address
            }}</strong>
            <strong class="d-block"
              >{{ order.buyer.business.address.city }},
              {{ order.buyer.business.address.state }}
              {{ order.buyer.business.address.zip }}</strong
            >
            <strong class="d-block">{{ order.buyer.about.email }}</strong>
            <strong class="d-block">{{ order.buyer.about.phone }}</strong>
            <b-button
              variant="info"
              pill
              @click="viewBuyerDetails(order.buyerID)"
              >View Buyer Details</b-button
            >
          </p>
        </div>
        <div v-if="order.commodity.location" class="col-12 col-md-4">
          <p>
            <small class="text-muted d-block">Location</small>
            <strong class="d-block">{{
              order.commodity.location.formatted_address
                ? order.commodity.location.formatted_address
                : order.commodity.location
            }}</strong>
            <gmap-map
              v-if="order.commodity.location.position"
              :center="order.commodity.location.position"
              :zoom="12"
              class="mt-2"
              style="width:100%;height: 320px;"
            >
              <gmap-marker
                :position="order.commodity.location.position"
              ></gmap-marker>
            </gmap-map>
            <a
              v-if="order.commodity.location.url"
              :href="order.commodity.location.url"
              target="_blank"
              class="d-block mt-2"
              ><strong
                ><span class="fa fa-external-link"></span> View Full Map</strong
              ></a
            >
          </p>
        </div>
      </div>
      <div v-if="order.services.length > 0" class="row">
        <div class="col-12 pt-2"><hr /></div>
      </div>
      <p>
        <b-badge
          variant="info"
          pill
          class="mr-2"
          :key="service.id"
          v-for="service in order.services"
          >{{ service.name }}</b-badge
        >
      </p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import numeral from "numeral";
import { mapState } from "vuex";
Vue.prototype.numeral = numeral;
Vue.prototype.moment = moment;
export default {
  props: ["order"],
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    viewBuyerDetails(buyerID) {
      this.$router.replace("/search/buyer/" + buyerID);
    },
  },
};
</script>
