import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  timeout:{
    time:1000
  },
  subscriptions:{
    active:false
  },

  user: {
    role:{
      producer:false,
      buyer:false,
      admin:false
    }
  },

  filters: {
    category:[],
    commodity:[],
    subtype:[],
    certification:[]
  },

  productionContractFilters: {
    category:[],
    commodity:[],
    subtype:[],
    specification:[]
  },

  bidRequestFilters: {
    category:[],
    commodity:[],
    subtype:[],
    specification:[]
  }

};

const mutations = {

  updateUser (state, user) {
    Vue.set(state, 'user', user)
  },

  updateFilters (state, filters) {
    Vue.set(state, 'filters', filters)
  },

  updateProductionContractFilters (state, productionContractFilters) {
    Vue.set(state, 'productionContractFilters', productionContractFilters)
  },

  updateBidRequestFilters(state, bidRequestFilters){
    Vue.set(state, 'bidRequestFilters', bidRequestFilters)
  },

  updateSubscriptions (state, subscriptions) {
    Vue.set(state, 'subscriptions', subscriptions)
  },

  updateTimeout (state, timeout) {
    Vue.set(state, 'timeout', timeout)
  },

  updateLimitOfMiles (state, limitOfMiles){
    Vue.set(state, 'limitOfMiles', limitOfMiles)
  }


}


export default new Vuex.Store({
  state,
  mutations,
});
