<template>
  <div class="container">
    <div class="row">
      <div class="col-12">

        <b-tabs class="custom_setting" v-model="tabIndex" pills card fill>
          <b-tab v-for="tab in tabs" :title="tab.name" :key="'tab'+tab.id">
            <b-card-text>
              <div v-if="tabIndex==0">
                <b-card no-body >
                  <b-tabs pills card vertical class="side_menu">
                      <b-tab title="Certifications">
                        <b-card-text><ListBuilder table="certifications" @backToTop="backToTop" /></b-card-text>
                      </b-tab>
                    <b-tab title="Units of Measure">
                      <b-card-text><Units /></b-card-text>
                    </b-tab>
                    <b-tab title="Crop Year">
                        <b-card-text>
                            <ListBuilderCropYear @backToTop="backToTop" />
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Specifications">
                        <b-card-text>
                            <ListBuilderSpecification @backToTop="backToTop" />
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Price Options">
                        <b-card-text>
                            <ListBuilderPriceOptions @backToTop="backToTop" />
                        </b-card-text>
                    </b-tab>
                    <b-tab title="BID Price Options">
                      <b-card-text>
                            <ListBuilderBidPriceOptions @backToTop="backToTop" />
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Other Settings">
                      <b-card-text class="text-center">
                        <div class="mt-4">
                          <div class="d-flex flex-rot justify-content-between">
                            <ValidationProvider
                              name="limitMiles"
                              :rules="{required:true, decimal:0,minimum:10}"
                              v-slot="{ passed, failed, errors }"
                            >
                              <b-form-group
                                :state="(failed?false:(passed?true:null))"  
                                label="Set search limit of miles"
                              > 
                                <b-form-input
                                  type="number"
                                  v-model.number="limitMiles"
                                  size="sm"
                                  style="max-width: 25rem;"
                                  @change="searchLimitChanged"
                                >                                  
                                </b-form-input>
                              </b-form-group>
                                <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                  {{ errors[0]=='Minimum'?'Enter minmum 10 miles':'Enter valid search limit of miles'}}
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="mt-4 text-left">
                          <b-form-group
                            label="Does NGF require subscriptions?"
                          >
                            <b-form-radio-group
                              id="subscriptions"
                              v-model="subscriptions"
                              :options="subscriptionOptions"
                              button-variant="outline-primary"
                              size="lg"
                              name="subscriptions"
                              buttons
                              @change="subscriptionsChanged"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                        <div class="mt-4 text-left">
                          <b-form-group
                            label="Does NGF require invoices?"
                          >
                            <b-form-radio-group
                              id="invoices"
                              v-model="invoices"
                              :options="invoicesOptions"
                              button-variant="outline-primary"
                              size="lg"
                              name="invoices"
                              buttons
                              @change="invoicesChanged"
                            ></b-form-radio-group>
                          </b-form-group>
                        </div>
                        <div class="mt-4">
                          <div  class="d-flex flex-row justify-content-between">
                            <b-form-group
                              label="Set loading timeout ( 1 sec = 1000 )"
                            >
                              <b-form-input
                                v-model="time"
                                size="sm"
                                style="max-width: 25rem;"
                                @change="timeoutChanged"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
              <div v-else-if="tabIndex==1">
                <b-card no-body>
                  <b-tabs pills card vertical class="side_menu" v-model="subtabIndex">
                    <b-tab v-for="subtab in subtabs" :title="subtab.name" :key="'subtab'+subtab.id">
                      <b-card-text>
                        <div class="container">
                          <div class="row">
                            <div class="col-12">
                              <h4>{{subtab.name}}</h4>
                              <template v-if="subtab.id=='categories'">
                                <ListBuilder table="commodity_categories" @backToTop="backToTop" />
                              </template>
                              <template v-else>
                                <div class="border-dotted p-4 rounded">
                                  <b-form-group
                                    label="Category"
                                  >
                                    <b-form-select @change="categoryChange" v-model="selectedCategory" :options="categories"></b-form-select>
                                  </b-form-group>
                                  <b-form-group
                                    label="Product Type"
                                     v-if="subtab.id=='commodity_subtypes'"
                                  >
                                    <b-form-select :disabled="!selectedCategory" v-model="selectedCommodityType" :options="commodity_types"></b-form-select>
                                  </b-form-group>
                                </div>
                                <ListBuilder v-if="subtab.id=='commodity_types'&&selectedCategory" :category="selectedCategory" table="commodity_types" class="mt-4" @backToTop="backToTop" />
                                <ListBuilder v-if="subtab.id=='commodity_subtypes'&&selectedCategory&&selectedCommodityType" :type="selectedCommodityType" table="commodity_subtypes" class="mt-4" @backToTop="backToTop" />
                              </template>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
              <div v-if="tabIndex==2">
                <b-card no-body>
                  <b-tabs pills card vertical class="side_menu">
                    <b-tab title="Producers">
                      <b-card-text><ListBuilder table="producers" @backToTop="backToTop" /></b-card-text>
                    </b-tab>
                    <b-tab title="Services">
                      <b-card-text><Descriptors table="services" /></b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {db} from '@/firebase';
import ListBuilder from "@/components/ListBuilder.vue";
import ListBuilderCropYear from '../../components/ListBuilderCropYear.vue';
import ListBuilderSpecification from '../../components/ListBuilderSpecification.vue';
import ListBuilderPriceOptions from '../../components/ListBuilderPriceOptions.vue';
import ListBuilderBidPriceOptions from '../../components/ListBuilderBidPriceOptions.vue';
import Descriptors from "@/components/Descriptors.vue";
import Units from "@/components/Units.vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {mapState} from 'vuex'

extend("required", required);
extend("minimum", {
  params: ["minimum"],
  validate: (searchRadiusMiles, {minimum})=>{
    return (parseInt(searchRadiusMiles)>=minimum);
  },
  message:'Minimum'
});

export default {
  props: ['table'],
  computed:{
    ...mapState(['user']),
  },
  components: {
    ListBuilder,
    ListBuilderCropYear,
    ListBuilderSpecification,
    ListBuilderPriceOptions,
    ListBuilderBidPriceOptions,
    Descriptors,
    Units,
},
  data() {
    return {
      tabIndex:0,
      subtabIndex:0,
      tabs:[
        {name:'General',id:'general'},
        {name:'Products',id:'commodities'},
        {name:'Logistics',id:'logistics'}
      ],
      subtabs:[
        {name:'Categories',id:'categories'},
        {name:'Types',id:'commodity_types'},
        {name:'Subtypes',id:'commodity_subtypes'}
      ],
      categories:[],
      commodity_types:[],
      selectedCategory:null,
      selectedCommodityType:null,
      subscriptions:true,
      invoices:false,
      time:0,
      limitMiles:0,
      subscriptionOptions:[{value:true,text:"On"},{value:false,text:"Off"}],
      invoicesOptions:[{value:true,text:"On"},{value:false,text:"Off"}]
    };
  },
  async created(){
    let refSubscriptions = await db.collection('settings').doc('subscriptions').get();
    if(refSubscriptions.exists){
      this.subscriptions=refSubscriptions.data().active;
    }
    let refInvoices = await db.collection('settings').doc('invoices').get();
    if(refInvoices.exists){
      this.invoices=refInvoices.data().active;
    }
    let refLimitOfMiles = await db.collection('settings').doc('limitOfMiles').get();
    if(refLimitOfMiles.exists){
      this.limitMiles = refLimitOfMiles.data().limitMiles;
    }
  },
  watch:{
    async subtabIndex(){
      this.selectedCategory=null;
      this.selectedCommodityType=null;
      if(this.subtabIndex>0){
        this.categories=[];
        let categories = await db.collection('commodity_categories').orderBy('name','asc').get();
        categories.forEach((c)=>this.categories.push({text:c.data().name,value:c.id}));
      }
    }
  },
  mounted(){
    if(!this.user.role.admin){
      this.$router.replace("/not/access")
    }
  },
  methods:{
    backToTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    async subscriptionsChanged(){
      await db.collection('settings').doc('subscriptions').set({active:this.subscriptions});
    },
    async invoicesChanged(){
      await db.collection('settings').doc('invoices').set({active:this.invoices});
    },
    async timeoutChanged(){
      await db.collection('settings').doc('timeout').set({time:this.time});
    },
    async searchLimitChanged(){
      await db.collection('settings').doc('limitOfMiles').set({limitMiles: this.limitMiles});
    },
    async categoryChange(){
      this.selectedCommodityType=null;
      this.commodity_types=[];
      if(this.subtabIndex>1){
        let commodity_types = await db.collection('commodity_types').where('category','==',this.selectedCategory).orderBy('name','asc').get();
        commodity_types.forEach((c)=>this.commodity_types.push({text:c.data().name,value:c.id}));
      }
    }
  }
};
</script>
<style>
.custom_setting .nav-pills .nav-item .nav-link.active,
.custom_setting .nav-pills .nav-item .nav-link.active:focus, 
.custom_setting .nav-pills .nav-item .nav-link.active:hover{
  background-color: #7ec313;
  box-shadow: unset;
}
.custom_setting .nav-pills .nav-item .nav-link{
 background-color: #bee189;
 border:1px solid #7ec313;
}
.side_menu .nav-pills .nav-item .nav-link.active,
.side_menu .nav-pills .nav-item .nav-link.active:focus, 
.side_menu .nav-pills .nav-item .nav-link.active:hover{
  background-color: #333;
}
.side_menu .nav-pills .nav-item .nav-link{
 background-color: #fff;
}
.custom_setting .card-header{
  background-color: #fff !important;
}
.custom_setting .side_menu .card-header {
    background-color: #7ec313 !important;
  padding-top: 20px;

}
</style>
