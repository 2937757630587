<template>
  <div class="row">
    <div class="col-12 mb-4" v-if="subscriptions.active&&user.role.buyer&&user.subscription.stripeSubscription&&user.subscription.stripeSubscription.status=='trialing'">
      <div class="text-center text-md-left bg-warning p-2 rounded border"><strong><i class="fa fa-exclamation-circle mr-2"></i></strong>You are currently <strong>trialing</strong>. Your trial ends in <strong>{{moment.unix(user.subscription.stripeSubscription.trial_end).diff(moment(),'days')}} days.</strong><b-button href="/account" class="float-none float-md-right m-0 ml-2 p-1 pl-2 pr-2" variant="black"><i class="fa fa-thumbs-up mr-2"></i> Upgrade</b-button></div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import Vue from "vue";
import moment from 'moment';
Vue.prototype.moment = moment;
export default {
  computed:{
    ...mapState(['user','subscriptions'])
  }
}
</script>
