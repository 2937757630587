<template>
    <div>
        <div class="border-2 bg-white rounded p-4">
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left text-muted text-break" v-if="completeProductionContract.buyerID" >
                    Owner ID: {{ completeProductionContract.buyerID.substr(0, 10).toUpperCase() }}
                </div>
                <div class="flex-fill text-center text-md-right text-success font-weight-bold">
                    {{ moment(completeProductionContract.created).format("ll") }}
                </div>
            </div>
            <h2 class="mt-2 mb-2 d-none d-md-block">
               Trans.ID: {{ completeProductionContract.id.toUpperCase() }}
            </h2>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left pt-3">
                    <h6 class="d-inline">
                        production contract Accepted: {{ completeProductionContract.fullFillAmount }} acres  {{ completeProductionContract.productionContract.subtype }} {{ completeProductionContract.productionContract.type }}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left">
                    <h6 class="d-inline">
                        Guaranteed Purchase:  {{ numeral(completeProductionContract.productionContract.total).format('0,000') }} {{completeProductionContract.productionContract.unit}} per acre
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left">
                    <h6 class="d-inline">
                        {{ completeProductionContract.productionContract.priceOption }} Price (Per {{completeProductionContract.productionContract.unit}}):  {{ (completeProductionContract.productionContract.price > 0) ? '$' : '-$' }}{{ numeral( Math.abs(completeProductionContract.productionContract.price)).format('0,000.00')}}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left">
                    <h6 class="d-inline">
                        Crop Year:  {{ (completeProductionContract.productionContract.year != undefined) ? completeProductionContract.productionContract.year : "-"  }}
                    </h6>
                </div>
            </div>
            <div class="d-block d-md-flex">
                <div class="flex-fill text-center text-md-left">
                    <h6 class="d-inline">
                        Delivery Window: {{moment(completeProductionContract.productionContract.startDate.toDate()).format('L')}} - {{moment(completeProductionContract.productionContract.endDate.toDate()).format('L')}}
                    </h6>
                </div>
                <div class="flex-fill text-center text-md-right total-global">
                    <div class="pt-0">
                        <strong class="text-uppercase">Total Projected Value:</strong>
                        <h3 class="d-inline font-weight-bold">
                            {{ ((completeProductionContract.productionContract.price > 0) ? '$' : '-$') + numeral(totalAmount).format('0,000.00')}}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="d-flex view-list mt-3">
                <a
                    :href="'/production/contract/' + completeProductionContract.productionContract.id"
                    target="_blank"
                    class="btn d-inline btn-sm btn-primary m-0"
                    ><span class="fa fa-info-circle mr-2"></span> View Listing
                </a>

                </div>
            </div>
            <div v-if="user.role.admin" class="border-2 bg-white rounded p-4 mt-4">
                <h5>More Details</h5>
                <div class="row">
                    <div v-if="completeProductionContract.buyer && completeProductionContract.buyer.about" class="col-12 col-md-4">
                        <p>
                            <small class="text-muted d-block">Buyer</small>
                            <strong class="d-block"> {{ buyerFullName }}</strong>
                            <strong class="d-block">
                                {{ completeProductionContract.buyer.business.name }}
                            </strong>
                            <strong class="d-block">
                                {{ completeProductionContract.buyer.business.address.address }}</strong>
                            <strong class="d-block">
                                {{ completeProductionContract.buyer.business.address.city }},
                                {{ completeProductionContract.buyer.business.address.state }}
                                {{ completeProductionContract.buyer.business.address.zip }}
                            </strong>
                            <strong class="d-block">
                                {{ completeProductionContract.buyer.about.email }}
                            </strong>
                            <strong class="d-block">
                                {{ completeProductionContract.buyer.about.phone }}
                            </strong>
                        </p>
                    </div>
                    <div v-if="completeProductionContract.producer && completeProductionContract.producer.about" class="col-12 col-md-4">
                        <p>
                            <small class="text-muted d-block">Producer</small>
                            <strong class="d-block"> {{ producerFullName }}</strong>
                            <strong class="d-block">
                                {{ completeProductionContract.producer.business.name }}
                            </strong>
                            <strong class="d-block">
                                {{ completeProductionContract.producer.business.address.address }}
                            </strong>
                            <strong class="d-block">
                                {{ completeProductionContract.producer.business.address.city }},
                                {{ completeProductionContract.producer.business.address.state }}
                            {{ completeProductionContract.producer.business.address.zip }}
                            </strong>
                            <strong class="d-block">{{ completeProductionContract.producer.about.email }}</strong>
                            <strong class="d-block">{{ completeProductionContract.producer.about.phone }}</strong>
                          
                        </p>
                    </div>
                    <div v-if="completeProductionContract.productionContract" class="col-12 col-md-4">
                        <p>
                            <small class="text-muted d-block">Location</small>
                            <strong class="d-block">{{ productionContractAddress }} 
                            </strong>
                            <gmap-map
                                v-if="completeProductionContract.productionContract.placePosition"
                                :center="completeProductionContract.productionContract.placePosition"
                                :zoom="12"
                                class="mt-2"
                                style="width:100%;height: 320px;"
                            >
                                <gmap-marker :position="completeProductionContract.productionContract.placePosition">
                                </gmap-marker>
                            </gmap-map>
                        </p>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

import moment from "moment";
import Vue from "vue";
import { mapState } from "vuex";

Vue.prototype.moment = moment;


export default{
    props: ["completeProductionContract"],
    computed: {
        ...mapState(["user"]),
        totalAmount: function(){
            const vm = this;
            return Math.abs(vm.completeProductionContract.productionContract.price * vm.completeProductionContract.productionContract.total)
        },
        buyerFullName: function(){
            const vm =this;
            return vm.completeProductionContract.buyer.about.first +" "+ vm.completeProductionContract.buyer.about.last 
        },
        producerFullName: function(){
            const vm = this;
            return vm.completeProductionContract.producer.about.first +" "+ vm.completeProductionContract.producer.about.last 
        },
        productionContractAddress: function(){
            const vm = this;
            return   (vm.completeProductionContract.productionContract.location.formatted_address) ? vm.completeProductionContract.productionContract.location.formatted_address : vm.completeProductionContract.productionContract.location;
        }
    },

}
</script>
