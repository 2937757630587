<template>
  <div class="container" style="margin-top: -50px;">
      <div>

          <p class="h4 text-center" style="color: white;">
              Seller &  Buyers: Choose your plan
          </p>
      </div>
      <div class="align-items-center">
          <b-card-group deck style="margin-left: 10rem;">
              <b-card class="" header-tag="header" footer-tag="footer" style="max-width:22rem">
                  <template #header>
                    <h5 class="p-2 text-bold text-center text-white text-uppercase">Basic</h5>
                  </template>
                  <b-card-text>
                      <p class="text-center text-primary mb-1 h1">$<span>0</span></p>
                      <p class="text-center">Free Plan</p>
                    
                      <div class="border-top mt-4 mb-4"></div>
                      <div class="mb-4">
                          <p>
                              A team member will get you started, then you are off
                          </p>
                      </div>
                  </b-card-text>
                  <template #footer>
                    <b-button href="#" variant="secondary" class="btn rounded btn-block btn-primary disabled" @click="clickCheckout">Current Plan</b-button>
                </template>
              </b-card>
              <b-card class="" header-tag="header" footer-tag="footer" style="max-width:22rem">
                  <template #header>
                    <h5 class="p-2 text-bold text-center text-white text-uppercase">Starter</h5>
                  </template>
                  <b-card-text>
                      <p class="text-center text-primary mb-1 h1">$<span>100</span></p>
                      <p class="text-center">/ Month</p>
                      <div class="border-top mt-4 mb-4"></div>
                      <div class="mb-4">
                          <p>
                              <b>*Seller*</b> <br />
                              Monitor Messaging <br />
                              Monitor Expiration Dates <br />
                              Manage Listing <br />
                              <b>*Buyer*</b> <br />
                              Get listing sent directly to your email
                          </p>
                      </div>
                  </b-card-text>
                  <template #footer>
                        <b-button href="#" variant="primary" class="btn rounded btn-block btn-primary" @click="clickCheckout">Select</b-button>
                    </template>
              </b-card>
              <!-- <b-card class="" header-tag="header" footer-tag="footer">
                  <template #header>
                    <h5 class="p-2 text-bold text-center text-white text-uppercase">Plus</h5>
                  </template>
                  <b-card-text>
                      <div class="border-bottom">
                          <p class="text-center text-primary mb-1 h1">$<span>15</span></p>
                          <p class="text-center">Every Month</p>
                      </div>
                      <div class="mt-4 mb-4">
                          <p>
                              Starter included <br />
                             <b> *Seller* </b><br />
                             - Snapshot of listing performance: bookmarked <br />
                             - Monthly Review of listing activity(Sold, removed)<br />
                              <b>*Buyer*</b> <br />
                              - Snapshot of similar listing performance (cost compare) <br />
                              - Monthly Review of bookmarked listing(Open negotiations)<br />
                          </p>
                      </div>
                  </b-card-text>
                  <template #footer>
                        <b-button href="#" variant="primary" class="btn rounded btn-block btn-primary" @click="clickCheckout">Select</b-button>
                    </template>
              </b-card>
              <b-card class="" header-tag="header" footer-tag="footer">
                  <template #header>
                    <h5 class="p-2 text-bold text-center text-white text-uppercase">Premium</h5>
                  </template>
                  <b-card-text>
                      <p class="text-center text-primary mb-1 h1">$<span>20</span></p>
                      <p class="text-center">Every Month</p>
                      <div class="border-top mt-4 mb-4"></div>
                      <div class="mb-4">
                          <p>
                              Starter included <br />
                              Plus include <br />
                              <b>*Seller*</b><br />
                              Receive bid offers posted by Buyers with active listings  <br />
                              <b>*Buyer*</b> <br />
                              Place a mass bid offer to network with an active listing <br />
                          </p>
                      </div>
                  </b-card-text>
                    <template #footer>
                        <b-button href="#" variant="primary" class="btn rounded btn-block btn-primary" @click="clickCheckout">Select</b-button>
                    </template>
              </b-card> -->
          </b-card-group>
      </div>
  </div>   
</template>
<style >
.card-header{
    background-color: #7ec313 !important;
}
.card .card-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.card-deck .card{
    padding-bottom: 60px;
}
</style>
<script>
export default {
    methods: {
        clickCheckout() {
            this.$router.replace('/checkout');
        }
    }
}
</script>