<template>
  <div class="container">
    <AccountStatus />
    <div v-if="commodityID" class="row mt-0 mt-md-4">
      <CommodityView ref="CommodityView" :commodityID="commodityID" />
    </div>
    <div v-else-if="orderID" class="row mt-0 mt-md-4">
      <OrderView ref="OrderView" :orderID="orderID" />
    </div>
    <div v-else-if="loading" class="row mt-0 mt-md-4">
      <div class="col d-none d-md-flex"></div>
      <div class="col-12 col-md-8 col-xl-6 pt-4">
        <card class="card-lock text-center mt-4 pb-4" no-footer-line>
          <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
          <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
        </card>
      </div>
      <div class="col d-none d-md-flex"></div>
    </div>
    <div v-else class="row">
      <div class="col-12 dash-card-header col-md-8">
        <b-card class="rounded border-2">
          <b-card-header>
            <div class="d-flex flex-row justify-content-between">
              <h5 class="m-0">Latest Activity</h5>
              <b-button v-if="user.role.admin" @click="clearActivity" variant="danger" size="sm"
                :disabled="clearActivityButton" class="m-0 btn btn-danger float-left">
                CLEAR ALL
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="activityLoading"></b-spinner>
            <b-list-group v-else-if="data.length > 0">
              <b-list-group-item @click="openItem(item)" button :key="item.id" v-for="item in getUniqueValueInbox(data)"
                class="d-block d-xl-flex justify-content-between align-items-center text-center text-md-left">
                <div class="d-flex">
                  <div class="position-relative activity-image">
                    <b-img class="d-none d-md-block img-dashboard-large-preview"
                      v-if="item.commodity && item.commodity.images" :src="primaryImage(item.commodity.images).thumbURL"
                      thumbnail />
                    <b-img class="d-block d-md-none" v-if="item.commodity && item.commodity.images"
                      :src="primaryImage(item.commodity.images).largeURL" thumbnail />
                    <small v-if="item.unread" class="position-absolute fa fa-circle text-danger notification-icon"></small>
                  </div>
                  <div class="text-left w-100 pl-0 pl-md-4 pr-0 pr-md-4 mt-4 mt-md-0">
                    <h6 class="m-0">
                      <span v-if="item.commodity && item.commodity.subtype">
                        {{ item.commodity.subtype + " " }}
                      </span>
                      <span v-else-if="item.productionContract && item.productionContract.subtype">
                        {{ item.productionContract.subtype + " " }}
                      </span>
                      <span v-else-if="item.bidRequest && item.bidRequest.subtype">
                        {{ item.bidRequest.subtype + " " }}
                      </span>
                      <span v-if="item.commodity && item.commodity.type">
                        {{ item.commodity.type }}
                      </span>
                      <span v-else-if="item.productionContract && item.productionContract.type">
                      {{ item.productionContract.type }}
                      </span>
                      <span v-else-if="item.bidRequest && item.bidRequest.type">
                        {{ item.bidRequest.type }}
                      </span>
                    </h6>
                    <div v-if="item.title" class="m-0">
                      <strong
                        :class="'text-' + (item.type == 'message' ? 'info' : (item.type == 'update' ? 'success' : (item.type == 'alert' ? 'danger' : (item.type == 'shipping' ? 'warning' : 'dark'))))">{{ item.type.toUpperCase() }}:
                      </strong>
                      <span class="ml-2">{{ item.title }}</span>
                    </div>
                    <div v-if="item.text" class="m-0">{{ item.text.substr(0, 100) + '...' }}</div>
                  </div>
                </div>
                <div class="text-xl-right text-left mt-2 mt-md-0">
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-if="item.entity == 'product_negotiation'">
                    Payment Negotiation
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'productionContract'">
                    Production Contract
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="success"
                    v-else-if="item.entity == 'production_contract_negotiation' && item.complete_Production_Contract_id != undefined">
                    Accept Production Contract Request
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'production_contract_negotiation'">
                    production Contract Negotiation
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'complete_production_contract_execute'">
                    Complete Production Contract
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'bidRequest'">
                    Product Bid
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="success" 
                      v-else-if="item.entity == 'bid_request_negotiation' && item.complete_bid_request_negotiation_id != undefined">
                    Accept Product Bid Request
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'bid_request_negotiation'">
                    Product Bid Negotiation
                  </b-badge>
                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else-if="item.entity == 'complete_bid_request_execute'">
                    Complete Product Bid Request
                  </b-badge>

                  <b-badge pill class="mb-0 mt-1 mt-xl-0 w-100 w-md-auto" variant="info" v-else>
                    {{ (item.entity == 'commodity' ? 'PRODUCT' : item.entity) }}
                  </b-badge>
                  <div class="d-flex d-xl-block align-items-center justify-content-between">
                    <small class="text-muted mt-1 d-block">
                      <nobr>{{ formatDate(item.created) }}</nobr>
                    </small>
                    <b-button @click.stop="clearIndividualLatestActivity(item)" class="clear-button m-0 mt-1" pill
                      variant="danger" size="sm" v-if="user.role.admin" :disabled="clearActivityButton">CLEAR</b-button>
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item v-if="!allActivityLoaded" :disabled="activityLoadingMore" @click="loadInbox" button
                variant="success" class="text-center"><span class="fa fa-spinner" v-if="activityLoadingMore"></span><span
                  v-else class="fa fa-plus-circle mr-2"></span> <strong>Load More Activity</strong></b-list-group-item>
            </b-list-group>
            <p class="text-muted m-0" v-else>You have no activity.</p>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-12 dash-card-header new-product-list col-md-4">
        <b-card v-if="!user.role.admin" class="rounded border-2">
          <b-card-header>
            <h5 class="m-0">Products Wish List</h5>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="commoditiesLoading"></b-spinner>
            <b-list-group v-else>
              <template v-if="saved.length > 0">
                <b-list-group-item @click="$router.push('/commodity/view/' + item.id)" :key="item.id" v-for="item in saved"
                  class="d-block d-md-flex align-items-center add-pointer">
                  <div class="position-relative activity-image">
                    <b-img class="d-block d-md-none" v-if="item.images" :src="primaryImage(item.images).largeURL"
                      thumbnail />
                    <b-img class="d-none d-md-block img-dashboard-large-preview" v-if="item.images"
                      :src="primaryImage(item.images).thumbURL" thumbnail />
                  </div>
                  <div class="text-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                    <h6 class="mt-2 m-md-0">{{ item.subtype }} {{ item.type }} </h6>
                    <small class="text-muted d-block">{{ numeral(item.accepted_amount ? item.accepted_amount :
                      item.total).format('0,000') }} {{ item.unit }}</small>
                    <small class="text-muted d-block" v-if="!item.active">Unavailable</small>
                  </div>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">You have not saved any listings to your profile.</p>
                </b-list-group-item>
              </template>
            </b-list-group>
            <b-list-group-item>
              <b-button href="/marketplace" block variant="success"><i class="fa fa-search mr-2"></i> FIND
                PRODUCTS</b-button>
            </b-list-group-item>
          </b-card-body>
        </b-card>
        <b-card class="rounded border-2">
          <b-card-header>
            <h5 class="m-0" v-if="!user.role.admin">My Products</h5>
            <div v-else class="d-flex flex-row justify-content-between">
              <h5 class="m-0">Newest Products</h5>
              <b-button v-if="user.role.admin" variant="danger" size="sm" :disabled="clearProductsButton"
                @click="clearProducts" class="m-0 btn btn-danger float-left clear-button-text">CLEAR ALL</b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingsLoading"></b-spinner>
            <b-list-group v-else>
              <template v-if="listings.length > 0">
                <div v-if="user.role.admin" class="row m-0">
                  <b-list-group-item @click="openCommodity(item.id)" button :key="item.id" v-for="item in listings"
                    class="new-product d-block col-xl-6 position-relative ">
                    <div class="position-relative new-product-img">
                      <b-img class="d-block d-md-none" v-if="item.images" :src="primaryImage(item.images).largeURL"
                        thumbnail />
                      <b-img class="d-none d-md-block" v-if="item.images" :src="primaryImage(item.images).thumbURL"
                        thumbnail />
                    </div>
                    <div class="text-center w-100">
                      <h6 class="mt-2 m-md-0"><span v-if="item.subtype">{{ item.subtype }}</span> <span
                          v-if="item.type">{{ item.type }}</span></h6>
                      <small class="text-muted d-block">{{ numeral(item.accepted_amount ? item.accepted_amount :
                        item.total).format('0,000') }} {{ item.unit }}</small>
                      <b-button v-if="user.role.admin" variant="info" 
                        class="clear-button close-product-button position-absolute" title="Clear" size="sm" pill
                        :disabled="clearProductsButton" @click.stop="clearIndividualProducts(item)">X</b-button>
                    </div>
                  </b-list-group-item>
                </div>
                <div v-else class="row m-0">
                  <b-list-group-item @click="openCommodity(item.id)" button :key="item.id" v-for="item in listings"
                    class="d-block new-product col-xl-6  position-relative">
                    <div class="new-product-img  position-relative ">
                      <b-img class="d-block d-md-none" v-if="item.images" :src="primaryImage(item.images).largeURL"
                        thumbnail />
                      <b-img class="d-none d-md-block" v-if="item.images"
                        :src="primaryImage(item.images).thumbURL" thumbnail />
                    </div>
                    <div class="text-center w-100">
                      <h6 class="mt-2 m-md-0"><span v-if="item.subtype">{{ item.subtype }}</span> <span
                          v-if="item.type">{{ item.type }}</span></h6>
                      <small class="text-muted d-block">{{ numeral(item.accepted_amount ? item.accepted_amount :
                        item.total).format('0,000') }} {{ item.unit }}</small>
                    </div>
                  </b-list-group-item>
                </div>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">You have not listed any Products on the Marketplace.</p>
                </b-list-group-item>
              </template>
              <b-list-group-item>
                <b-button @click="$router.push('/commodity/new')" block variant="success"><i
                    class="fa fa-plus-square mr-2"></i>NEW PRODUCT</b-button>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>

        <!-- <b-card class="rounded border-2" v-if="!user.role.admin">
          <b-card-header>
            <h5 class="m-0">My Products In Negotiation</h5>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingsProductNegotiation"></b-spinner>
            <b-list-group v-else>
              <template v-if="myProductNegotiation.length > 0">
                <b-list-group-item v-for="(item, index) in getUniqueValue(myProductNegotiation)" :key="index"
                  class="d-block d-md-flex text-md-left align-items-center">
                  <div class="position-relative  activity-image">
                    <b-img class="d-none d-md-block img-dashboard-large-preview"
                      v-if="item.commodity && item.commodity.images" :src="primaryImage(item.commodity.images).thumbURL"
                      thumbnail />
                    <b-img class="d-block d-md-none" v-if="item.commodity && item.commodity.images"
                      :src="primaryImage(item.commodity.images).largeURL" thumbnail />
                  </div>
                  <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                    <h6 class="mt-2 m-md-0">
                      {{ item.commodity.subtype.name }} {{ item.commodity.type.name }}
                    </h6>
                    <div class="text-muted mb-0">
                      <small>Purchase of {{ numeral(item.amount).format('0,000') }} {{ item.commodity.unit.name }}
                        {{ item.commodity.subtype.name }} {{ item.commodity.type.name }}</small>
                    </div>
                    <div class="text-muted mb-0">
                      <small>Total: ${{ numeral(item.total).format('0,000.00') }}</small>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <b-button @click="$router.push('/negotiation/items')" block variant="success"><i
                      class="fa fa-plus-square mr-2"></i>Show More</b-button>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">Product Negotiation Request is empty.</p>
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card-body>
        </b-card> -->
    <!-- <b-card class="rounded border-2" v-if="!user.role.admin">
          <b-card-header>
            <h5 class="m-0">My Product Negotiations</h5>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingProducerProductNegotiations"></b-spinner>
            <b-list-group v-else>
              <template v-if="producerProductNegotiation.length > 0">
                <b-list-group-item v-for="order in getUniqueValue(producerProductNegotiation)"
                  @click="openProductNegotiation(order.id)" :key="order.id"
                  class="d-block d-md-flex align-items-center add-pointer">
                  <div class="position-relative  activity-image">
                    <b-img class="d-none d-md-block img-dashboard-large-preview"
                      v-if="order.commodity && order.commodity.images"
                      :src="primaryImage(order.commodity.images).thumbURL" thumbnail />
                    <b-img class="d-block d-md-none" v-if="order.commodity && order.commodity.images"
                      :src="primaryImage(order.commodity.images).largeURL" thumbnail />
                  </div>
                  <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                    <h6 class="mt-2 m-md-0">
                      {{ order.commodity.subtype.name }} {{ order.commodity.type.name }}
                    </h6>
                    <div class="text-muted">
                      <small>Purchase of {{ order.amount }}</small>
                    </div>
                    <div class="text-muted">
                      <small> Total: ${{ numeral(order.total).format('0,000.00') }} </small><br />
                    </div>
                    <div class="text-muted">
                      <small> Buyer Offer Price: ${{ numeral(order.offer_price).format('0,000.00') }} </small>
                    </div>
                    <div class="text-muted">
                      <small> Payment Negotiation: </small><br />
                      <small v-if="order.paymentOption == '100_at_purchase'">100% at time of Purchase</small>
                      <small v-else-if="order.paymentOption == '50_at_purchase'">Buyer and Seller Negotiated Payment
                        Split</small>
                      <small v-else-if="order.paymentOption == '100_at_delivery'">100% at time of Delivery</small>
                    </div>
                  </div>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">Product Negotiation Request is empty.</p>
                </b-list-group-item>
              </template>
              <-- <b-list-group-item>
                    <b-button @click="$router.push('/product/grain/cart')" block variant="success"><i class="fa fa-plus-square mr-2"></i>Show More</b-button>
                </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card> -->
        <b-card class="rounded border-2">
          <b-card-header>
            <h5 class="m-0" v-if="!user.role.admin"><span>My Production Contracts</span>
            </h5>
            <div v-if="user.role.admin" class="d-flex flex-row justify-content-between">
              <h5 class="m-0">Newest Production Contracts</h5>
              <b-button v-if="user.role.admin" variant="danger" size="sm" :disabled="clearProductionContractButton"
                @click="clearProductionContracts" class="m-0 btn btn-danger float-left clear-button-text">CLEAR
                ALL</b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingsProductionContract"></b-spinner>
            <b-list-group v-else>
              <template v-if="myProductionContract.length > 0">
                <b-list-group-item v-for="data in getUniqueValue(myProductionContract)" :key="data.id"
                  class="d-block d-md-flex align-items-center add-pointer" @click="productionContractDetails(data.id)">
                  <div class="text-md-left w-100 pl-0">
                    <h6 class="mt-2 m-md-0">
                      {{ data.subtype }} {{ data.type }}
                    </h6>
                    <div class="d-lg-flex justify-content-between align-items-center">
                      <div>
                        <small>Create Date: {{ formatDate(data.created) }}</small><br />
                        <small class="d-block d-md-flex"><span>Delivery Window: <strong
                              class="d-block d-md-inline flex-fill text-left text-md-left">{{ moment(data.startDate.toDate()).format('ll') }}
                              to {{ moment(data.endDate.toDate()).format('ll') }}</strong></span></small>
                        <small class="d-block d-md-flex"><span>Number of Acres: <strong
                              class="d-block d-md-inline flex-fill text-left text-md-left"> {{ data.accepted_full_fill_amount != undefined ? data.accepted_full_fill_amount : data.available_acres }}</strong></span></small>
                      </div>
                      <div>
                        <b-button v-if="user.role.admin" variant="danger" class="clear-button" size="sm" pill
                          :disabled="clearProductionContractButton"
                          @click.stop="clearIndividualProductContract(data)">CLEAR</b-button>
                      </div>
                    </div>
                    

                  </div>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">You have not listed any Production Contracts on the Marketplace.</p>
                </b-list-group-item>
              </template>
              <b-list-group-item>
                <b-button @click="$router.push('/production/contract/new')" block variant="success"><i
                    class="fa fa-plus-square mr-2"></i>NEW PRODUCTION CONTRACTS</b-button>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>

        <b-card class="rounded border-2">
          <b-card-header>
            <h5 class="m-0" v-if="!user.role.admin"><span>My Buyer Bids</span> </h5>
            <div v-if="user.role.admin" class="d-flex flex-row justify-content-between">
              <h5 class="m-0">Newest Product Bid Request</h5>
              <b-button v-if="user.role.admin" variant="danger" size="sm" :disabled="clearBidRequestButton"
                @click="clearBidRequests" class="m-0 btn btn-danger float-left clear-button-text">CLEAR
                ALL</b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingsBidRequest"></b-spinner>
            <b-list-group v-else>
              <template v-if="myBidRequest.length > 0">
                <b-list-group-item v-for="data in getUniqueValue(myBidRequest)" :key="data.id"
                  class="d-block d-md-flex align-items-center add-pointer" @click="bidRequestDetails(data.id)">
                  <div class="text-md-left w-100 pl-0">
                    <h6 class="mt-2 m-md-0">
                      {{ data.subtype }} {{ data.type }}
                    </h6>
                    <div class="d-lg-flex justify-content-between align-items-center">
                      <div>
                        <small>Create Date: {{ formatDate(data.created) }}</small><br />
                        <small class="d-block d-md-flex"><span>Delivery Window: <strong
                              class="d-block d-md-inline flex-fill text-left text-md-left">{{ moment(data.startDate.toDate()).format('ll') }}
                              to {{ moment(data.endDate.toDate()).format('ll') }}</strong></span></small>
                        <small class="d-block d-md-flex"><span>Total of {{ data.unit }}: <strong
                              class="d-block d-md-inline flex-fill text-left text-md-left"> {{ data.accepted_full_fill_amount ?  data.accepted_full_fill_amount:data.total }}</strong></span></small>
                      </div>
                      <div>
                        <b-button v-if="user.role.admin" variant="danger" class="clear-button" size="sm" pill
                          :disabled="clearBidRequestButton"
                          @click.stop="clearIndividualBidRequest(data)">CLEAR</b-button>
                      </div>
                    </div>
                    
                    
                 
                  </div>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">You have not listed any Buyer Bids on the Marketplace.</p>
                </b-list-group-item>
              </template>
              <b-list-group-item>
                <b-button @click="$router.push('/bid/request/new')" block variant="success"><i
                    class="fa fa-plus-square mr-2"></i>{{ (user.role.admin) ? 'PRODUCT BID REQUEST'  : 'NEW BUYER BID' }} </b-button>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>

        </b-card>

        <!-- <b-card class="rounded border-2" v-if="user.role.buyer">
            <b-card-header><h5 class="m-0">My Production Contract Offers</h5></b-card-header>
            <b-card-body>
              <b-spinner v-if="listingProductionContractNegotiations"></b-spinner>
              <b-list-group v-else>
                  <template v-if="productionContractNegotiation.length > 0">
                    <b-list-group-item v-for="data in getUniqueValue(productionContractNegotiation)" @click="openProductionContractNegotiation(data.id)" :key="data.id" class="d-block d-md-flex align-items-center add-pointer">
                      <div class="text-center text-md-left w-100 pl-0 pr-0 pr-md-4">
                        <h6 class="mt-2 m-md-0">
                            {{data.productionContract.subtype}} {{data.productionContract.type}}
                        </h6>
                        <div class="text-muted">
                          <small>Available acres: {{ numeral(data.productionContract.available_acres).format('0,000') }}</small>
                        </div>
                        <div class="text-muted">
                          <small>Guaranteed Purchase acres: {{ numeral(data.productionContract.total).format('0,000') }}</small>
                        </div>
                        <div class="text-muted">
                          <small>Delivery Window: {{moment(data.productionContract.startDate.toDate()).format('ll')}} to {{moment(data.productionContract.endDate.toDate()).format('ll')}}</small>
                        </div>
                      </div>
                    </b-list-group-item>
                    <b-list-group-item>
                          <b-button @click="$router.push('/negotiation/items')" block variant="success"><i class="fa fa-plus-square mr-2"></i>Show More</b-button>
                      </b-list-group-item>
                  </template>
                  <template v-else>
                    <b-list-group-item>
                      <p class="text-muted m-0">Production Contract Offer list is empty.</p>
                    </b-list-group-item>
                  </template> 
              </b-list-group>
            </b-card-body>
        </b-card> -->

        <!-- <b-card class="rounded border-2" v-if="!user.role.admin">
          <b-card-header>
            <h5 class="m-0">My Production Contract Negotiations</h5>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingsProductionContractNegotiation"></b-spinner>
            <b-list-group v-else>
              <template v-if="myProductionContractNegotiation.length > 0">
                <b-list-group-item v-for="data in getUniqueValue(myProductionContractNegotiation)"
                  @click="openProductionContractNegotiation(data.id)" :key="data.id"
                  class="d-block d-md-flex align-items-center add-pointer">
                  <div class="text-md-left w-100 pl-0 pr-0 pr-md-4">
                    <h6 class="mt-2 m-md-0">
                      {{ data.productionContract.subtype }} {{ data.productionContract.type }}
                    </h6>
                    <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Available acres: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left">{{
                            numeral(data.productionContract.accepted_full_fill_amount ?
                              data.productionContract.accepted_full_fill_amount : data.productionContract.available_acres).format('0,000')
                          }}</strong></small>
                    </div>
                    <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Guaranteed Purchase acres: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left"> {{
                            numeral(data.productionContract.total).format('0,000') }} </strong></small>
                    </div>
                    <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Delivery Window: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left">{{ moment(data.productionContract.startDate.toDate()).format('ll') }}
                          to {{ moment(data.productionContract.endDate.toDate()).format('ll') }}</strong></small>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <b-button @click="$router.push('/negotiation/items')" block variant="success"><i
                      class="fa fa-plus-square mr-2"></i>Show More</b-button>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">Production Contract Negotiation Request is empty.</p>
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card-body>
        </b-card> -->

        <!-- <b-card class="rounded border-2" v-if="!user.role.admin">
          <b-card-header>
            <h5 class="m-0">My Product Bid Request Negotiations</h5>
          </b-card-header>
          <b-card-body>
            <b-spinner v-if="listingBidRequestNegotiation"></b-spinner>
            <b-list-group v-else>
              <template v-if="myBidRequestNegotiation.length > 0">
                <b-list-group-item v-for="data in getUniqueValue(myBidRequestNegotiation)"
                  @click="openBidRequestNegotiation(data.id)" :key="data.id"
                  class="d-block d-md-flex align-items-center add-pointer">
                  <div class="text-md-left w-100 pl-0 pr-0 pr-md-4">
                    <h6 class="mt-2 m-md-0">
                      {{ data.bidRequest.subtype }} {{ data.bidRequest.type }}
                    </h6>
                    <-- <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Available acres: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left">{{
                            numeral(data.productionContract.accepted_full_fill_amount ?
                              data.productionContract.accepted_full_fill_amount : data.productionContract.available_acres).format('0,000')
                          }}</strong></small>
                    </div> 
                    <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Total {{ data.bidRequest.unit }}: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left"> {{
                            numeral(data.bidRequest.total).format('0,000') }} </strong></small>
                    </div>
                    <div class="text-muted">
                      <small class="d-block d-md-flex"><span class="mr-1">Delivery Window: </span> <strong
                          class="d-block d-md-inline flex-fill text-left text-md-left">{{ moment(data.bidRequest.startDate.toDate()).format('ll') }}
                          to {{ moment(data.bidRequest.endDate.toDate()).format('ll') }}</strong></small>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <b-button @click="$router.push('/negotiation/items')" block variant="success"><i
                      class="fa fa-plus-square mr-2"></i>Show More</b-button>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group-item>
                  <p class="text-muted m-0">Product Bid Request Negotiation Request is empty.</p>
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card-body>
        </b-card> -->

      </div>
    </div>
  </div>
</template>
<script>
import { bus } from '@/vuex/bus.js';
import { mapState } from 'vuex';
import Vue from "vue";
import numeral from 'numeral';
import moment from 'moment';
//import {bus} from '@/vuex/bus.js';
import { db } from '@/firebase';
//import { collection } from 'rxfire/firestore';
import { collectionData, docData } from 'rxfire/firestore';
import { from, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import CommodityView from "@/views/Dashboard/Commodity/View.vue";
import OrderView from "@/views/Dashboard/Order/View.vue";
import AccountStatus from "@/views/Dashboard/Account/Status.vue";
Vue.prototype.moment = moment;
Vue.prototype.numeral = numeral;
export default {
  components: {
    CommodityView,
    OrderView,
    AccountStatus
  },
  methods: {
    openCommodity(id) {
      const vm = this;
      if(vm.user.role.admin){
        vm.$router.push('/commodity/edit/' + id);
      } else {
        vm.$router.push('/commodity/view/' + id);
      }
    },
    openProductNegotiation(id) {
      this.$router.push('/product/negotiation/' + id);
    },
    openProductionContractNegotiation(id) {
      this.$router.push('/production/contract/negotiation/' + id);
    },
    openBidRequestNegotiation(id){
      this.$router.push('/bid/request/negotiation/' + id);
    },
    openItem(item) {
      const vm = this;
      let ref = db.collection('inbox').doc(item.id);
      ref.update({ unread: false });
      switch (item.entity) {
        case 'commodity':
          vm.$router.push('/commodity/view/' + item.commodityID);          
          break;

        case 'order':
          vm.$router.push('/order/view/' + item.orderID);        
          break;
        
        case 'product_negotiation':
          var negotiationRef = db.collection('inbox').doc(item.id);
          negotiationRef.update({ unread: false });
          vm.$router.push('/product/negotiation/' + item.product_negotiation_id);
          break;

        case 'productionContract':
          vm.$router.push('/production/contract/' + item.productionContractID);
          break;

        case 'production_contract_negotiation':
          if(item.complete_Production_Contract_id == undefined){
            vm.$router.push('/production/contract/negotiation/' + item.production_contract_negotiation_id);
          } else if( item.complete_Production_Contract_id != undefined){
            vm.$router.push('/production/contract/complete/list/' + item.complete_Production_Contract_id);
          }
          break;
        
        case 'complete_production_contract_execute':
          vm.$router.push('/production/contract/complete/list/' + item.completeProductionContractID);
          break;
        
        case 'bidRequest':
          vm.$router.push('/bid/request/' + item.bidRequestID)
          break;

        case 'bid_request_negotiation':
          if(item.complete_bid_request_negotiation_id == undefined){
            vm.$router.push('/bid/request/negotiation/' + item.bid_request_negotiation_id);
          } else if( item.complete_bid_request_negotiation_id != undefined){
            vm.$router.push('/bid/request/complete/list/' + item.complete_bid_request_negotiation_id);
          }
          break;
        
        case 'complete_bid_request_execute':
          vm.$router.push('/bid/request/complete/list/' + item.completeBidRequestID);
          break;

        default:
          break;
      }
    },
    getUniqueValue(orders) {
      return [
        ...new Map(
          orders.map((item) => [item['id'], item])
        ).values()
      ]
    },
    getUniqueValueInbox(messages) {
      return [
        ...new Map(
          messages.map((item) => [item['id'], item])
        ).values()
      ]
    },
    async loadListings() {
      const vm = this;
      vm.listingsLoading = true;

      let ref = db.collection('commodities');
      if (!vm.user.role.admin) {
        ref = ref.where('userID', '==', vm.user.uid)
      }
      
      ref = ref.limit(10).orderBy('created', 'desc');
      collectionData(ref, 'id').pipe(
        switchMap(listings => {
          if (listings.length == 0) return of([]);
          return combineLatest(...listings.map(c => {
            return docData(db.collection('commodity_types').doc(c.type)).pipe(
              map(t => {
                return { ...c, type: t.name }
              })
            )
          }))
        })
      ).pipe(
        switchMap(listings => {
          if (listings.length == 0) return of([]);
          return combineLatest(...listings.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
              map(t => {
                return { ...c, subtype: t.name }
              })
            )
          }))
        })
      ).pipe(
        switchMap(commodities => {
          if (commodities.length == 0) return of([]);
          return combineLatest(...commodities.map(c => {
            return docData(db.collection('units').doc(c.unit)).pipe(
              map(t => {
                return { ...c, unit: t.name }
              })
            )
          }))
        })
      ).subscribe((listings) => {
        if (vm.user.role.admin) {
          let listing2 = [];
          Array.from(Array(listings.length).keys()).map((x) => {
            if (((listings[x].is_delete == 0) && ((listings[x].is_newest_product == undefined) || (listings[x].is_newest_product == 1)))) {
              listing2.push(listings[x])
            }
          });
          if (listing2.length == 0) {
            vm.clearProductsButton = true;
          }
          vm.listingsLoading = false;
          vm.listings = listing2;
        } else {
          let listing2 = [];
          Array.from(Array(listings.length).keys()).map((x) => {
            if (listings[x].is_delete == 0) {
              listing2.push(listings[x])
            }
          });
          vm.listingsLoading = false;
          vm.listings = listing2;
        }
      });
      
    },
    async loadSavedCommodities() {
      let vm = this;
      vm.commoditiesLoading = true;
      let ref = db.collection('commodity_bookmarks');
      ref = ref.where('userID', '==', vm.user.uid);
      ref = ref.orderBy('created', 'desc');
      vm.saved = [];
      collectionData(ref, 'id').pipe(
        switchMap(listings => {
          if (listings.length == 0) return of([]);
          return combineLatest(...listings.map(c => {
            return docData(db.collection('commodities').doc(c.commodityID)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...c }
                } else {
                  return of({});
                }

              })
            )
          }))
        })
      ).pipe(
        switchMap(listings => {
          if (listings.length == 0) return of([]);
          return combineLatest(...listings.map(c => {
            if (c && c.type) {
              return docData(db.collection('commodity_types').doc(c.type)).pipe(
                map(t => {
                  return { ...c, type: t.name }
                })
              )
            } else {
              return of({ ...c, type: '' })
            }
          }))
        })
      ).pipe(
        switchMap(listings => {
          if (listings.length == 0) return of([]);
          return combineLatest(...listings.map(c => {
            if (c.subtype && c) {
              return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                map(t => {
                  return { ...c, subtype: t.name }
                })
              )
            } else {
              return of({ ...c, subtype: '' })
            }
          }))
        })
      ).pipe(
        switchMap(commodities => {
          if (commodities.length == 0) return of([]);
          return combineLatest(...commodities.map(c => {
            if (c && c.unit) {
              return docData(db.collection('units').doc(c.unit)).pipe(
                map(t => {
                  return { ...c, unit: t.name }
                })
              )
            } else {
              return of({ ...c, unit: '' })
            }
          }))
        })
      ).subscribe((listings) => {
        listings.forEach((i) => {
          if (i.type != "") {
            vm.saved.push(i)
          }
        });
        vm.commoditiesLoading = false;
      });
    },
    async loadMyProductNegotiation() {
      const vm = this;
      vm.listingsProductNegotiation = true;
      let ref = await db.collection('products_negotiation').where('is_restore', '==', null);
      ref = ref.where('buyerID', '==', vm.user.uid).limit(3);
      collectionData(ref, 'id').pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('commodities').doc(item.commodityID)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: c }
                } else {
                  return of({});
                }

              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('commodity_types').doc(item.commodity.type)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, type: c } }
                } else {
                  return of({});
                }

              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          console.log(items);
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            
            return docData(db.collection('commodity_subtypes').doc(item.commodity.subtype)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, subtype: c } }
                } else {
                  return of({});
                }
              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('units').doc(item.commodity.unit)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, unit: c } }
                } else {
                  return of({});
                }
              })
            )
          })
          )
        })
      ).subscribe((items) => {
        if (items.length != 0) {
          items.map((i) => {
            vm.myProductNegotiation.push(i);
          });
        }
      });
      setTimeout(() => {
        vm.listingsProductNegotiation = false;
      }, 5000);
    },


    async loadMyBidRequest(isAdmin){
        const vm = this;
        vm.listingsBidRequest = true;
        let ref = db.collection('bid_request');
        if (!isAdmin) {
          ref = ref.where('userID', '==', vm.user.uid);
        }
        ref = ref.where('is_delete', '==', 0).orderBy('created', 'desc')
        collectionData(ref, 'id').pipe(
        switchMap((bidRequests) => {
          if (bidRequests.empty) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('units').doc(c.unit)).pipe(
              map((t) => {
                return { ...c, unit: t.name, unitId: c.unit }
              })
            )
          }))
        })
      ).pipe(
        switchMap(bidRequests => {
          if (bidRequests.length == 0) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('commodity_types').doc(c.type)).pipe(
              map(t => {
                return { ...c, type: t.name }
              })
            )
          }))
        })
      ).pipe(
        switchMap(bidRequests => {
          if (bidRequests.length == 0) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
              map(t => {
                return { ...c, subtype: t.name }
              })
            )
          }))
        })
      ).subscribe((bidRequests) => {
        if (!vm.user.role.admin) {
          if (bidRequests.length != 0) {
            bidRequests.map((bidRequest) => {
              vm.myBidRequest.push(bidRequest);
            });
          }
        } else if (this.user.role.admin) {
          if (bidRequests.length != 0) {
            bidRequests.map((bidRequest) => {
              if (bidRequest.is_newest == undefined || bidRequest.is_newest == 1) {
                vm.myBidRequest.push(bidRequest);
              }
            });
          }
        }
        vm.loading = false
      })
      setTimeout(() => {
        vm.listingsBidRequest = false;
      }, 4000);
    },

    async loadMyProductionContract(isAdmin) {
      const vm = this;
      vm.listingsProductionContract = true;
      let ref = db.collection('production_contract');
      if (!isAdmin) {
        ref = ref.where('userID', '==', vm.user.uid);
      }
      ref = ref.where('is_delete', '==', 0).orderBy('created', 'desc')
      collectionData(ref, 'id').pipe(
        switchMap((productionContracts) => {
          if (productionContracts.empty) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('units').doc(c.unit)).pipe(
              map((t) => {
                return { ...c, unit: t.name, unitId: c.unit }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_types').doc(c.type)).pipe(
              map(t => {
                return { ...c, type: t.name }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
              map(t => {
                return { ...c, subtype: t.name }
              })
            )
          }))
        })
      ).subscribe((productionContracts) => {
        if (!vm.user.role.admin) {
          if (productionContracts.length != 0) {
            productionContracts.map((productionContract) => {
              vm.myProductionContract.push(productionContract);
            });
          }
        } else if (vm.user.role.admin) {
          if (productionContracts.length != 0) {
            productionContracts.map((productionContract) => {
              if (productionContract.is_newest == undefined || productionContract.is_newest == 1) {
                vm.myProductionContract.push(productionContract);
              }
            });
          }
        }
        vm.loading = false
      })
      setTimeout(() => {
        vm.listingsProductionContract = false;
      }, 4000);
    },


    async loadMyBidRequestNegotiation(){
      const vm = this;
      vm.listingBidRequestNegotiation = true;

      let ref = db.collection('bid_request_negotiation').where('is_restore', '==', null);
      ref = ref.where('producerID', '==', vm.user.uid).orderBy('created', 'desc').limit(3);
      collectionData(ref, 'id').pipe(
        switchMap((bidRequests) => {
          if (bidRequests.empty) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('bid_request').doc(c.bidRequestID)).pipe(
              map(bidRequest => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...c, bidRequest: bidRequest }
                } else {
                  return of({});
                }
              })
            )
          }))
        })
      ).pipe(
        switchMap(bidRequests => {
          if (bidRequests.length == 0) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('commodity_types').doc(c.bidRequest.type)).pipe(
              map(t => {
                return { ...c, bidRequest: { ...c.bidRequest, type: t.name } }
              })
            )
          }))
        })
      ).pipe(
        switchMap(bidRequests => {
          if (bidRequests.length == 0) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.bidRequest.subtype)).pipe(
              map(t => {
                return { ...c, bidRequest: { ...c.bidRequest, subtype: t.name } }
              })
            )
          }))
        })
      ).pipe(
        switchMap(bidRequests => {
          if (bidRequests.length == 0) return of([]);
          return combineLatest(...bidRequests.map(c => {
            return docData(db.collection('units').doc(c.bidRequest.unit)).pipe(
              map(t => {
                return { ...c, bidRequest: { ...c.bidRequest, unit: t.name } }
              })
            )
          }))
        })
      ).subscribe((bidRequests) => {
        if (bidRequests.length != 0) {
          bidRequests.map((bidRequest) => {
            if (bidRequest.is_bidRequest_negotiation_status == undefined) {
              vm.myBidRequestNegotiation.push(bidRequest);
            }
          });
        }
      });
      setTimeout(() => {
        vm.listingBidRequestNegotiation = false;
      }, 4000);

    },

    async loadMyProductionContractNegotiation() {
      let vm = this;
      vm.listingsProductionContractNegotiation = true;
      let ref = db.collection('production_contract_negotiation')
      ref = ref.where('producerID', '==', vm.user.uid).where('is_restore','==', null).orderBy('created', 'desc').limit(3);
      collectionData(ref, 'id').pipe(
        switchMap((productionContracts) => {
          if (productionContracts.empty) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('production_contract').doc(c.productionContractID)).pipe(
              map(productionContract => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...c, productionContract: productionContract }
                } else {
                  return of({});
                }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
              map(t => {
                return { ...c, productionContract: { ...c.productionContract, type: t.name } }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
              map(t => {
                return { ...c, productionContract: { ...c.productionContract, subtype: t.name } }
              })
            )
          }))
        })
      ).subscribe((productionContracts) => {
        if (productionContracts.length != 0) {
          productionContracts.map((productionContracts) => {
            if (productionContracts.is_productionContract_negotiation_status == undefined) {
              vm.myProductionContractNegotiation.push(productionContracts);
            }
          });
        }
      });
      setTimeout(() => {
        vm.listingsProductionContractNegotiation = false;
      }, 4000);
    },
    async loadProductionContractNegotiation() {
      let vm = this;
      vm.listingProductionContractNegotiations = true;

      let ref = db.collection('production_contract_negotiation').where('is_restore','==', null)
      ref = ref.where('buyerID', '==', vm.user.uid).orderBy('created', 'desc').limit(3);
      collectionData(ref, 'id').pipe(
        switchMap((productionContracts) => {
          if (productionContracts.empty) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('production_contract').doc(c.productionContractID)).pipe(
              map(productionContract => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...c, productionContract: productionContract }
                } else {
                  return of({});
                }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
              map(t => {
                return { ...c, productionContract: { ...c.productionContract, type: t.name } }
              })
            )
          }))
        })
      ).pipe(
        switchMap(productionContracts => {
          if (productionContracts.length == 0) return of([]);
          return combineLatest(...productionContracts.map(c => {
            return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
              map(t => {
                return { ...c, productionContract: { ...c.productionContract, subtype: t.name } }
              })
            )
          }))
        })
      ).subscribe((productionContracts) => {
        if (productionContracts.length != 0) {
          productionContracts.map((productionContracts) => {
            if (productionContracts.is_productionContract_negotiation_status == undefined) {
              vm.productionContractNegotiation.push(productionContracts);
            }
          });
        }
        setTimeout(() => {
          vm.listingProductionContractNegotiations = false;
        }, 4000);
      });
    },
    async loadProductNegotiation() {
      let vm = this;
      vm.listingProducerProductNegotiations = true;
      let ref = db.collection('products_negotiation').where('is_restore', '==', null)
      ref = ref.where('producerID', '==', vm.user.uid);
      ref = ref.where('is_hide_paymentOption', '==', 0);
      collectionData(ref, 'id').pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('commodities').doc(item.commodityID)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: c }
                } else {
                  return of({});
                }

              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('commodity_types').doc(item.commodity.type)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, type: c } }
                } else {
                  return of({});
                }

              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('commodity_subtypes').doc(item.commodity.subtype)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, subtype: c } }
                } else {
                  return of({});
                }
              })
            )
          })
          )
        })
      ).pipe(
        switchMap((items) => {
          if (items.empty) return of([]);
          return combineLatest(...items.map((item) => {
            return docData(db.collection('units').doc(item.commodity.unit)).pipe(
              map(c => {
                if (JSON.stringify(c) !== '{}') {
                  return { ...item, commodity: { ...item.commodity, unit: c } }
                } else {
                  return of({});
                }
              })
            )
          })
          )
        })
      ).subscribe((items) => {
        if (items.length != 0) {
          items.map((item) => {
            vm.producerProductNegotiation.push(item);
          });
        }
      });
      setTimeout(() => {
        vm.listingProducerProductNegotiations = false;
      }, 5000);

    },

    async loadInboxItems(ref) {
      const vm = this;
      // await collectionData(ref, 'id').pipe(
      //   switchMap ((inbox) => {
      //     if(inbox.length < 10) vm.allActivityLoaded = true;
      //     if(inbox.length === 0) return of([])
      //     vm.lastActivityDoc = inbox[inbox.length - 1];
      //     return combineLatest(...inbox.map(item => {
      //       if (item.commodityID != undefined) {
      //         return docData(db.collection('commodities').doc(item.commodityID)).pipe(
      //           map(t => {
      //             return { ...item, id: item.id, commodity: { ...t } }
      //           })
      //         )
      //       } else if (item.productionContractID != undefined && (item.entity == "productionContract" || item.entity == "production_contract_negotiation" || item.entity == "complete_production_contract_execute")) {
      //         return docData(db.collection('production_contract').doc(item.productionContractID)).pipe(
      //           map(t => {
      //             return { ...item, id: item.id, productionContract: { ...t } }
      //           })
      //         )
      //       } else if (item.bidRequestID != undefined && (item.entity == "bidRequest" || item.entity == "bid_request_negotiation" || item.entity == "complete_bid_request_execute")){
      //         return docData(db.collection('bid_request').doc(item.bidRequestID)).pipe(
      //           map(t => {
      //             return { ...item, id: item.id, bidRequest: { ...t } }
      //           })
      //         )
      //       }
      //     }))
      //   })
      // ).pipe(
      //   switchMap(inbox => {
      //     if (inbox.length == 0) return of([]);
      //     return combineLatest(...inbox.map(c => {
      //       if (c.commodity && c.commodity.type) {
      //         return docData(db.collection('commodity_types').doc(c.commodity.type)).pipe(
      //           map(t => {
      //             let typeName = (t.name) ? t.name : '';
      //             return { ...c, commodity: { ...c.commodity, type: typeName } }
      //           })
      //         )
      //       } else if (c.productionContract && c.productionContract.type) {
      //         return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
      //           map(t => {
      //             let typeName = (t.name) ? t.name : '';
      //             return { ...c, productionContract: { ...c.productionContract, type: typeName } }
      //           })
      //         )
      //       } else if (c.bidRequest && c.bidRequest.type){
      //         return docData(db.collection('commodity_types').doc(c.bidRequest.type)).pipe(
      //           map(t => {
      //             let typeName = (t.name) ? t.name : '';
      //             return { ...c, bidRequest: { ...c.bidRequest, type: typeName } }
      //           })
      //         )
      //       }
      //       return of([]);
      //     }))
      //   })
      // ).pipe(
      //   switchMap(inbox => {
      //     if (inbox.length == 0) return of([]);
      //     return combineLatest(...inbox.map(c => {
      //       if (c.commodity && c.commodity.subtype) {
      //         return docData(db.collection('commodity_subtypes').doc(c.commodity.subtype)).pipe(
      //           map(t => {
      //             let subTypeName = (t.name) ? t.name : '';
      //             return { ...c, commodity: { ...c.commodity, subtype: subTypeName } }
      //           })
      //         )
      //       } else if (c.productionContract && c.productionContract.subtype) {
      //         return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
      //           map(t => {
      //             let subTypeName = (t.name) ? t.name : '';
      //             return { ...c, productionContract: { ...c.productionContract, subtype: subTypeName } }
      //           })
      //         )
      //       } else if(c.bidRequest && c.bidRequest.subtype){
      //         return docData(db.collection('commodity_subtypes').doc(c.bidRequest.subtype)).pipe(
      //           map(t => {
      //             let subTypeName = (t.name) ? t.name : '';
      //             return { ...c, bidRequest: { ...c.bidRequest, subtype: subTypeName } }
      //           })
      //         )
      //       }
      //       return of([]);
      //     }))
      //   })
      // ).subscribe((inbox) => {
      //   vm.activityLoading = false;
      //   vm.activityLoadingMore = false;
      //   if (inbox.length == 0) {
      //     vm.clearActivityButton = true;
      //   }
      //   inbox.forEach((i) => {
      //     if (i.length == undefined && i.entity != 'invoice') {
      //       vm.data.push(i)
      //     }
      //   });
      // });
      await from(ref.get()).pipe(
        switchMap(inbox => {
          if (inbox.empty || inbox.docs.length < 10) vm.allActivityLoaded = true;
          if (inbox.empty) return of([]);
          vm.lastActivityDoc = inbox.docs[inbox.docs.length - 1];
          return combineLatest(...inbox.docs.map(doc => {
            if (doc.data().commodityID != undefined) {
              return docData(db.collection('commodities').doc(doc.data().commodityID)).pipe(
                map(t => {
                  return { ...doc.data(), id: doc.id, commodity: { ...t } }
                })
              )
            } else if (doc.data().productionContractID != undefined && (doc.data().entity == "productionContract" || doc.data().entity == "production_contract_negotiation" || doc.data().entity == "complete_production_contract_execute")) {
              return docData(db.collection('production_contract').doc(doc.data().productionContractID)).pipe(
                map(t => {
                  return { ...doc.data(), id: doc.id, productionContract: { ...t } }
                })
              )
            } else if (doc.data().bidRequestID != undefined && (doc.data().entity == "bidRequest" || doc.data().entity == "bid_request_negotiation" || doc.data().entity == "complete_bid_request_execute")){
              return docData(db.collection('bid_request').doc(doc.data().bidRequestID)).pipe(
                map(t => {
                  return { ...doc.data(), id: doc.id, bidRequest: { ...t } }
                })
              )
            }
          }))
        })
      ).pipe(
        switchMap(inbox => {
          if (inbox.length == 0) return of([]);
          return combineLatest(...inbox.map(c => {
            if (c.commodity && c.commodity.type) {
              return docData(db.collection('commodity_types').doc(c.commodity.type)).pipe(
                map(t => {
                  let typeName = (t.name) ? t.name : '';
                  return { ...c, commodity: { ...c.commodity, type: typeName } }
                })
              )
            } else if (c.productionContract && c.productionContract.type) {
              return docData(db.collection('commodity_types').doc(c.productionContract.type)).pipe(
                map(t => {
                  let typeName = (t.name) ? t.name : '';
                  return { ...c, productionContract: { ...c.productionContract, type: typeName } }
                })
              )
            } else if (c.bidRequest && c.bidRequest.type){
              return docData(db.collection('commodity_types').doc(c.bidRequest.type)).pipe(
                map(t => {
                  let typeName = (t.name) ? t.name : '';
                  return { ...c, bidRequest: { ...c.bidRequest, type: typeName } }
                })
              )
            }
            return of([]);
          }))
        })
      ).pipe(
        switchMap(inbox => {
          if (inbox.length == 0) return of([]);
          return combineLatest(...inbox.map(c => {
            if (c.commodity && c.commodity.subtype) {
              return docData(db.collection('commodity_subtypes').doc(c.commodity.subtype)).pipe(
                map(t => {
                  let subTypeName = (t.name) ? t.name : '';
                  return { ...c, commodity: { ...c.commodity, subtype: subTypeName } }
                })
              )
            } else if (c.productionContract && c.productionContract.subtype) {
              return docData(db.collection('commodity_subtypes').doc(c.productionContract.subtype)).pipe(
                map(t => {
                  let subTypeName = (t.name) ? t.name : '';
                  return { ...c, productionContract: { ...c.productionContract, subtype: subTypeName } }
                })
              )
            } else if(c.bidRequest && c.bidRequest.subtype){
              return docData(db.collection('commodity_subtypes').doc(c.bidRequest.subtype)).pipe(
                map(t => {
                  let subTypeName = (t.name) ? t.name : '';
                  return { ...c, bidRequest: { ...c.bidRequest, subtype: subTypeName } }
                })
              )
            }
            return of([]);
          }))
        })
      ).subscribe((inbox) => {
        vm.activityLoading = false;
        vm.activityLoadingMore = false;
        if (inbox.length == 0) {
          vm.clearActivityButton = true;
        }
        inbox.forEach((i) => {
          if (i.length == undefined && i.entity != 'invoice') {
            vm.data.push(i)
          }
        });
      });
    },
    async loadInbox() {
      let vm = this;
      let ref = db.collection('inbox');
      ref = ref.where('to', '==', (vm.user.role.admin ? 'admin' : vm.user.uid)).where('is_restore','==', null).orderBy('created', 'desc').limit(10);
      if (vm.lastActivityDoc) {
        ref = ref.startAfter(vm.lastActivityDoc);
        vm.activityLoadingMore = true;
      } else {
        vm.activityLoading = true;
      }
      await vm.loadInboxItems(ref);
    },
    choose(id) {
      this.commodityID = id;
    },
    formatNumeral(amount, format) {
      return numeral(amount).format(format);
    },
    primaryImage(images) {
      let arr = images.filter(i => i.name);
      if (arr.length == 0) return { thumbURL: '/img/NGF_NO_IMAGE_45x45.png', largeURL: '/img/NGF_NO_IMAGE.png' }
      arr = arr.filter(i => i.primary);
      if (arr.length == 0) return images[0];
      else return arr[0];
    },
    formatDate(d) {
      if (d) {
        d = d.toDate();
        if (moment().diff(d, 'minutes') < 1) return moment().diff(d, 'seconds') + ' secs ago';
        if (moment().diff(d, 'hours') < 1) return moment().diff(d, 'minutes') + ' mins ago';
        if (moment().diff(d, 'days') < 1) return moment().diff(d, 'hours') + ' hrs ago';
        return moment(d).format('ll');
      } else {
        return '';
      }
    },
    async clearIndividualLatestActivity(activity) {
      const vm = this;
      if (confirm('Are you sure you want to clear this activity?')) {
        vm.clearActivityButton = true;
        vm.activityLoading = true;
        let ref = await db.collection('inbox').doc(activity.id);
        ref.delete();
        setTimeout(() => {
          vm.clearActivityButton = false;
          vm.data = [];
          vm.listings = [];
          vm.lastActivityDoc = null;
          vm.load();
        }, 1000);
      }
    },
    async clearActivity() {
      const vm = this;
      if (confirm('Are you sure you want to clear this latest activity?')) {
        vm.clearActivityButton = true;
        vm.activityLoading = true;
        await Array.from(Array(vm.data.length).keys()).map(async (x) => {
          let messageId = vm.data[x].id;
          let ref = await db.collection('inbox').doc(messageId);
          ref.delete();
        });
        setTimeout(() => {
          vm.clearActivityButton = false;
          vm.data = [];
          vm.listings = [];
          vm.lastActivityDoc = null;
          vm.load();
        }, 1000);
      }
    },


    async clearBidRequests(){
      const vm = this;
      if (confirm('Are you sure you want to clear this newest product bit request?')) {
          vm.clearBidRequestsButton = true;
          let ref = db.collection('bid_request').orderBy('created', 'desc')
          ref.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({ is_newest: 0 })
          });
        });
        vm.clearBidRequestsButton = false;
        vm.listingsBidRequest = true;
        vm.myBidRequest = [];
        setTimeout(() => {
          vm.loadMyBidRequest(true);
        }, 1000);
      }
    },

    async clearIndividualBidRequest(bidRequest) {
      const vm = this;
      if (confirm('Are you sure you want to clear this newest Product Bid Request?')) {
        vm.clearBidRequestsButton = true;
        let ref = db.collection('bid_request').doc(bidRequest.id);
        ref.get().then((doc) => {
          doc.ref.update({ is_newest: 0 });
        });
        vm.clearBidRequestsButton = false;
        vm.listingsBidRequest = true;
        vm.myBidRequest = [];
        setTimeout(() => {
          vm.loadMyBidRequest();
        }, 1000);
      }
    },

    async clearProductionContracts() {
      if (confirm('Are you sure you want to clear this newest production contract?')) {
        this.clearProductionContractButton = true;
        let ref = db.collection('production_contract').orderBy('created', 'desc')
        ref.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({ is_newest: 0 })
          });
        });
        this.clearProductionContractButton = false;
        this.listingsProductionContract = true;
        this.myProductionContract = [];
        setTimeout(() => {
          this.loadMyProductionContract(true);
        }, 1000);
      }
    },

    async clearIndividualProductContract(productionContract) {
      if (confirm('Are you sure you want to clear this newest Production contract?')) {
        this.clearProductionContractButton = true;
        let ref = db.collection('production_contract').doc(productionContract.id);
        ref.get().then((doc) => {
          doc.ref.update({ is_newest: 0 });
        });
        this.clearProductionContractButton = false;
        this.listingsProductionContract = true;
        this.myProductionContract = [];
        setTimeout(() => {
          this.loadMyProductionContract();
        }, 1000);
      }
    },

    async clearProducts() {
      if (confirm('Are you sure you want to clear this newest Products?')) {
        this.clearProductsButton = true;
        let ref = db.collection('commodities').orderBy('created', 'desc')
        ref.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({ is_newest_product: 0 })
          });
        });
        this.clearProductsButton = false;
        this.listingsLoading = true;
        this.listings = [];
        setTimeout(() => {
          this.loadListings();
        }, 1000);
      }
    },
    async clearIndividualProducts(product) {
      if (confirm('Are you sure you want to clear this newest Product?')) {
        this.clearProductsButton = true;
        let ref = await db.collection('commodities').doc(product.id);
        ref.get().then((doc) => {
          doc.ref.update({ is_newest_product: 0 });
        })
        this.clearProductsButton = false;
        this.listingsLoading = true;
        this.listings = [];
        setTimeout(() => {
          this.loadListings();
        }, 1000);
      }
    },

    productionContractDetails(productionContractID) {
      const vm = this;
      if (vm.user.role.admin) {
        vm.$router.push('/production/contract/edit/' + productionContractID)
      } else {
        vm.$router.push('/production/contract/' + productionContractID)
      }
    },

    bidRequestDetails(productionContractID) {
      const vm = this
      if (vm.user.role.admin) {
        vm.$router.push('/bid/request/edit/' + productionContractID)
      } else {
        vm.$router.push('/bid/request/' + productionContractID)
      }
    },

    async load() {
      const vm = this;
      vm.loading = true;
      await vm.loadInbox();
      await vm.loadListings();


      if(!vm.user.role.admin){
        await vm.loadSavedCommodities();
        // await vm.loadMyProductNegotiation();
        await vm.loadMyProductionContract(false);
        await vm.loadMyBidRequest(false);
        // await vm.loadProductNegotiation();
        // await vm.loadMyProductionContractNegotiation();
        // await vm.loadMyBidRequestNegotiation(); 
      } else {
        await vm.loadMyProductionContract(true);
        await vm.loadMyBidRequest(true);
      }


      // if () {
      //   await vm.loadSavedCommodities();
      //   await vm.loadMyProductNegotiation();
      //   await vm.loadMyProductionContract(false);
      //   await vm.loadMyBidRequest(false);
      //   // await vm.loadProductionContractNegotiation();
      // } 
        
      // if (vm.user.role.producer) {
      //   await vm.loadMyProductNegotiation();
      //   await vm.loadMyProductionContract(false);
      //   // await vm.loadProductNegotiation();
      //   await vm.loadMyProductionContractNegotiation();
      //   await vm.loadMyBidRequestNegotiation();
      //   await vm.loadMyBidRequest(false);
      //   // await vm.loadProductionContract();
      // }
      
      // if (vm.user.role.admin) {
      //   await vm.loadMyProductionContract(true);
      //   await vm.loadMyBidRequest(true);
      // }

      vm.loading = false;
      bus.$on('backToMarketplace', () => {
        if (vm.$refs.CommodityView && !vm.$refs.CommodityView.purchasing) {
          vm.commodityID = null;
          bus.$emit('prepareTitle', null);
          return
        }
        vm.orderID = null;
        bus.$emit('prepareTitle', null);
      });
      vm.commodityID = null;
      vm.orderID = null;
      bus.$emit('prepareTitle', null);
    }
  },
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      data: [],
      allActivities: [],
      listings: [],
      saved: [],
      myProductNegotiation: [],
      myProductionContractNegotiation: [],
      myGrainOrders: [],
      producerProductNegotiation: [],
      productionContractNegotiation: [],
      myProductionContract: [],
      myBidRequest: [],
      myBidRequestNegotiation: [],
      commodityID: null,
      orderID: null,
      loading: true,
      activityLoading: true,
      activityLoadingMore: false,
      commoditiesLoading: false,
      listingsLoading: true,
      listingsProductNegotiation: true,
      listingsProductionContractNegotiation: true,
      listingBidRequestNegotiation: true,
      listingsBidRequest: true,
      listingsProductionContract: true,
      listingGrainOrders: true,
      listingProducerProductNegotiations: true,
      listingProductionContractNegotiations: true,
      lastActivityDoc: null,
      allActivityLoaded: false,
      clearActivityButton: false,
      clearProductsButton: false,
      clearProductionContractButton: false,
      clearBidRequestButton: false,
    };
  },
  async mounted() {
    const vm = this;
    if (vm.user.uid) {
      vm.load();
    }
  },
  watch: {
    user() {
      const vm = this;
      if (vm.user.uid) {
        vm.load();
      }
    }
  }

};
</script>
<style>.dash-card-header .card .card-header {
  padding: 10px 15px 10px;
  border: 0;
  background: transparent !important;
  border-bottom: 2px solid #7ec313;
  color: #000;
}

.dash-card-header .card .card-body {
  padding: 0px;
}

.dash-card-header .card .card-body .card-body {
  padding: 15px 15px 10px 15px;
}

.img-dashboard-large-preview {
  width: 56px !important;
  height: 56px !important;
}

.activity-image {
  flex: none;
}

.clear-button {
  padding: 6px 14px;
  font-size: 14px;
  line-height: 14px;
}

.clear-button-text {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .clear-button {
    font-size: 16px;
  }

  .clear-button-text {
    font-size: 16px;
  }
}

.add-pointer {
  cursor: pointer;
}

.new-product-img {
  max-width: 120px;
  max-height: 120px;
  overflow: hidden;
  margin: 0 auto;
}

.new-product-img img {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
  width: 120px;
  height: 120px;
}

.close-product-button {
  top: 0px;
  right: 5px;
  padding: 5px 8px;
  display: none;
}
.new-product:hover .close-product-button {
  display: block;
}
.new-product-list .list-group-item + .list-group-item{
  border-top-width: 1px;
}
@media only screen and (max-width: 767px) {
  .activity-image {
    max-width: 90px;
    margin: auto;
  }
.close-product-button {
  display: block;
}
.new-product-list h5 {font-size:1.1em !important;}
}

.notification-icon {
  top: -4px;
  left: -4px;
}
</style>
