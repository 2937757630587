<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else>
            <b-card>
                <b-card-header>
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="m-0">Product Wish Lists</h5>
                    </div>
                </b-card-header>
                <b-card-body>
                    <b-list-group>
                        <b-alert v-if="error" show variant="danger"><small>{{error}}</small></b-alert>
                        <template v-if="myProductWishList.length > 0"> 
                            <b-list-group-item @click="redirectProductDetails(item)" :key="item.id" v-for="item in myProductWishList" class="d-block d-md-flex align-items-center add-pointer">
                                <div class="position-relative activity-image">
                                    <b-img class="d-block d-md-none" v-if="item.images" :src="primaryImage(item.images).largeURL" thumbnail />
                                    <b-img class="d-none d-md-block img-dashboard-large-preview" v-if="item.images" :src="primaryImage(item.images).thumbURL" thumbnail />
                                </div>
                                <div class="text-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                                    <h6 class="mt-2 m-md-0">{{item.subtype}} {{item.type}} </h6>
                                    <small class="text-muted d-block">{{numeral(item.total).format('0,000')}} {{item.unit}}</small>
                                </div>
                                <div class="text-right mt-2 mt-md-0">
                                    <small>{{formatDate(item.created)}}</small>
                                    <b-button @click.stop="removeProductFromWishList(item)" class="clear-button" variant="danger" size="sm"  :disabled="removeWishList">Remove</b-button>
                                </div>
                            </b-list-group-item>
                        </template>
                        <template v-else>
                            <b-list-group-item>
                                <p class="text-muted m-0">You have not any wish list to your profile.</p>
                            </b-list-group-item>
                        </template>
                        <b-list-group-item v-if="!allActivityLoaded" :disabled="activityLoadingMore" @click="getMyProductWishLists" button variant="success" class="text-center"><span class="fa fa-spinner" v-if="activityLoadingMore"></span><span v-else class="fa fa-plus-circle mr-2"></span> <strong>Show More</strong></b-list-group-item>
                    </b-list-group>
                </b-card-body>
            </b-card>
        </div>
    </div>
</template>
<script>
import {db} from '@/firebase';
import { collectionData, docData } from 'rxfire/firestore';
import { map, switchMap} from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import numeral from 'numeral';
import Vue from "vue";
import {mapState} from 'vuex'
import moment from 'moment';



Vue.prototype.numeral = numeral;
Vue.prototype.moment = moment;


export default {
    data(){
        return {
            loading: true, 
            allActivityLoaded: true,
            activityLoadingMore: false,
            myProductWishList: [],
            removeWishList: false,
            error: '',
        }
    },
    computed:{
        ...mapState(['user']),
    },
    mounted() {
        this.getMyProductWishLists();
    },
    methods: {
        redirectProductDetails(product){
            if(product.active){
                this.$router.push('/commodity/view/'+product.id)
            } else {
                this.error =  product.subtype+" "+ product.type + "is currently inactive.";
                setTimeout(() => {
                    this.error = '';                    
                }, 5000);
            }
            
        },
        primaryImage(images){
            let arr = images.filter(i=>i.name);
            if(arr.length==0) return {thumbURL:'/img/NGF_NO_IMAGE_45x45.png',largeURL:'/img/NGF_NO_IMAGE.png'}
            arr = arr.filter(i=>i.primary);
            if(arr.length==0) return images[0];
            else return arr[0];
        },
        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
        async removeProductFromWishList(item){
            if(confirm('Are you Sure want to remove this product from Product Wish Lists?')){
                let vm = this;
                vm.loading = true;
                vm.activityLoadingMore = false;
                let ref = await db.collection('commodity_bookmarks').doc(item.id);
                if(ref.exists){
                    ref.delete().then(() => {
                        vm.loading = false;
                        vm.myProductWishList = [];
                        vm.activityLoadingMore = false;
                        setTimeout(() => {
                            vm.getMyProductWishLists();    
                        }, 1000);
                    })
                } else {
                    vm.loading = false;
                    vm.myProductWishList = [];
                    vm.activityLoadingMore = false;
                    setTimeout(() => {
                        vm.getMyProductWishLists();    
                    }, 1000);
                }
            }
        },
        async getMyProductWishLists(){
            let vm = this;
            let ref = await db.collection('commodity_bookmarks');
            ref = ref.where('userID','==',vm.user.uid);
            ref = ref.orderBy('created','desc');
            collectionData(ref,'id').pipe(
                switchMap(listings=>{
                    if(listings.length==0) return of([]);
                    if(listings.length > 10) { vm.allActivityLoaded = true }
                    return combineLatest(...listings.map(c=>{
                        return docData(db.collection('commodities').doc(c.commodityID)).pipe(
                            map(c=>{
                                if(JSON.stringify(c) !== '{}'){
                                    return {...c}
                                } else {
                                    return of({});
                                }
                                
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(listings=>{
                    if(listings.length==0) return of([]);
                        return combineLatest(...listings.map(c=>{
                            if(c && c.type){
                                return docData(db.collection('commodity_types').doc(c.type)).pipe(
                                    map(t=>{
                                        return {...c,type:t.name}
                                    })
                                )              
                            } else{
                                return of({...c,type:''})
                            }
                    }))
                })
            ).pipe(
                switchMap(listings=>{
                if(listings.length==0) return of([]);
                    return combineLatest(...listings.map(c=>{
                        if(c.subtype && c){
                            return docData(db.collection('commodity_subtypes').doc(c.subtype)).pipe(
                                map(t=>{
                                    return {...c,subtype:t.name}
                                })
                            )
                        } else {
                            return of({...c,subtype:''})
                        }
                    }))
                })
            ).pipe(
                switchMap(commodities=>{
                if(commodities.length==0) return of([]);
                    return combineLatest(...commodities.map(c=>{
                        if(c && c.unit){
                            return docData(db.collection('units').doc(c.unit)).pipe(
                                map(t=>{
                                     return {...c,unit:t.name}
                                })
                            )
                        } else {
                            return of({...c,unit:''})
                        }
                    }))
                })
            ).subscribe((inbox)=>{
                if(inbox.length == 0){
                     vm.clearActivityButton = true;
                }
                inbox.forEach((i)=> {
                    if(i.length == undefined && i.type != ''){
                        vm.myProductWishList.push(i)
                    } 
                });
            });
            vm.loading = false;
        }
    }
}
</script>
