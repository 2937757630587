<template>
    <div class="row">
      <div class="col-12 position-relative pt-1 pb-2" v-if="specification.id !== null">
        <a :disabled="deleting" @click="deleteFile" class="text-danger position-absolute" style="left:-10px;"><span :class="'fa '+(deleting?'fa-spinner':'fa-times')"></span></a>
        <strong class="mr-2">{{specification_name}}</strong>
        <template v-if="specification.more_information">
          <strong class="ml-2">|</strong>
          <strong class="ml-2">{{ specification.more_information }}</strong>
        </template>
        <em v-if="specification.file" class="text-muted ml-2">{{specification.file.name}}</em>
        <em v-else class="text-muted ml-2">{{specification.url}}</em>
      </div>
    </div>
  </template>
  <script>
  import {db} from '@/firebase';
  import {firestorage} from '@/firebase';
  export default {
    data() {
      return {
        deleting:false,
        specification_name:null
      }
    },
    props:[
      'specification'
    ],
    computed:{
      
    },
    methods:{
      async getCertName() {
        let ref = await db
            .collection("specifications")
            .doc(this.$props.specification.specification)
            .get();
        this.specification_name = ref.data().name;
        this.$emit('cert-add', this.specification_name);
      },
  
      async deleteFile(){
        this.deleting=true;
        console.log(this.$props.specification.specification)
        if(this.$props.specification.specification === "ERCF1yIbUxKiESnbjdPa") this.$emit('cert-del', "Standard Grade Test Missing!")
        if(this.specification.file){
          try{
            let storageRef = firestorage.ref(this.specification.file.fullPath);
            await storageRef.delete();
          }catch(e){
            console.log(e);
          }
        }
        await db.collection('production_contract_certifications').doc(this.specification.id).delete();
        this.$emit('remove',this.specification.id);
      }
    },
     mounted() {
      return this.getCertName();
    }
  }
  </script>
  