<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else class="row custom-card-header">
            <div class="col-12" v-if="user.about">
                <b-card>
                    <b-card-header>
                        <div class="d-flex flex-row justify-content-between flex-wrap">
                            <h3 class="m-0">About</h3>
                            <div v-if="user.about">
                                <div v-if="user.about.is_active == undefined">
                                    <button size="sm" @click.stop="updateUsersStatus(true)" type='button' :class="'float-left m-0 btn btn-'+(false?'primary':'outline-primary')"><i :class="'fa fa-'+(false?'toggle-on':'toggle-off')+' mr-2'"></i> ACTIVE</button>
                                    <b-button variant="danger" class="ml-2" @click.stop="deleteUser">Delete</b-button>
                                </div> 
                                <div v-else>
                                    <button size="sm" @click.stop="updateUsersStatus(!user.about.is_active)" type='button' :class="'float-left m-0 btn btn-'+(user.about.is_active?'primary':'outline-primary')"><i :class="'fa fa-'+(user.about.is_active?'toggle-on':'toggle-off')+' mr-md-2 '"></i> <span class="d-none d-md-inline-block">ACTIVE</span></button>
                                    <b-button variant="danger" class="ml-2 m-0" @click.stop="deleteUser"><i class="fa fa-trash d-md-none"></i><span class="d-none d-md-inline-block">Delete</span></b-button>
                                </div>
                            </div>
                        </div>
                    </b-card-header>
                <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                        <small>First Name</small>
                        <div v-if="user.about.first">
                            <strong>{{ user.about.first }}</strong>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                        <small>Last Name</small>
                        <div v-if="user.about.last">
                            <strong>{{ user.about.last }}</strong>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 pt-2">
                        <small>Email</small>
                        <div v-if="user.about.email">
                            <strong>{{ user.about.email }}</strong>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 pt-2">
                        <small>Phone No.</small>
                        <div v-if="user.about.phone">
                            <strong>{{ user.about.phone }}</strong>
                        </div>
                    </div>
                </div>
                </b-card>
            </div>
            <div class="col-12" v-if="user.business">
                <b-card>
                    <b-card-header>
                        <div class="d-flex flex-row justify-content-between">
                            <h3 class="m-0">
                                Business 
                            </h3>
                        </div>
                    </b-card-header>
                    <div class="row">
                        <div class="col-12 col-md-6 pt-2">
                            <small>Company Name</small>
                            <div v-if="user.business.name"><strong>{{ user.business.name }}</strong></div>
                        </div>
                        <div class="col-12 col-md-6 pt-2">
                            <small>User Type</small>
                            <div v-if="user.business.type"><strong>{{ user.business.type }}</strong></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-2">
                            <div v-if="user.business.address">
                                <small>Address</small><br />
                                <strong class="d-block">{{user.business.address.address}}</strong>
                                <strong class="d-block">{{user.business.address.city}}, {{user.business.address.state}} {{ user.business.address.zip}}</strong>
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>    
</template>

<script>
import { db } from '@/firebase';
import {bus} from '@/vuex/bus.js';

export default {
    data() {
        return {
            UserId: null,
            user: null,
            loading: true,
        }
    },
    async mounted() {
        this.getUser();
        bus.$emit('prepareTitle', null);
    },
    methods: {
        async getUser(){
            let vm = this;
            if(vm.$route.params.id) vm.UserId=vm.$route.params.id;
            let ref = await db.collection('users').doc(vm.UserId).get();
            if(ref.exists) vm.user = {...ref.data()};
            vm.loading = false;
        },
        async updateUsersStatus(status){
            let userStatus = "Active";
            if(!status) userStatus ="De-Active";           
            if(confirm('Are you sure want to this User ' + userStatus +'?')){
                await db.collection('users').doc(this.UserId)
                .update({"about.is_active": status}).then(() => {
                    this.getUser();
                });
            }
        }, 
        async deleteUser(){
            if(confirm('Are you sure want to this Delete User ?')){
                await db.collection("users").doc(this.UserId).delete().then(() => {
                    this.$router.replace('/users');
                });
            }
        }
    },
}
</script>
<style>
.custom-card-header .card .card-header {
    padding: 0px 0px 10px;
    border: 0;
    background: transparent !important;
    border-bottom: 2px solid #7ec313;
    color: #000;
}
.card .card-header {
    padding: 5px 15px 5px;
    border: 0;
    color:#fff;
}
</style>