var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[(_vm.loading)?_c('b-spinner'):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"pl-4 pr-4 pt-0 pt-md-4"},[_c('h3',{staticClass:"d-block d-md-none"},[_vm._v("About")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.about.first","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-form-group',{attrs:{"invalid-feedback":'Please enter a first name of at least 2 characters',"state":(failed?false:(passed?true:null))}},[_c('b-form-input',{attrs:{"placeholder":"First name (required)","state":(failed?false:(passed?true:null))},model:{value:(_vm.signup.about.first),callback:function ($$v) {_vm.$set(_vm.signup.about, "first", $$v)},expression:"signup.about.first"}})],1)]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.about.last","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('b-form-group',{attrs:{"invalid-feedback":'Please enter a last name of at least 2 characters',"state":(failed ?false:(passed?true:null))}},[_c('b-form-input',{attrs:{"placeholder":"Last name (required)","state":(failed?false:(passed?true:null))},model:{value:(_vm.signup.about.last),callback:function ($$v) {_vm.$set(_vm.signup.about, "last", $$v)},expression:"signup.about.last"}})],1)]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.about.phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
return [_c('b-form-group',{attrs:{"invalid-feedback":'Please enter a valid phone',"state":(!_vm.isValidPhoneNumber?false:(passed?true:null))}},[_c('VuePhoneNumberInput',{ref:"phoneNumber",attrs:{"noValidatorState":true,"borderRadius":30,"valid-color":"#28a745","color":"#7ec313","placeholder":"Phone (required)","state":(!_vm.isValidPhoneNumber?false:(passed?true:null))},on:{"update":_vm.updatePhoneProfile},model:{value:(_vm.signup.about.phone),callback:function ($$v) {_vm.$set(_vm.signup.about, "phone", $$v)},expression:"signup.about.phone"}})],1)]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 pb-2"},[_c('ValidationProvider',{attrs:{"name":"signup.about.email","rules":"emailRequired|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"invalid-feedback":errors.length>0?errors[0]:null,"state":(failed?false:(passed?true:null))}},[_c('b-form-input',{attrs:{"placeholder":"Email (required)","state":(failed?false:(passed?true:null))},model:{value:(_vm.signup.about.email),callback:function ($$v) {_vm.$set(_vm.signup.about, "email", $$v)},expression:"signup.about.email"}})],1)]}}])})],1)])])]),_c('b-modal',{attrs:{"size":"md","title":"Change Email"},model:{value:(_vm.enterPassword),callback:function ($$v) {_vm.enterPassword=$$v},expression:"enterPassword"}},[_c('div',{staticClass:"personal-details"},[_c('b-alert',{staticClass:"mt-2 mb-2",attrs:{"variant":"info","show":""}},[_vm._v("Changing your email address requires you to reenter your password.")]),_c('div',{staticClass:"mt-4 mb-4",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"input-live"}},[_vm._v("ENTER YOUR PASSWORD")]),_c('b-form-input',{attrs:{"id":"changeEmailPassword","type":"password","placeholder":"","trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('template',{slot:"modal-footer"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function (){this$1.signup.about.email=this$1.user.about.email;this$1.enterPassword=false;}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validate}},[_vm._v("Change")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }