<template>
  <div class="row">
    <div class="col-12 position-relative pt-1 pb-2 ml-4" v-if="certification.id !== null">
      <a :disabled="deleting" @click="deleteFile" class="text-danger position-absolute" style="left:-10px;"><span :class="'fa '+(deleting?'fa-spinner':'fa-times')"></span></a>
      <strong class="mr-2">{{certification_name}}</strong>
      <template v-if="certification.more_information">
        <strong class="ml-2">|</strong>
        <strong class="ml-2">{{certification.more_information}}</strong>
      </template>
      <em v-if="certification.file" class="text-muted ml-2">{{certification.file.name}}</em>
      <em v-else class="text-muted ml-2">{{certification.url}}</em>
    </div>
  </div>
</template>
<script>
import {db} from '@/firebase';
import {firestorage} from '@/firebase';
export default {
  data() {
    return {
      deleting:false,
      certification_name:null
    }
  },
  props:[
    'certification'
  ],
  computed:{
    
  },
  methods:{
    async getCertName() {
      let ref = await db
          .collection("certifications")
          .doc(this.$props.certification.certification)
          .get();
      this.certification_name = ref.data().name;
      this.$emit('cert-add', this.certification_name);
    },

    async deleteFile(){
      this.deleting=true;
      if(this.$props.certification.certification === "ERCF1yIbUxKiESnbjdPa") this.$emit('cert-del', "Standard Grade Test Missing!")
      if(this.certification.file){
        try{
          let storageRef = firestorage.ref(this.certification.file.fullPath);
          await storageRef.delete();
        }catch(e){
          console.log(e);
        }
      }
      if(this.certification.id !== null){
        await db.collection('commodity_certifications').doc(this.certification.id).delete();
        this.$emit('remove',this.certification.id);
      }
    }
  },
   mounted() {
    return this.getCertName();
  }
}
</script>
