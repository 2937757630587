<template>
    <div class="row">
      <div class="col-12 position-relative pt-1 pb-2" v-if="specification.id !== null">
        <a :disabled="deleting" @click="deleteFile" class="text-danger position-absolute" style="left:-10px;"><span :class="'fa '+(deleting?'fa-spinner':'fa-times')"></span></a>
        <strong class="mr-2">{{specification_name}}</strong>
        <template v-if="specification.more_information">
          <strong class="ml-2">|</strong>
          <strong class="ml-2">{{ specification.more_information }}</strong>
        </template>
        <em v-if="specification.file" class="text-muted ml-2">{{specification.file.name}}</em>
        <em v-else class="text-muted ml-2">{{specification.url}}</em>
      </div>
    </div>
  </template>
  <script>
  import {db} from '@/firebase';
  import {firestorage} from '@/firebase';
  export default {
    data() {
      return {
        deleting:false,
        specification_name:null
      }
    },
    props:[
      'specification'
    ],
    computed:{
      
    },
    methods:{
      async getCertName() {
        const vm = this;
        let ref = await db
            .collection("specifications")
            .doc(vm.$props.specification.specification)
            .get();
        vm.specification_name = ref.data().name;
        vm.$emit('cert-add', vm.specification_name);
      },
  
      async deleteFile(){
        const vm = this;
        vm.deleting=true;
        if(vm.specification.file){
          try{
            let storageRef = firestorage.ref(vm.specification.file.fullPath);
            await storageRef.delete();
          }catch(e){
            console.log(e);
          }
        }
        await db.collection('bidRequest_certifications').doc(vm.specification.id).delete();
        vm.$emit('remove',vm.specification.id);
      }
    },
     mounted() {
      const vm = this;
      return vm.getCertName();
    }
  }
  </script>
  