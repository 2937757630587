import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/assets/sass/now-ui-dashboard.scss'

import router from '@/lib/router.js';

import store from '@/vuex/store.js';
import {auth,db} from '@/firebase';
import moment from 'moment';

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "@/components/NotificationPlugin";
import SideBar from "@/components/SidebarPlugin";
import VueCurrencyInput from "vue-currency-input";


import { StripePlugin } from '@vue-stripe/vue-stripe';


Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(SideBar);
Vue.use(VueCurrencyInput);


const options = {
  pk: process.env.VUE_APP_STRIPE_KEY,
};


Vue.use(StripePlugin, options);


let app = '';
auth.onAuthStateChanged(async(user)=>{
  let subscriptionsRef = await db.collection('settings').doc('subscriptions').get();
  if(subscriptionsRef.exists) store.commit('updateSubscriptions',Object.assign({...subscriptionsRef.data()}));
  let timeoutRef = await db.collection('settings').doc('timeout').get();
  if(timeoutRef.exists) store.commit('updateTimeout', Object.assign({...timeoutRef.data()}));
  let limitOfMilesRef = await db.collection('settings').doc('limitOfMiles').get();
  if(limitOfMilesRef.exists) store.commit('updateLimitOfMiles', Object.assign({...limitOfMilesRef.data()}));
  if(user) {
    let token = await user.getIdTokenResult();
    let res = await db.collection("users").doc(user.uid).get();
    if(res.exists) user = {...res.data(),uid:user.uid};
    if(token) {
      user = {...user,...{
        role:{
          producer:(token.claims.producer?true:false),
          buyer:(token.claims.buyer?true:false),
          admin:(token.claims.admin?true:false)
        }
      }};
    }
    if(!user.role.producer&&!user.role.buyer&&!user.role.admin){
      user = {...user,...{
        role:{
          [user.business.type]:true
        }
      }};
    }
    if(user.role.buyer&&store.state.subscriptions.active){
      res = await db.collection("user_subscriptions").doc(user.uid).get();
      if(res.exists) {
        user.subscription = {...res.data()};
      }else{
        user.subscription = {stripeSubscription:{status:'trialing',trial_end:moment().add(7,'days').unix()}};
        await db.collection("user_subscriptions").doc(user.uid).set({});
      }
    }
    store.commit('updateUser',Object.assign({...user}));
  }
  if(!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
});
