<template>
  <div class="col-md-6 col-lg-4 ml-auto mr-auto">
    <div v-if="showForgot" class="card rounded bg-white">
      <ForgotPassword @return="showForgot=false" />
    </div>
    <div v-else class="card login-card rounded bg-white">
      <div class="card-header bg-primary text-center pb-2">
        <img slot="header" src="/img/logo.png" class="img-fluid" />
        <h3 class="card-title text-white">Login</h3>
      </div>

      <div class="card-body">
        <p v-if="loginError" class="alert alert-danger mb-4">{{loginError}}</p>
        <fg-input
          type="email"
          class="no-border form-control-lg"
          placeholder="Email"
          addon-left-icon="now-ui-icons ui-1_email-85"
          v-model="email"
          @keyup.enter.native="login"
        >
        </fg-input>

        <div class="d-flex">
          <fg-input
            :type="passwordType"
            class="no-border form-control-lg w-100"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
            :addon-right-icon="'fa '+passwordIcon"
            v-model="password"
            inputClasses="rounded-0"
            @keyup.enter.native="login"
            @rightclick="togglePasswordShow"
          >
          </fg-input>

        </div>
        <p class="mt-2 text-center"><small><a @click="showForgot=true" class="text-primary add-pointer">Forgot your password?</a></small></p>
      </div>

      <div class="card-footer">
        <a @click="login" class="btn rounded btn-block btn-primary" :disabled="processing">
          <i class="fa fa-spinner mr-2" v-if="processing"></i>
          Login
        </a>
        <a href="/register" class="btn rounded btn-block btn-outline-primary mt-2">Join NGF Connect</a>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import {mapState} from 'vuex'
import store from '@/vuex/store.js';
import {bus} from "@/vuex/bus.js";
import {auth} from '@/firebase';
import {db} from '@/firebase';
export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loginError: null,
      processing:false,
      showForgot:false,
      passwordType:'password',
      passwordIcon:'fa-eye-slash'
    };
  },
  components:{
    ForgotPassword
  },
  computed:{
    ...mapState(['user'])
  },
  methods: {
    togglePasswordShow(){
      if(this.passwordType=='password') {
        this.passwordType='text';
        this.passwordIcon='fa-eye';
      } else {
        this.passwordType='password';
        this.passwordIcon='fa-eye-slash';
      }
    },
    async getUser(userId){
        let userData = "";
        let ref = await db.collection("users").doc(userId).get();
        if(ref.exists)  userData = {...ref.data()};
        return userData;
    },
    login() {
      let vm = this;
      vm.processing=true;
      return auth.signInWithEmailAndPassword(this.email, this.password)
      .then(async(userCredential)=>{
        let getUserData =  await this.getUser(userCredential.user.uid);
        if(getUserData){
            if(getUserData.business.type != "admin"){
                if(getUserData.about.is_active){
                  vm.processing=false;
                  this.loginError=null;
                  this.$router.replace((vm.$route.query&&vm.$route.query.redirect?vm.$route.query.redirect:'/'));
                } else {
                  vm.processing=false;
                  this.loginError= 'Your account was disabled. please contact to NGF-Global.'; 
                  this.logout();
                }
            } else {
              vm.processing=false;
              this.loginError=null;
              this.$router.replace((vm.$route.query&&vm.$route.query.redirect?vm.$route.query.redirect:'/'));
            }
        } else {
          vm.processing=false;
          this.loginError= 'Your account does not exist. Please contact to NGF-Global.'; 
          this.logout();
        }
      }).catch((err)=>{
        vm.processing=false;
        this.loginError=err.message;
      });
    },
    async load() {
      if(this.user.uid){
        this.$router.replace('/');
      }
    }, 
    logout(){
      auth.signOut().then(() => {
        bus.$emit('UserLogout');
        store.commit('updateUser',{});
        this.$router.replace('/login')
      });
    },
  },
  mounted(){
    this.load();
  },
  watch: {
    user () {
      this.load();
    }
  }
};
</script>
<style>
.login-card .card-footer{
  position: relative !important; 
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.add-pointer {
  cursor: pointer;
}
.link {
  color: #007BFF !important;
  font-size: 0.875em;
  font-weight: 400;
}

.loginHeaderImage{
  width: 300px;
  margin-top: 14px;
}

</style>
