<template>
    <div>
        <div v-if="errors.length > 0">
            <b-alert show variant="danger">
                <small>{{ errors[0] }}</small>
            </b-alert>
        </div>
        <b-list-group>
            <b-list-group-item v-for="(item,index) in items" :key="item.id">
                <div class="input-group m-0">
                    <input type="text" class="form-control" v-on:change="saveItem(item, index, item.id)" v-model="item.name">
                    <div class="input-group-append">
                        <b-button variant="danger" @click="removeItem(index)"><span class="fa fa-times"></span></b-button>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <div class="input-group">
                    <input type="text" v-model="newItem" ref="specification" class="form-control" >
                    <div class="input-group-append">
                        <b-button @click="addItem()" variant="success"><span class="fa fa-plus"></span></b-button>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <b-button @click="save" :disabled="!hasChanges&&!saving" block variant="primary"><strong><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>SAVE</strong></b-button>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
import {db} from '@/firebase';
import Vue from "vue";

export default {
    name: 'ListBuilderPriceOptions',
    data(){
        return {
            items:[],
            removed:[],
            newItem:'',
            hasChanges:null,
            saving:null,
            errors:[],
        }
    },

    methods: {
        async loadItems(){
            this.items=[];
            let items = null;
            items = await db.collection('price_options').get();
            items.forEach((item)=>{
                this.items.push({...item.data(),id:item.id})
            });
        },

        async save(){
            this.saving=true;
            var batch = db.batch();
            this.items.forEach((item)=>{
                let ref = db.collection('price_options').doc(item.id);
                batch.set(ref,item);
            });
            this.removed.forEach(item=>{
                let ref = db.collection('price_options').doc(item.id);
                batch.delete(ref);
            });
            await batch.commit()
            this.hasChanges=false;
            this.saving=false;
        },

        async addItem(){
            let vm = this;
            if(this.newItem == ''){
                vm.errors.push('Please enter Validate price options')
                setTimeout(() => {
                    vm.errors = [];
                }, 4000);
                this.$emit('backToTop');
                return;
            }

            if(vm.checkDuplicateNameValidation() != 0){
                vm.errors.push('This name already exists in list.')
                setTimeout(() => {
                    vm.errors = [];
                }, 5000);
                this.$emit('backToTop');
                return
            }

            let ref = db.collection('price_options').doc()
            let data = {id:ref.id,name: this.newItem};
            this.items.push(data);
            vm.newItem='';
            vm.errors = [];
            vm.hasChanges=true;
            vm.$emit('saveList',vm.list);
        },

        checkDuplicateNameValidation(){
            return this.items.filter((item) => {
                return (item.name.toLowerCase() === this.newItem.toLowerCase());
            });
        },

        saveItem(item,index){
            let vm = this;
            Vue.set(vm.items,index,item);
            vm.hasChanges=true;
            vm.$emit('saveList',vm.list);
        },

        removeItem(index){
            let vm = this;
            vm.removed.push(vm.items[index]);
            vm.items = vm.items.filter((item,i)=>i!=index);
            vm.hasChanges=true;
            vm.$emit('saveList',vm.list);
        },
    },

    async mounted() {
        this.loadItems();
    }
   

}

</script>