<template>
  <div class="container">
    <div class="row pt-4 mt-4">
      <div class="col-12 col-md-6">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Payment Methods</strong></div>
          <div class="card-body p-1 p-lg-2">
            <div class="row no-gutters mt-2">
              <div class="col-12">
                <b-list-group>
                  <b-list-group-item @click="confirmChangeDefault(account)" class="list-group-item-action flex-column align-items-start" v-for="account in accounts" :active="account.default" :key="account.id">
                    <template v-if="account.new">
                      <div class="d-flex w-100">
                        <CreditCard @save="saveCreditCard" @cancel="cancelCreditCard" :stripe="stripe" :elements="elements" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="d-flex w-100">
                        <span class="pt-2"><input :disabled="account.new" type="radio" :checked="account.default" /></span>
                        <div class="d-flex justify-content-between w-100">
                          <span class="ml-4">Credit Card ending in <strong>{{account.card.last4}}</strong><br /><small :class="account.default?'text-white':'text-muted'">{{account.card.brand}}</small></span>
                          <span><b-button @click.stop="confirmRemoveAccount(account)" v-if="!account.default" size="sm" variant="outline-danger"><span class="fa fa-trash"></span></b-button><b-badge v-else variant="light">PRIMARY</b-badge></span>
                        </div>
                      </div>
                    </template>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </div>
            <div class="row no-gutters mt-2">
              <div class="col-12">
                <b-button :disabled="adding" @click="addCreditCard" size="sm" variant="info">Add Credit Card</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="subscription&&subscription.stripeSubscription" class="col-12 col-md-6">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Billing Summary</strong><b-badge v-if="subscription.stripeSubscription&&subscription.stripeSubscription.status" class="float-right mt-1" :variant="((subscription.stripeSubscription
            =='active'||subscription.stripeSubscription.status=='trialing')?'success':'danger')">{{subscription.stripeSubscription.status.toUpperCase()}}</b-badge><b-badge v-else class="float-right mt-1" variant="warning">INACTIVE</b-badge></div>
          <div class="card-body p-1 p-lg-2">
            <b-alert show v-if="subscription.stripeSubscription&&subscription.stripeSubscription.status=='trialing'" class="p-2" variant="info"><small>Trialing until <strong>{{moment.unix(subscription.stripeSubscription.trial_end).format('LL')}}</strong>.</small></b-alert>
            <div class="row no-gutters border-top mt-2 mb-2 pt-2">
              <div class="col-12 col-md-8 p-1 pl-2 bg-dark">
                <strong class="text-white">Monthly Total</strong>
              </div>
              <div class="col-12 col-md-4 text-right p-1 pr-2 bg-dark text-white">
                <strong v-if="subscription.stripeSubscription">${{numeral(parseInt(subscription.stripeSubscription.items.data[0].price.unit_amount)/100).format('0,0.00')}}</strong>
                <strong v-else>$100</strong>
              </div>
            </div>
          </div>
          <div v-if="subscription&&subscription.stripeSubscription" class="card-footer p-1 p-lg-2">
            <div class="row">
              <div class="col-6"><small>Next invoice</small><br />${{numeral(parseInt(subscription.stripeSubscription.items.data[0].price.unit_amount)/100).format('0,0.00')}} on {{(subscription.stripeSubscription.status!='active'&&subscription.stripeSubscription.status!='trialing'?moment.unix(subscription.stripeSubscription.current_period_start).format('ll'):moment.unix(subscription.stripeSubscription.current_period_end).format('ll'))}}</div>
              <div class="col-6 border-left"><small>Current Period</small><br />{{moment.unix(subscription.stripeSubscription.current_period_start).format('ll')}} to {{moment.unix(subscription.stripeSubscription.current_period_end).format('ll')}}</div>
            </div>
            <div class="row mt-2" v-if="subscription.stripeSubscription.status=='trialing'||subscription.stripeSubscription.status=='active'">
              <div class="col-12">
                <b-button @click="showCancelSubscriptionModal=true" block size="sm" variant="danger">Cancel My Subscription</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="subscription&&subscription.cancelled" class="col-12 col-md-6">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Billing Summary</strong><b-badge class="float-right mt-1" variant="danger">CANCELLED</b-badge></div>
          <div class="card-footer p-1 p-lg-2">
            <div class="row mt-2">
              <div class="col-12">
                <b-button @click="reactivateSubscription" block size="sm" variant="success" :disabled="reactivating"><span v-if="reactivating" class="fe fe-loader mr-1"></span>Reactivate My Subscription</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="subscription&&subscription.reactivate" class="col-12 col-md-6">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Billing Summary</strong><b-badge class="float-right mt-1" variant="success">REACTIVATING</b-badge></div>
          <div class="card-body p-1 p-lg-2">
            <b-alert show class="p-2" variant="success">Thank you. We are currently reactivating your account.</b-alert>
          </div>
        </div>
      </div>
      <div v-else class="col-12 col-md-6">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Billing Summary</strong><b-badge class="float-right mt-1" variant="danger">INACTIVE</b-badge></div>
          <div class="card-body p-1 p-lg-2">
            <div class="row no-gutters border-top mt-2 mb-2 pt-2">
              <div class="col-12 col-md-8 p-1 pl-2 bg-dark">
                <strong class="text-white">Monthly Total</strong>
              </div>
              <div class="col-12 col-md-4 text-right p-1 pr-2 bg-dark text-white">
                <strong>$100</strong>
              </div>
            </div>
          </div>
          <div class="card-footer p-1 p-lg-2">
            <div class="row mt-2">
              <div class="col-12">
                <b-button @click="reactivateSubscription" block size="sm" variant="success" :disabled="reactivating"><span v-if="reactivating" class="fe fe-loader mr-1"></span>Activate My Subscription</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-4 mt-4" v-if="subscription&&subscription.invoices">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-header p-1 p-lg-2"><strong>Invoices</strong></div>
          <div class="card-body p-1 p-lg-2">
            <b-table responsive="md" striped :items="subscription.invoices" :fields="invoiceFields">
              <template v-slot:cell(amount_due)="data">
                ${{numeral(parseFloat(data.item.amount_due/100)).format('0,0.00')}}
              </template>
              <template v-slot:cell(created)="data">
                {{moment.unix(data.item.created).format('LL')}}
              </template>
              <template v-slot:cell(status)="data">
                <b-badge :variant="(data.item.status=='paid'?'success':'warning')">{{data.item.status.toUpperCase()}}</b-badge>
              </template>
              <template v-slot:cell(view)="data">
                <a :href="data.item.hosted_invoice_url" target="_blank" class="btn btn-primary btn-sm d-block d-md-inline-block"><span class="fa fa-external-link text-white"></span></a>
                <a :href="data.item.invoice_pdf" target="_blank" class="btn btn-warning btn-sm ml-0 ml-md-2 mt-2 mt-md-0 d-block d-md-inline-block"><span class="fa fa-download text-white"></span></a>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="changeDefaultModal" v-model="showChangeDefaultModal" title="Confirm Change Default" hide-footer>
      <div v-if="selectedAccount">
        <template v-if="selectedAccount.type=='card'">
          <p>Are you sure you want to set your <strong>{{selectedAccount.card.brand}}</strong> credit card ending in <strong>{{selectedAccount.card.last4}}</strong> as your primary payment method?</p>
        </template>
        <template v-else>
          <p>Are you sure you want to set your <strong>{{selectedAccount.institution.name}}</strong> bank account ending in <strong>{{selectedAccount.mask}}</strong> as your primary payment method?</p>
        </template>
        <b-button @click="setDefault" block variant="primary" class="text-uppercase" :disabled="saving"><span v-if="saving" class="fe fe-loader mr-1"></span>CONFIRM</b-button>
        <b-button @click="hideChangeDefaultModal" block variant="outline-primary" class="text-uppercase">CANCEL</b-button>
      </div>
    </b-modal>
    <b-modal variant="danger" id="deleteAccountModal" v-model="showRemoveAccountModal" title="Confirm Remove Account" hide-footer>
      <div v-if="selectedAccount">
        <template v-if="selectedAccount.type=='card'">
          <p>Are you sure you want to remove the <strong>{{selectedAccount.card.brand}}</strong> credit card ending in <strong>{{selectedAccount.card.last4}}</strong>?</p>
        </template>
        <template v-else>
          <p>Are you sure you want to remove the <strong>{{selectedAccount.institution.name}}</strong> account ending in <strong>{{selectedAccount.mask}}</strong>?</p>
        </template>
        <b-button @click="removeAccount" block variant="danger" class="text-uppercase" :disabled="deleting"><span v-if="deleting" class="fe fe-loader mr-1"></span>CONFIRM</b-button>
        <b-button @click="hideRemoveAccountModal" block variant="outline-danger" class="text-uppercase">CANCEL</b-button>
      </div>
    </b-modal>
    <b-modal variant="danger" id="cancelSubscriptionModal" v-model="showCancelSubscriptionModal" title="Confirm Cancel Subscription" hide-footer>
      <div>
        <p>Are you sure you want to cancel your subscription?</p>
        <b-button @click="cancelSubscription" block variant="danger" class="text-uppercase" :disabled="cancelling"><span v-if="cancelling" class="fe fe-loader mr-1"></span>CONFIRM</b-button>
        <b-button @click="showCancelSubscriptionModal=false" block variant="outline-danger" class="text-uppercase">CANCEL</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import CreditCard from './Account/CreditCard.vue';
import {mapState} from 'vuex'
import { db } from '@/firebase';
import firebase from 'firebase';
import { collectionData } from 'rxfire/firestore';
import { combineLatest,of } from 'rxjs';
import store from '@/vuex/store.js';
export default {
  name: 'Account',
  components: {
    CreditCard
  },
  computed:{
    ...mapState(['user'])
  },
  data(){
      return {
        loading:true,
        deleting:false,
        reactivating:false,
        activePayment:'visa',
        showRemoveAccountModal:false,
        showCancelSubscriptionModal:false,
        selectedAccount:null,
        accounts:[],
        subscription:null,
        rows:[],
        cancelling:false,
        saving:false,
        showChangeDefaultModal:false,
        adding:false,
        invoiceFields: [{key:'amount_due',label:'Amount'},{key:'number',label:'Invoice Number'},{key:'created',label:'Date'},'status',{key:'view',label:''}],
      }
  },
  methods:{
    reactivateSubscription(){
      let vm = this;
      vm.reactivating=true;
      return db.collection('user_subscriptions').doc(vm.user.uid).set({reactivate:true},{merge:true}).then(()=>{
        vm.reactivating=false;
        vm.showCancelSubscriptionModal=false;
        vm.subscription.reactivate=true;
        delete vm.subscription.cancelled;
        delete vm.user.subscription.cancelled;
        store.commit('updateUser',Object.assign({...vm.user}));
      }).catch(()=>{
        vm.reactivating=false;
      });
    },
    cancelSubscription(){
      let vm = this;
      vm.cancelling=true;
      return db.collection('user_subscriptions').doc(vm.user.uid).set({cancelled:true,cancel:true},{merge:true}).then(()=>{
        vm.cancelling=false;
        vm.showCancelSubscriptionModal=false;
        vm.subscription.cancelled = true;
        delete vm.user.subscription.stripeSubscription;
        store.commit('updateUser',Object.assign({...vm.user}));
        vm.cancelled=true;
      }).catch(()=>{
        vm.cancelling=false;
      });
    },
    chooseAccount(account){
      let index = null;
      this.accounts.forEach((acc,i)=>{
        if(acc.id==account.id) index=i;
        acc.selected=false;
      });
      Vue.set(this.accounts,index,{...account,selected:true});
      this.$emit('select',account);
    },
    clickPayment(btn){
      this.activePayment = btn;
    },
    async load(){
      let vm = this;
      vm.subscription = vm.user.subscription;
      vm.loadScript('https://js.stripe.com/v3/').then(vm.onStripeLoaded).catch(vm.onScriptError);
      combineLatest(
        collectionData(db.collection('user_accounts').where('userID','==',vm.user.uid),'id')
      ).subscribe((res)=>{
        vm.accounts=res[0].sort((a) => a.default ? -1 : 0);
        vm.loading=false;
      });
    },
    async saveCreditCard(token){
      let vm = this;
      vm.loading=true;
      let data = {
        stripeToken:token.id,
        userID:vm.user.uid,
        type:token.type,
        card:{}
      };
      Object.keys(token.card).forEach((cardKey)=>{
        if(token.card[cardKey]) data.card[cardKey]=token.card[cardKey];
      });
      let activeAccounts = vm.accounts.filter(a=>!a.new);
      if(activeAccounts.length==0) data.default=true;
      db.collection('user_accounts').add(data).then(()=>{
        vm.adding=false;
        vm.loading=false;
      }).catch((err)=>{
        vm.loading=false;
        vm.$notify({
          title: err,
          type: 'error',
          position: 'bottom right'
        });
      });
    },
    async cancelCreditCard(){
      this.adding=false;
      this.accounts=this.accounts.filter((a)=>!a.new);
    },
    addCreditCard(){
      this.adding=true;
      this.accounts.push({
        selected:false,
        type:'card',
        new:true
      })
    },
    removeAccount(){
      let vm = this;
      let account = vm.selectedAccount;
      vm.deleting=true;
      db.collection('user_accounts').doc(account.id).delete().then(()=>{
        vm.deleting=null;
        vm.showRemoveAccountModal=false;
      }).catch((error)=>{
        vm.deleting=null;
        vm.$notify({
          title: error,
          type: 'error',
          position: 'bottom right'
        });
      });
    },
    confirmRemoveAccount(account){
      this.selectedAccount=account;
      this.showRemoveAccountModal=true;
    },
    hideRemoveAccountModal(){
      this.selectedAccount=null;
      this.showRemoveAccountModal=false;
    },
    confirmChangeDefault(account){
      if(!account.new){
        this.selectedAccount=account;
        this.showChangeDefaultModal=true;
      }
    },
    hideChangeDefaultModal(){
      this.selectedAccount=null;
      this.showChangeDefaultModal=false;
    },
    setDefault(){
      let vm = this;
      vm.saving=true;
      let account=vm.selectedAccount;
      let existingDefaultID = null;
      vm.accounts.forEach((obj)=>{
        if(obj.default) existingDefaultID=obj.id;
      });
      db.collection('user_accounts').doc(account.id).update({default:true}).then(()=>{
        if(existingDefaultID){
          return db.collection('user_accounts').doc(existingDefaultID).update({default:firebase.firestore.FieldValue.delete()});
        }else{
          return of(true);
        }
      }).then(()=>{
        vm.saving=false;
        vm.hideChangeDefaultModal();
      }).catch((error)=>{
        vm.saving=false;
        vm.$notify({
          title: error,
          type: 'error',
          position: 'bottom right'
        });
      });
    },
    onScriptError (error) {
      console.log(error);
    },
    onStripeLoaded () {
      let vm = this;
      vm.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
      vm.elements = vm.stripe.elements();
    },
    loadScript (src) {
        return new Promise(function (resolve, reject) {
            if (document.querySelector('script[src="' + src + '"]')) {
                resolve();
                return;
            }
            const el = document.createElement('script')
            el.type = 'text/javascript'
            el.async = true
            el.src = src
            el.addEventListener('load', resolve)
            el.addEventListener('error', reject)
            el.addEventListener('abort', reject)
            document.head.appendChild(el)
        })
    },
  },
  mounted(){
    this.load();
  },
  watch: {
    user () {
      this.load();
    }
  }
}
</script>
