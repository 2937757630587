<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
        <div v-else-if="completeProductBidRequests.length > 0" class="row">
            <div class="col-12">
                <b-card class="rounded border-2"> 
                    <b-card-header><h5 class="m-0">My Product Bid Request</h5></b-card-header>
                    <b-card-body>
                        <b-list-group>
                            <b-list-group-item
                                :key="item.id"  v-for="item in completeProductBidRequests" @click="viewDetails(item.id)" class="d-block d-md-flex align-items-center text-md-left"
                            >
                                <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4">
                                    <h6 class="mt-2 m-md-0">
                                        {{item.bidRequest.subtype}} {{item.bidRequest.type}}
                                    </h6>
                                    <small class="d-block d-md-flex"><span>Delivery Window: </span><strong class="d-block d-md-inline flex-fill text-left ml-1 text-md-left">{{moment( item.bidRequest.startDate.toDate()).format('ll')}} to {{moment(item.bidRequest.endDate.toDate()).format('ll')}}</strong></small>
                                    <small class="d-block d-md-flex"><span>Total Number of {{ item.bidRequest.unit }}: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left ml-1 "> {{ item.bidRequest.accepted_full_fill_amount ? item.bidRequest.accepted_full_fill_amount : item.bidRequest.total }}</strong></small>
                                    <small class="d-block d-md-flex"><span>Farmer Fulfilled Number of {{ item.bidRequest.unit }}: </span><strong class="d-block d-md-inline flex-fill text-left text-md-left"> {{ item.fullFillAmount }}</strong></small>                                    
                                </div>
                                <div class=""><b-badge pill variant="info">{{item.entity}}</b-badge><small class="text-muted d-block mt-1"><nobr>{{formatDate(item.created)}}</nobr></small></div>
                            </b-list-group-item>
                            <b-list-group-item v-if="!allBidRequestLoaded" :disabled="bidRequestLoadingMore" @click="loadCompleteProductBidRequest" button variant="success" class="text-center">
                                <span class="fa fa-spinner" v-if="bidRequestLoadingMore"></span>
                                <span v-else class="fa fa-plus-circle mr-2"></span> 
                                <strong>Show More</strong>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <div v-else-if="completeProductBidRequests.length == 0" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                    <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                    <template v-if="user.role.buyer">
                        <h4 class="card-title mt-0 mb-4">No product bid request to accept </h4>
                        <p><a @click="showLearnMore=true" class="text-info">Learn more about creating great Product Bid Request</a></p>
                        <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                    </template>
                    <template v-else>
                        <h4 class="card-title mt-0 mb-4">No Product Bid Request requested</h4>
                        <p><a href="/bid/request" class="text-info">Browse our Product Bid</a></p>
                        <p><a target="_blank" href="https://www.ngf-global.com/contact-us" class="text-info">Contact our team with any questions you may have</a></p>
                    </template>
                </card>
                <b-modal size="lg" ref="learn-more-modal" v-model="showLearnMore" hide-footer title="Creating Great Product Listings">
                    <CreatingGreatListings />
                </b-modal>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>
    </div>
</template>


<script>

import CreatingGreatListings from "@/views/Dashboard/Commodity/components/CreatingGreatListings.vue";
import {mapState} from 'vuex';
import {bus} from '@/vuex/bus.js';
import moment from 'moment';
import {db} from '@/firebase';
import { docData } from 'rxfire/firestore';
import { from, of, combineLatest } from 'rxjs';
import { map, switchMap} from 'rxjs/operators';

export default {
    computed:{
        ...mapState(['user'])
    },
    components: {
        CreatingGreatListings
    },
    data() {
        return {
            completeProductBidRequests: [],
            loading: true,
            showLearnMore: false,
            bidRequestLoadingMore: false,
            allBidRequestLoaded: false,
            lastBidRequestDoc: null,
        };
    },

    async mounted(){
        const vm = this;
        vm.load();
      
    },

    methods:{
        async load(){
            const vm = this;
            vm.completeProductBidRequests = [];
            await vm.loadCompleteProductBidRequest();
            bus.$on('backToMarketplace',()=>{
                bus.$emit('prepareTitle',null);
            });
            bus.$emit('prepareTitle',null);
        },

        async loadCompleteProductBidRequest(){
            const vm = this;
            (!vm.lastBidRequestDoc) ? vm.loading=true : vm.bidRequestLoadingMore=true;
            let ref = db.collection('bid_request_complete').where('is_restore', '==', null);
            ref = ref.limit(10).orderBy('created','desc');

            if(vm.lastBidRequestDoc) ref=ref.startAfter(vm.lastBidRequestDoc);
            from(ref.get()).pipe(
                switchMap(items=>{
                    if(items.empty||items.length<10) vm.allBidRequestLoaded, vm.loading = false;
                    if(items.empty) return of([]);
                    vm.lastBidRequestDoc=items.docs[items.docs.length-1];
                    return combineLatest(...items.docs.map(doc=>{
                        return docData(db.collection('bid_request').doc(doc.data().bidRequestID)).pipe(
                            map(t=>{
                                if(JSON.stringify(t) !== '{}'){
                                    return {id:doc.id,...doc.data(),bidRequest:{...t}}
                                } else {
                                    return {id:doc.id,...doc.data(),bidRequest:{}}
                                }
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(bidRequests=>{
                    if(bidRequests.length==0) return of([]);
                    return combineLatest(...bidRequests.map(c=>{
                        return docData(db.collection('commodity_types').doc(c.bidRequest.type)).pipe(
                            map(t=>{
                                return {...c, bidRequest: {...c.bidRequest,type:t.name}}
                            })
                        )
                    }))
                })
            ).pipe(
                switchMap(bidRequests=>{
                    if(bidRequests.length==0) return of([]);
                    return combineLatest(...bidRequests.map(c=>{
                        return docData(db.collection('commodity_subtypes').doc(c.bidRequest.subtype)).pipe(
                        map(t=>{
                            return {...c, bidRequest: {...c.bidRequest,subtype:t.name}}
                        })
                        )
                    }))
                })
            ).pipe(
                switchMap((bidRequests) => {
                    if(bidRequests.length == 0) return of([]);
                    return combineLatest(...bidRequests.map((c) => {
                        return docData(db.collection('units').doc(c.bidRequest.unit)).pipe(
                            map((t) => {
                                return {...c, bidRequest: {...c.bidRequest, unit: t.name}}
                            })
                        )
                    }))
                })
            ).subscribe((items)=>{
                items.forEach((i)=>{
                    if(i.length == undefined){
                        if(vm.user.role.admin){
                            vm.completeProductBidRequests.push(i)                           
                        } else if(i.bidRequest.userID === vm.user.uid && i.buyerID === vm.user.uid){
                            vm.completeProductBidRequests.push(i)                           
                        } else if(i.bidRequest.userID !== vm.user.uid && i.producerID === vm.user.uid){
                            vm.completeProductBidRequests.push(i)
                        }
                    }
                });
                vm.productionContractLoadingMore=false;
            });
            setTimeout(() => {
                vm.bidRequestLoadingMore=false;
                vm.loading=false;
            }, 5000);

        },

        viewDetails(completeBidRequestID){
            const vm = this;
            vm.$router.push('/bid/request/complete/list/'+completeBidRequestID)
        },

        formatDate(d){
            if(d){
                d = d.toDate();
                if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
                if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
                if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
                return moment(d).format('ll');
            }else{
                return '';
            }
        },
    }

}
</script>