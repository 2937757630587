<template>
  <div :class="(type=='large'?'d-inline-block position-relative w-100':'col col-thumbnail') + ' text-center'">
    <b-button variant="link" :disabled="uploading||resizing||disabled" @click="selectFile" :class="(type=='large'?' img-link-large ' + (mode=='view'?'border-2':'border-dotted'):'img-link ' + (mode=='view'?'border-2':'border-dotted')) + ' m-0' + (file.name?' img-img':'')">
      <img v-if="file.name" :src="this.file[(type=='large'?'largeURL':'thumbURL')]" class="img-fluid" :class="(type =='large'?'img-large-preview':'' )"/>
      <template v-else-if="type=='large'">
        <div :class="'rounded-circle p-4 fa ' + (uploading||resizing?'fa-spinner':'fa-upload')"></div>
        <div v-if="uploading" class="mt-2">{{progressUpload}}%<br />Uploading...</div>
        <div v-else-if="resizing" class="mt-2">&nbsp;<br />Resizing...</div>
        <div v-else class="mt-2">Upload photo of<br />product</div>
      </template>
      <template v-else>
        <i :class="'fa ' + (uploading||resizing?'fa-spinner':'fa-plus')"></i>
      </template>
      <input class="d-none" id="upload"
                type="file"
                name="upload"
                ref="uploadInput"
                accept="image/*"
                :multiple="false"
                @change="detectFiles($event)" />
    </b-button>
    <div v-if="type=='large'&&mode=='edit'" class="row">
      <div class="col text-left">
        <b-button @click="setPrimary" size="sm" :disabled="!file.name" :variant="(this.file.primary?'primary':'outline-primary')"><i :class="'fa '+(this.file.primary?'fa-toggle-on':'fa-toggle-off') + ' mr-2'"></i> Primary</b-button>
      </div>
      <div class="col text-right">
        <b-button size="sm" :disabled="!file.name||deleting" @click="deleteFile" variant="danger"><i v-if="deleting" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-close mr-2"></i> Remove</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {bus} from '@/vuex/bus.js';
import {db} from '@/firebase';
import {firestorage} from '@/firebase';
export default {
  props: {
    type:String,
    selectedFile:Object,
    disabled:Boolean,
    mode:{
      type:String,
      required:false,
      default:'edit'
    },
  },
  data() {
    return {
      progressUpload: 0,
      file:null,
      uploading:false,
      resizing:false,
      deleting:false,
      uploadTask:'',
      listener:null
    };
  },
  destroyed(){
    this.file=null;
  },
  async created(){
    this.file=this.selectedFile;
  },
  async mounted(){
    this.file=this.selectedFile;
  },
  methods:{
    async setPrimary(){
      if(this.file.primary) return;
      Vue.set(this.file,'primary',true);
      bus.$emit('updateImage',this.file);
      bus.$emit('primaryImageChanged',this.file);
    },
    async deleteFile(){
      let vm = this;
      vm.deleting=true;
      await db.collection("commodity_images").doc(vm.file.id).delete();
      vm.file={id:vm.file.id,commodityID:vm.file.commodityID,userID:vm.file.userID};
      bus.$emit('updateImage',vm.file);
      vm.deleting=false;
    },
    upload (file) {
      let extension = file.name.split('.').pop();
      this.uploading = true;
      bus.$emit('processingImage',true);
      this.uploadTask = firestorage.ref('ci/' + this.file.commodityID + '/' + this.file.id + '.' + extension).put(file)
    },
    selectFile () {
      if(this.type=='thumbnail'){
        this.$emit('select',this.file);
      }else{
        if(!this.file.name) this.$refs.uploadInput.click();
        else window.open(this.file.fullURL);
      }
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x])
      })
    },
  },
  watch: {
    uploadTask: function () {
      let vm = this;
      vm.uploadTask.on('state_changed', sp => {
        vm.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      async () => {
        let data = await vm.uploadTask.snapshot.ref.getMetadata();
        for(let key in data){
          if(data[key] === undefined) delete data[key];
        }
        await db.collection('commodity_images').doc(vm.file.id).set({...data,...this.file});
        vm.uploading=false;
        vm.resizing=true;
        vm.listener = db.collection("commodity_images").doc(vm.file.id).onSnapshot({
            includeMetadataChanges: true
        }, async function(doc) {
          if(doc.data().extension){
            vm.listener();
            let data = doc.data();
            let thumbURL = await firestorage.ref(data.fullPath + data.name + '-60.' + data.extension).getDownloadURL();
            let largeURL = await firestorage.ref(data.fullPath + data.name + '-600.' + data.extension).getDownloadURL();
            let fullURL = await firestorage.ref(data.fullPath + data.name + '-1600.' + data.extension).getDownloadURL();
            vm.file = {...data,thumbURL:thumbURL,largeURL:largeURL,fullURL:fullURL};
            await doc.ref.update({thumbURL:thumbURL,largeURL:largeURL,fullURL:fullURL})
            vm.resizing = false;
            bus.$emit('processingImage',false);
            bus.$emit('updateImage',vm.file);
            vm.$refs.uploadInput.value=null;
          } else {
            vm.listener();
            let data = doc.data();
            let thumbURL = await firestorage.ref(data.fullPath).getDownloadURL();
            vm.file = {...data,thumbURL:thumbURL,largeURL:thumbURL,fullURL:thumbURL};
            await doc.ref.update({thumbURL:thumbURL,largeURL:thumbURL,fullURL:thumbURL})
            vm.resizing = false;
            bus.$emit('processingImage',false);
            bus.$emit('updateImage',vm.file);
            vm.$refs.uploadInput.value=null;
          }
        });
      })
    }
  }
}
</script>
<style>
.img-link,.img-link-large {
  cursor:pointer;
  background-color:#dedede;
  display:inline-block;
  text-align:center;
  width:100%;
  padding:0;
}
.img-link {
  position:relative;
  width:60px;
  height:60px;
  color:#979a9f;
}
.img-link, .img-link .img-fluid {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.img-link .fa {
  position:absolute;top:50%;left:50%;margin-left:-6px;margin-top:-8px;
}
.img-link-large {
  width:100%;
  box-sizing:border-box;
  text-decoration:none;
  padding-top:75px;
  padding-bottom:75px;
  color:#979a9f;
  font-weight:bold;
  max-width:600px;
  max-height:338px;
}
.img-link-large, .img-link-large .img-fluid {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

}
.img-link-large.img-img {
  padding:0;
}
.img-link-large:hover {
  text-decoration:none;
}
.img-link-large .fa {
  background-color:#f5f6f7;font-size:24px;
}
.img-large-preview {
  height:350px !important; 
  width:600px !important; margin-bottom: 30px;
}
</style>
