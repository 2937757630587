<template>
  <footer class="footer border-top">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <div class="copyright">
        &copy; {{ year }} by NGF Global.
      </div>
      <div class="copyright">
        <span class="ml-4">Contact: <a href="mailto:info@ngf-global.com">info@ngf-global.com</a></span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
