<template>
    <div class="container">
        <div v-if="loading" class="row mt-4">
            <div class="col d-none d-md-flex"></div>
            <div class="col-12 col-md-8 col-xl-6 pt-4">
                <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                <h4 class="card-title mb-4">
                    <b-spinner class="mr-2"></b-spinner> Loading...
                </h4>
                </card>
            </div>
            <div class="col d-none d-md-flex"></div>
        </div>



        <div v-else>
            <ValidationObserver ref="form">
                <form @submit.prevent="validate">
                    <div class="container">
                        <div class="row" v-if="$route.params.id">
                            <!-- <div class="d-block d-md col-6"><b-button @click="backTo" variant="primary"><i class="fa fa-chevron-left mr-2"></i> Back</b-button></div> -->
                            <div v-if="!saved" class="col-6 col-md-12 text-right">
                                <button @click="updateBidRequestStatus(bidRequest.active)" type='button' :disabled="error" :class="'btn btn-'+(bidRequest.active?'primary':'outline-primary')"><i :class="'fa fa-'+(bidRequest.active?'toggle-on':'toggle-off')+' mr-2'"></i> ACTIVE</button>
                            </div>
                        </div>

                        <div v-if="saved" class="row mt-4">
                            <div class="col d-none d-md-flex"></div>
                            <card  v-if="$route.params.id" class="card-lock text-center mt-4 pb-4" no-footer-line>
                                <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
                                <h4 class="card-title mt-0 mb-4">Your listing is saved!</h4>
                                <p><a @click="backTo" class="text-info">Return</a></p>
                            </card>
                        </div>

                        <div class="row" v-else>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="m-0">General</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider 
                                            name="bidRequest.category"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                label="Category"
                                                :invalid-feedback="'Category is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select @change="onCategoryChange" placeholder="Choose category" v-model="bidRequest.category" :options="optionCategories" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.year"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >

                                            <b-form-group
                                                label="Crop year"
                                                :invalid-feedback="'Year is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select placeholder="Choose year" v-model="bidRequest.year" :options="sortedYears" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.type"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                label="Product"
                                                :invalid-feedback="'Product is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select @change="onTypeChange" :disabled="optionTypes.length==0" placeholder="Choose product" v-model="bidRequest.type" :options="optionTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.subtype"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                label="Type"
                                                :invalid-feedback="'Type is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select @change="onSubTypeChange" :disabled="optionSubTypes.length==0" placeholder="Choose type" v-model="bidRequest.subtype" :options="optionSubTypes" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <b-form-group label="Variety" >
                                            <b-form-input placeholder="Enter variety" v-model="bidRequest.variety" />
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.unit"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                label="Unit of Measure"
                                                :invalid-feedback="'Unit is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select placeholder="Choose units" v-model="bidRequest.unit" :options="optionUnits" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.expires"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                label="Expires On"
                                                :invalid-feedback="'Expires is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <Datepicker v-model="bidRequest.expires" :disabledDates="disabledExpireOn" :state="(failed?false:(passed?true:null))"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.total"
                                            :rules="{required:true,decimal:true,minimum:{minimum:minimumQuantity}}"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                            <b-form-group
                                                :label="'Total'+(selectedUnit?' in '+selectedUnit:'')"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-input placeholder="Enter total" v-model="bidRequest.total" :state="(failed?false:(passed?true:null))"></b-form-input>
                                                <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                                    {{ errors[0] == "Minimum" ? "Total must meet the minimum of " + numeral(minimumQuantity).format((selectedUnit !== 'LBS') ? '0.00000' : '' ) +" " + selectedUnit + ((selectedUnit !== 'LBS') ? ' (as converted 1 lbs)' : '') : "Please enter a valid number." }}
                                                </b-form-invalid-feedback>
                                                <!-- <div class="invalid-feedback d-block" v-if="minimumFailed">Total must meet the minimum of {{numeral(minimumQuantity).format((selectedUnit !== "LBS") ? '0.00000' : '' )}} {{selectedUnit}} {{ (selectedUnit !== "LBS") ? "(as converted 1 lbs)" : "" }} </div> -->
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.priceOption"
                                            rules="required"
                                            v-slot="{passed, failed}"
                                        > 
                                            <b-form-group
                                                label="Price Option"
                                                :invalid-feedback="'Price Option is required'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-select placeholder="Choose Price Options" v-model="bidRequest.priceOption" :options="optionPriceOption" @change="priceOptionChange" :state="(failed?false:(passed?true:null))"></b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="CurrencyPrice"
                                            :rules="{ required : !disabledPriceField}"   
                                            v-slot="{ passed, failed }"
                                        >
                                            <b-form-group
                                                :label="(selectedPriceOption? selectedPriceOption : '') + ' Price'+(selectedUnit?' per '+selectedUnit:'')"
                                                :invalid-feedback="'Please enter a valid number'"
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <b-form-input :disabled="disabledPriceField" placeholder="Enter bid request price" ref="currencyInput" v-currency="priceFormatOptions" v-model="CurrencyPrice" :state="(failed?false:(passed?true:null))"></b-form-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div>
                                    <span>Delivery Window</span>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.startDate"
                                            rules="required"
                                            v-slot="{ passed, failed }"
                                        >
                                        <b-form-group
                                            :invalid-feedback="'Start Date is required'"
                                            :state="(failed?false:(passed?true:null))"
                                        >
                                            <Datepicker placeholder="Start Date" v-model="bidRequest.startDate" :disabledDates="disabledStartDates"   @selected="onSelectStartDate"  :state="(failed?false:(passed?true:null))"/>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-md-6 pt-2">
                                        <ValidationProvider
                                            name="bidRequest.endDate"
                                            :rules="{required: true, minimumStartDate: bidRequest.startDate }"
                                            v-slot="{ passed, failed, errors }"
                                        >
                                            <b-form-group 
                                                :state="(failed?false:(passed?true:null))"
                                            >
                                                <Datepicker placeholder="End Date" v-model="bidRequest.endDate" :disabled="disabledEndDateField" :disabledDates="disabledEndDates" :state="(failed?false:(passed?true:null))"/>
                                            </b-form-group>
                                            <b-form-invalid-feedback :state="(failed?false:(passed?true:null))">
                                                {{ errors[0]=='MinimumStartDate'?'End date must be after the start date ' + moment(bidRequest.startDate).format('ll'): (errors.length > 0) ? 'End Date is required' : ''}}
                                            </b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2"><hr /></div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <h5>Details</h5>
                                    </div>
                                </div>
                                <div class="col-12 pt-2 bg-white border rounded">
                                    <b-form-group
                                        id="fieldset-bidRequest-details"
                                        description="Enter additional details for your Bid Request Offer."
                                        label="Include more details"
                                    >
                                        <vue-editor v-model="bidRequest.details"></vue-editor>
                                    </b-form-group>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2"><hr /></div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <h5>Location</h5>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2">
                                        <b-alert show variant="info">The requested address will be used for Bid Request Offer location purposes.  The precise location will not be shared with the Farmer; however, the general location will be shared.</b-alert>
                                        <b-form-group label="Where is your Bid located?">
                                            <gmap-autocomplete
                                                @place_changed="setPlaceLocation"
                                                :types="['address']"
                                                :enable-geolocation="true"
                                            >
                                            </gmap-autocomplete>
                                            <div class="invalid-feedback d-block" v-if="positionFailed">Please set the location</div>
                                        </b-form-group>
                                        <b-button variant="info" @click="onClickSetPosition">Set Location</b-button>
                                        <gmap-map
                                            v-if="bidRequestPlace"
                                            :center="bidRequestPlace.position"
                                            :zoom="12"
                                            class="mt-4"
                                            style="width:100%;  height: 320px;"
                                        >
                                            <gmap-marker :position="bidRequestPlace.position" />
                                        </gmap-map>
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-12 pt-2"><hr /></div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-2"><h5>Certifications</h5></div>
                                </div>   
                                <div v-if="bidRequest.specifications.length > 0">
                                    <EditSpecification v-for="specification in bidRequest.specifications" :key="specification.id" :specification="specification" @cert-add="getAddCert"  @remove="certRemoved" />
                                </div>
                                <NewSpecification :bidRequestID="bidRequest.id" :certificationIndex="bidRequest.specifications.length" @saved="certAdded" @cert-add="getAddCert" class="mt-4" />
                                <div v-if="error" class="row mt-2">
                                    <div class="col-12"><div class="alert alert-danger"><i class="fa fa-exclamation-triangle mr-2"></i>One or more required fields are missing.</div></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12"><div class="alert alert-primary"><i class="fa fa-balance-scale mr-2"></i>By proceeding, you acknowledge reading and agreeing to NGF-Global’s policies <a href="https://www.ngf-global.com/policy" target="_blank" class="text-white font-weight-bold"><u>https://www.ngf-global.com/policy</u></a></div></div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6">
                                        <b-button @click="backTo" variant="light">Cancel</b-button>
                                    </div>
                                    <div class="col-6 text-right">
                                        <b-button :disabled="saving" @click="validate" variant="success"><i v-if="saving" class="fa fa-spinner mr-2"></i><i v-else class="fa fa-save mr-2"></i>Submit<i class="fa fa-arrow-right ml-2" /></b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>  
    </div>
</template>

<script>
import Vue from "vue";
import {db} from '@/firebase';
import {bus} from '@/vuex/bus.js';
import {mapState} from 'vuex'
import Datepicker from 'vuejs-datepicker';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { VueEditor } from "vue2-editor";
import NewSpecification from "@/components/BidRequestCertification/New.vue";
import EditSpecification from "@/components/BidRequestCertification/Edit.vue";
import { getValue, setValue } from "vue-currency-input";
import firebase from 'firebase';



import * as VueGoogleMaps from "vue2-google-maps";
import * as geofire from 'geofire-common';


extend("required", required);
extend("decimal",n=>!isNaN(parseFloat(n)) && isFinite(n));

extend("minimumPrice", {
    params: ["minimumPrice"],
    validate: (value, {minimumPrice})=>{
        if(typeof value != 'number'){
            let tempPrice = value.replace('$', '');
            return (parseFloat(tempPrice)>=minimumPrice);
        }
    },
    message:'Minimum'
});

extend("minimumStartDate", {
    params: ["minimumStartDate"],
    validate: (value, {minimumStartDate})=>{
        const startDate = new Date(value);
        const EndDate = new Date(minimumStartDate);
        return !(startDate.getTime()<=EndDate.getTime());
    },
    message:'MinimumStartDate'
});


Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY,
    libraries: "places"
  }
});

export default {
    name: "EditBidRequest",
    components: {
        Datepicker,
        VueEditor,
        NewSpecification,
        EditSpecification
    },

    computed: {
        ...mapState(['user']),
        minimumQuantity(){
            const vm = this;
            let min = 0;
            vm.optionUnits.forEach((u)=>{
                if(u.value==vm.bidRequest.unit) min = u.minimum;
            })
            return min;
        },
        sortedYears: function(){
            return [...this.years].sort((a, b) => {
                return a - b;
            });
        },
        selectedUnit(){
            const vm = this;
            let unit = '';
            vm.optionUnits.forEach((u)=>{
                if(u.value==vm.bidRequest.unit) unit = u.text;
            })
            return unit;
        },
        selectedPriceOption(){
            const vm = this;
            let priceOption = '';
            vm.optionPriceOption.forEach((p) => {
                if(p.value==vm.bidRequest.priceOption) priceOption = p.text;
            });
            vm.disabledPriceField = false;
            if(priceOption === "Make Offer"){
                vm.disabledPriceField = true;
            }
            return priceOption;
        },
    },
    watch:{
        CurrencyPrice(){
            // const vm = this;
            // vm.bidRequest.price = getValue(vm.$refs.currencyInput);
        },  
    },

    data() {
        return {
            bidRequest: {
                id:null, 
                category: null,
                year: null,
                subtype: null,
                type: null,
                variety:null,
                unit:null,
                expires:null,
                total: null,
                priceOption: null,
                price:null,
                startDate: null,
                endDate: null,
                details:null,
                location:null,
                active:true,
                specifications: [],
                is_delete: 0,
                slug: null,
            },
            optionCategories: [],
            optionTypes: [],
            optionSubTypes: [],
            years: [],
            optionUnits: [],
            optionPriceOption: [],
            priceFormatOptions: {
                locale:"en",
                currency: "USD",
                precision: 2,
                distractionFree: false,
                valueAsInteger: false,
                autoDecimalMode: true,
                allowNegative: true
            },
            disabledExpireOn:{
                to: new Date(Date.now() - 8640000)
            },
            disabledStartDates: {
                to: new Date(Date.now() - 8640000)
            },
            disabledEndDates: {
                to: new Date(Date.now() - 8640000)
            },
            disabledEndDateField: true,
            CurrencyPrice: '',
            positionFailed: null,
            bidRequestPlace: null,
            disabledPriceField: true,
            saving:false,
            error: false,
            saved: false,
            loading: false,
        }
    },

    async created(){
        let vm = this;
        if(vm.$route.params.id){
            try {
                const cRef = await db.collection('bid_request').doc(vm.$route.params.id).get();
                
                // Check user permissions
                const { userID } = cRef.data();
                if (!vm.user.role.admin && vm.user.uid !== userID) {
                    vm.$router.push("/permission/denied/bidRequest");
                    bus.$emit('prepareTitle', null);
                    return;
                }
            }catch (error) {
                console.error("Error fetching document:", error);
            }
        } 
    },

    async mounted() {
        try {
            await Promise.all([
                this.getYears(),
                this.loadBidRequest(),
                this.loadOptionsAndTypes(),
            ]);
            bus.$emit('prepareTitle', null);
        } catch (error) {
            console.error("Error during mounted lifecycle:", error);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async loadBidRequest(){
            let vm = this;
            let cRef = await db.collection('bid_request').doc(vm.$route.params.id).get();
            if(cRef.exists){
                const data = cRef.data();
                vm.loading = false;

                const {price, slug, expires, startDate, endDate} = data;

                for(let key in data){
                    if(key in vm.bidRequest) vm.bidRequest[key]=data[key];
                }

                vm.CurrencyPrice = price;
                vm.slug = slug;
                vm.bidRequest.expires = expires.toDate();
                vm.bidRequest.startDate = startDate.toDate();
                vm.bidRequest.endDate = endDate.toDate();
                // vm.disabledEndDate = false;

                await Promise.all([vm.onCategoryChange(), vm.onTypeChange()]);

                const locationRef = await db.collection('bid_request_location').doc(vm.$route.params.id).get();
                await setValue(vm.$refs.currencyInput, data.price)

                if(locationRef.exists){
                    vm.bidRequestPlace = {...locationRef.data()};
                }
            }
        },

        async getYears(){
            const vm = this;
            let refs = await db.collection('cropyear').orderBy('year', 'desc').get();
            refs.forEach(ref => {
                vm.years.push(ref.data().year)
            });
        },


        async loadOptionsAndTypes(){
            const vm = this;
            let categories = await db.collection('commodity_categories').orderBy('name','asc').get();
            categories.forEach(c=>{vm.optionCategories.push({text:c.data().name,value:c.id})});

            let priceOptions = await db.collection('bid_price_options').orderBy('name', 'asc').get();
            priceOptions.forEach(c=>{vm.optionPriceOption.push({text:c.data().name, value:c.id})});

            let units = await db.collection('units').orderBy('name','asc').get();
            units.forEach(c=>{vm.optionUnits.push({minimum:c.data().minimum,text:c.data().name,value:c.id})});
        },

        async onCategoryChange(){
            const vm = this;
            vm.optionTypes=[];
            vm.optionSubTypes=[];
            if(vm.bidRequest.category){
                let types = await db.collection('commodity_types').where('category','==',vm.bidRequest.category).get();
                if(!types.empty) types.forEach(t=>{vm.optionTypes.push({text:t.data().name,value:t.id})});
            }
        },

        async onTypeChange(){
            const vm = this;
            vm.optionSubTypes=[];
            if(vm.bidRequest.type){
                let subtypes = await db.collection('commodity_subtypes').where('type','==',vm.bidRequest.type).get();
                if(!subtypes.empty) subtypes.forEach(t=>{vm.optionSubTypes.push({text:t.data().name,value:t.id})});
            }
        },

        async onSubTypeChange(){
            const vm = this;
            let tempSlug = null;

            let ref = await db.collection('commodity_subtypes').doc(vm.bidRequest.subtype).get();
            if(!ref.empty) var subType = {...ref.data()}.name;

            ref = await db.collection('commodity_types').doc(vm.bidRequest.type).get();
            if(!ref.empty) var type = {...ref.data()}.name;

            let bidRequestRef = await db.collection('bid_request')
                                                .where('type', '==', vm.bidRequest.type)
                                                .where('subtype', '==', vm.bidRequest.subtype).get();

            if(!bidRequestRef.empty){
                let totalExistingProductBid = bidRequestRef.size + vm.generateRandomNumber();
                tempSlug = (subType + "-" + type + "-" + totalExistingProductBid).trim();
            } else {
                tempSlug = (subType + "-" + type).trim();
            }                      
            
            let productSlug = tempSlug.toLowerCase().replace(/\s+/g,'-');
            productSlug = productSlug.replace("/", "-");
            vm.bidRequest.slug = productSlug;
        },

        async validate(){
            const vm = this;
            vm.error=false;
            vm.saving=true;
            vm.bidRequest.price = getValue(vm.$refs.currencyInput);
            let res = await this.$refs.form.validate();
            if(!vm.bidRequestPlace) vm.positionFailed = true;
            if(res&&vm.bidRequestPlace){
                let data = vm.bidRequest;                
                if(data.specifications.length==0) {
                    data.specification = firebase.firestore.FieldValue.delete();
                    data.specifications = firebase.firestore.FieldValue.delete();
                } else {
                    data.specification = data.specifications.map(c=>c.specification);
                }
                data.location=vm.bidRequestPlace.address;
                
                if(vm.selectedPriceOption == "Make Offer"){
                    data.price = null;
                }
                
                try{
                    if(vm.$route.params.id){
                        data.updated = firebase.firestore.FieldValue.serverTimestamp();
                        await db.collection('bid_request').doc(vm.bidRequest.id).set(data,{merge:true}).then(async() =>{
                            await db.collection('bid_request_location').doc(vm.bidRequest.id).set({...vm.bidRequestPlace});
                            if(vm.user.role.admin){
                               await vm.storeLogs();
                            }
                            vm.bidRequest.active = data.active;
                            vm.saved=true;
                        });
                    }
                } catch(e){
                    console.log(e);
                    vm.error=e;
                }
                vm.saving=false;    
                vm.saved=true;
            } else {
                vm.error=true;
                vm.saving=false;
            }
            

        },

        async storeLogs(){
            const vm = this;
            let data = {
                entity: 'ProductBidRequest',
                RequestID: vm.$route.params.id,
                UpdatedBy: vm.user.uid,
                Updated: firebase.firestore.FieldValue.serverTimestamp()
            };
            await db.collection('store_logs').add(data).then(() => {
                console.log('store added successfully!')
            })
        },

        async updateBidRequestStatus(bidRequestActiveStatus){
            const vm = this;
            vm.bidRequestStatus = (bidRequestActiveStatus == true) ? 'inactive' : 'active' ; 
            if(confirm('Are you sure you want to make this production contract ' + vm.bidRequestStatus + '?')){
                let bidRequestRef = await db.collection('bid_request').doc(vm.bidRequest.id);
                bidRequestRef.update({active: !bidRequestActiveStatus }).then(() =>{
                    vm.bidRequest.active = !bidRequestActiveStatus
                });
            }
        },

        onSelectStartDate(){
            const vm = this;
            vm.disabledEndDateField = false;
        },

        
        setPlaceLocation(place){
            const vm = this;
            let generateRandomNumber = (parseFloat("0.0000" + parseInt(vm.randomNumberGenerate())));
            let arr = place.address_components.filter((ac)=>{
                return ( ac.types.indexOf('administrative_area_level_2')>-1 || ac.types.indexOf('administrative_area_level_1')>-1
                );
            });
            let address = arr.map(a=>a.long_name).join(', ');
            vm.place = {
                    position:{
                        lat: place.geometry.location.lat() + generateRandomNumber,
                        lng: place.geometry.location.lng()
                    },
                formatted_address:place.formatted_address,
                address:address,
                url:place.url,
                reference:place.reference
            };
        },

        onClickSetPosition() {
            const vm = this;
            if(vm.place){
                vm.bidRequestPlace = vm.place;
                const lat = vm.place.position.lat;
                const lng = vm.place.position.lng;  
                const hash = geofire.geohashForLocation([lat, lng]); 
                vm.bidRequestPlace.geohash = hash;
                vm.bidRequest.geoHash = hash;
                vm.bidRequest.placePosition = {
                    lat: lat,
                    lng: lng
                },        
                vm.positionFailed=null     
            } else {
                vm.positionFailed=true;
            }
        },

        randomNumberGenerate(){
            return Math.random() * (120 - 100) + 100;
        }, 

        
        getAddCert: async function getAddCert(){
            const vm = this;
            let tempCertificationArray = await vm.getUniqueArray(vm.bidRequest.specifications) ;
            vm.bidRequest.specifications = [];
            vm.bidRequest.specifications = tempCertificationArray;
        },

        certRemoved(id){
            const vm = this;
            vm.bidRequest.specifications=vm.bidRequest.specifications.filter(c=>c.id!=id);
        },

        certAdded(cert, index, isCertificationNotDelete = true){
            const vm = this;
            if(isCertificationNotDelete){
                vm.bidRequest.specifications[index] = cert;
            } else{
                vm.bidRequest.specifications.splice(index, 1);
            }
        },

      
        backTo(){
          bus.$emit('backToTop');
        },

        priceOptionChange(){
            const vm = this;
            vm.disabledPriceField = false;
            if(vm.selectedPriceOption == "Make Offer"){
                vm.disabledPriceField = true;
                vm.CurrencyPrice = null;
                vm.bidRequest.price = 0;
            }
        },
        
        getUniqueArray(items){      
            return [
                ...new Map(
                    items.map((item) => [item['id'], item])
                ).values()
            ]
        },


    }


}

</script>